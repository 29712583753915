import {
  IsNotEmpty,
  IsPositive,
  IsString,
  Matches,
  Min,
  MinLength,
} from 'class-validator'
import {
  DateValidationPattern,
  getFormattedDate,
  IsNotBeforeOrAfter,
} from '../../../util/validation'

export class CreateAsset {
  @IsString({ message: 'Name is invalid' })
  @MinLength(3, { message: 'Name is too short' })
  public name!: string

  @IsNotEmpty({ message: 'Enter a description' })
  @MinLength(3, { message: 'Description is too short' })
  public description!: string

  @Min(0, { message: 'Price is invalid' })
  public price!: number

  @IsPositive({ message: 'Asset category is invalid' })
  public asset_category_id!: number

  @IsPositive({ message: 'Beneficiary is invalid' })
  public beneficiary_id!: number

  @IsNotBeforeOrAfter(undefined, true, {
    message: 'Date acquired cannot be in the future',
  })
  @Matches(DateValidationPattern, {
    message: 'Date acquired is invalid',
  })
  public date_acquired!: string

  constructor(object?: CreateAsset) {
    this.name = object?.name || ''
    this.description = object?.description || ''
    this.price = object?.price || 0
    this.asset_category_id = object?.asset_category_id || 0
    this.beneficiary_id = object?.beneficiary_id || 0
    this.date_acquired = object?.date_acquired || getFormattedDate()
  }

  public static transform(object: CreateAsset) {
    object.price = Number.parseInt(object.price as any, 10)
    object.asset_category_id = Number.parseInt(
      object.asset_category_id as any,
      10
    )
    object.beneficiary_id = Number.parseInt(object.beneficiary_id as any, 10)
  }
}
