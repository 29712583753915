import { Matches } from 'class-validator'
import { EmailValidationPattern } from '../../../util/validation'

export class ForgotPassword {
  @Matches(EmailValidationPattern, { message: 'Invalid ABDC email' })
  public email!: string

  constructor() {
    this.email = ''
  }
}
