import { SetAccountDetails } from '../model/request/account-details.model'
import {
  AdminEditEmployee as EditEmployee,
  CreateEmployee,
  SetNextOfKin,
} from '../model/request/employees'
import { AccountDetails, Employee, NextOfKin } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class EmployeesService {
  public static async create(details: CreateEmployee) {
    return ApiClient.post<CreateEmployee, Employee>(
      'employee/account/create',
      details,
      CreateEmployee
    )
  }

  public static async setNextOfKin(slug: string, details: SetNextOfKin) {
    return ApiClient.post<SetNextOfKin, NextOfKin>(
      'employee/account/next-of-kin/' + slug,
      details,
      SetNextOfKin
    )
  }

  public static async setAccountDetails(
    slug: string,
    details: SetAccountDetails
  ) {
    return ApiClient.post<SetAccountDetails, AccountDetails>(
      'employee/account/account-details/' + slug,
      details,
      SetAccountDetails
    )
  }

  public static async list() {
    return ApiClient.get<Employee[]>('employee/account')
  }

  public static async retrieve(slug: string) {
    return ApiClient.getOne<Employee>('employee/account/show', slug)
  }

  public static async update(slug: string, details: EditEmployee) {
    return ApiClient.patch<EditEmployee, Employee>(
      'employee/account/update',
      slug,
      details,
      EditEmployee
    )
  }
}
