import { Formik, FormikErrors } from 'formik'
import React from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { PrimaryButton, PrimaryLink } from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { FormTextInput } from '../../../../component/field'
import { PageBody } from '../../../../component/misc'
import { CreateClient as CreateClientModel } from '../../../../model/request/jobs'
import { Client } from '../../../../model/response'
import { ClientsService } from '../../../../service'
import { validateWithModel } from '../../../../util/validation'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { JobsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Jobs, name: 'Jobs' },
  {
    path: '',
    name: 'Clients',
  },
]

const View: React.FC<PageComponentProps> = ({ history, loaderState }) => {
  const { state } = useLocation<any>()
  const alert = useAlert()
  const realRedirect = state?.referrer || JobsLinks.Clients

  return (
    <PageBody breadcrumbs={breadcrumbs}>
      <Formik
        initialValues={new CreateClientModel()}
        validate={validateWithModel(CreateClientModel)}
        onSubmit={async (values, { setErrors }) => {
          loaderState.setLoading('Creating client')
          const response = await ClientsService.create(values)
          loaderState.setDone()

          if (response.success) {
            alert.success('Client created successfully')
            history.push(
              realRedirect,
              state
                ? {
                    key: state.key,
                    values: {
                      ...state.values,
                      [state.field]: response.data?.id,
                    },
                  }
                : null
            )
            return
          }

          if (response.errors) {
            alert.error(
              'There was a problem with your data. Check the form again'
            )
            setErrors(response.errors as FormikErrors<Client>)
            return
          }

          alert.error(response.message)
        }}
      >
        {({ handleChange, handleSubmit, isSubmitting }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Client</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <span>Basic Information</span>
                <FormTextInput
                  label="Name"
                  name="name"
                  placeholder="Name"
                  autoCapitalize="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Phone Number"
                  name="phone_number"
                  placeholder="Phone number"
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Address"
                  placeholder="Address"
                  as="textarea"
                  name="address"
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink to={isSubmitting ? '#' : JobsLinks.Clients}>
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  )
}

export const CreateClient = Page(View, 'Create Client')
