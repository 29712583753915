import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { PrimaryButton, PrimaryLink } from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { FormTextInput } from '../../../../component/field'
import { PageBody } from '../../../../component/misc'
import { CreateDisciplinaryCase as CreateDisciplinaryCaseModel } from '../../../../model/request/disciplinary-cases'
import { Employee, NamedEntity } from '../../../../model/response'
import {
  DisciplinaryCasesService,
  DisciplinaryCaseTypesService,
  EmployeesService,
} from '../../../../service'
import { Permissions } from '../../../../util/constants'
import { useLoadingMessage } from '../../../../util/hook'
import { validateWithModel } from '../../../../util/validation'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { SettingsLinks } from '../../settings/links'
import { EmployeesLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Employees, name: 'Employees' },
  {
    path: EmployeesLinks.DisciplinaryCases,
    name: 'Disciplinary Cases',
  },
  {
    path: '',
    name: 'Create Disciplinary Case',
  },
]

const View: React.FC<PageComponentProps> = ({
  history,
  authState,
  loaderState,
  permissionsState,
}) => {
  const alert = useAlert()
  const { state } = useLocation<any>()
  const [employees, setEmployees] = useState<Employee[]>([])
  const [caseTypes, setCaseTypes] = useState<NamedEntity[]>([])
  const { isLoading, message, updateLoader } = useLoadingMessage()

  const getDependencies = async () => {
    {
      updateLoader(true, 'Fetching employees')
      const response = await EmployeesService.list()
      updateLoader(false)

      if (response.data) {
        setEmployees(
          response.data.filter(({ id }) => authState.user?.id !== id)
        )
      }

      if (!response.success) {
        alert.error('Error fetching employees')
      }
    }

    {
      updateLoader(true, 'Fetching disciplinary case types')
      const response = await DisciplinaryCaseTypesService.list()
      updateLoader(false)

      if (response.data) {
        setCaseTypes(response.data)
      }

      if (!response.success) {
        alert.error('Error fetching employees')
      }
    }
  }

  useEffect(() => {
    getDependencies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      loadingMessage={message}
    >
      <Formik
        initialValues={
          new CreateDisciplinaryCaseModel(
            state?.key === 'create-disciplinary-case'
              ? (state?.values as CreateDisciplinaryCaseModel)
              : undefined,
            authState.user as Employee
          )
        }
        validate={validateWithModel(CreateDisciplinaryCaseModel)}
        onSubmit={async values => {
          loaderState.setLoading('Creating disciplinary case')
          const response = await DisciplinaryCasesService.create(values)
          loaderState.setDone()

          if (response.success) {
            alert.success('Disciplinary case created successfully')
            history.push(EmployeesLinks.DisciplinaryCases, null)
            return
          }

          alert.error(response.message)
        }}
      >
        {({ handleChange, handleSubmit, isSubmitting, values }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Disciplinary Case</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <FormTextInput
                  label="Employee"
                  as="select"
                  name="employee_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select employee
                  </option>
                  {employees.map(({ id, first_name, last_name }, i) => (
                    <option value={id} key={i}>
                      {first_name} {last_name}
                    </option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Disciplinary Case Type"
                  addLink={
                    permissionsState.hasPermission(
                      Permissions.SystemConfiguration
                    )
                      ? {
                          to: SettingsLinks.CreateDisciplinaryCaseType,
                          state: {
                            key: 'create-disciplinary-case',
                            referrer: EmployeesLinks.CreateDisciplinaryCase,
                            field: 'disciplinary_case_id',
                            values,
                          },
                        }
                      : undefined
                  }
                  as="select"
                  name="disciplinary_case_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select disciplinary case type
                  </option>
                  {caseTypes.map(({ id, name }, i) => (
                    <option value={id} key={i}>
                      {name}
                    </option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Penalty"
                  name="penalty"
                  placeholder="Penalty"
                  autoCapitalize="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink
                to={
                  isSubmitting
                    ? '#'
                    : {
                        pathname: EmployeesLinks.DisciplinaryCases,
                        state: null,
                      }
                }
              >
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  )
}

export const CreateDisciplinaryCase = Page(View, 'Create Disciplinary Case')
