import {
  CreateNamedEntity,
  EditNamedEntity,
} from '../model/request/named-entities'
import { NamedEntity } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class EventCategoriesService {
  public static async create(details: CreateNamedEntity) {
    return ApiClient.post<CreateNamedEntity, NamedEntity>(
      'holiday-category/create',
      details,
      CreateNamedEntity
    )
  }

  public static async list() {
    return ApiClient.get<NamedEntity[]>('holiday-category/view-list')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<NamedEntity>('holiday-category/show', id)
  }

  public static async update(id: number | string, details: EditNamedEntity) {
    return ApiClient.patch<EditNamedEntity, NamedEntity>(
      'holiday-category/update',
      id,
      details,
      EditNamedEntity
    )
  }
}
