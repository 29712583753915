export enum JobStages {
  Planning = 'Planning',
  Deployment = 'Deployment',
  InitialDraftReport = 'Initial Draft Report',
  InitialDraftReview = 'Initial Draft Review',
  FinalDraft = 'Final Draft',
  DraftPresentation = 'Draft Presentation',
  AuditApproval = 'Audit Approval',
}

export const JobStagesSerial = [
  JobStages.Planning,
  JobStages.Deployment,
  JobStages.InitialDraftReport,
  JobStages.InitialDraftReview,
  JobStages.FinalDraft,
  JobStages.DraftPresentation,
  JobStages.AuditApproval,
]
