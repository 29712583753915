import React from 'react'
import { Switch } from 'react-router-dom'
import { ExternalRoute } from '../component/misc'
import { FirstLogin } from '../page/auth/FirstLogin'
import { ForgotPassword } from '../page/auth/ForgotPassword'
import { Login } from '../page/auth/Login'
import { ResetPassword } from '../page/auth/ResetPassword'
import { MainRoutes } from './main'

export const Routes = () => (
  <Switch>
    <ExternalRoute path="/login" component={Login} />
    <ExternalRoute path="/first-login" component={FirstLogin} />
    <ExternalRoute path="/forgot-password" component={ForgotPassword} />
    <ExternalRoute path="/reset-password/:token" component={ResetPassword} />
    <MainRoutes />
  </Switch>
)
