import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Check, X } from "react-feather";
import { useLocation } from "react-router-dom";
import { PrimaryButton, PrimaryLink } from "../../../component/button";
import { CreateEditForm } from "../../../component/container";
import { DatePicker, FormTextInput } from "../../../component/field";
import { PageBody } from "../../../component/misc";
import { CreateEvent as CreateEventModel } from "../../../model/request/events";
import { NamedEntity } from "../../../model/response";
import { EventCategoriesService, EventsService } from "../../../service";
import { Permissions } from "../../../util/constants";
import { useLoadingMessage } from "../../../util/hook";
import { validateWithModel } from "../../../util/validation";
import { Page, PageComponentProps } from "../../Page";
import { SidebarLinks } from "../links";
import { SettingsLinks } from "../settings/links";
import { EventsLinks } from "./links";

const breadcrumbs = [
  { path: SidebarLinks.Index, name: "Dashboard" },
  {
    path: SidebarLinks.Events,
    name: "Events",
  },
  {
    path: "",
    name: "Create Event",
  },
];

const View: React.FC<PageComponentProps> = ({
  history,
  loaderState,
  permissionsState,
}) => {
  const alert = useAlert();
  const { state } = useLocation<any>();
  const [categories, setCategories] = useState<NamedEntity[]>();
  const { isLoading, message, updateLoader } = useLoadingMessage();

  const getDependencies = async () => {
    updateLoader(true, "Fetching event categories");
    setCategories((await EventCategoriesService.list()).data);
    updateLoader(false);
  };

  useEffect(() => {
    getDependencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      loadingMessage={message}
    >
      <Formik
        initialValues={
          new CreateEventModel(
            state?.key === "create-event"
              ? (state?.values as CreateEventModel)
              : undefined
          )
        }
        validate={validateWithModel(CreateEventModel)}
        onSubmit={async (values) => {
          loaderState.setLoading();
          const response = await EventsService.create(values);
          loaderState.setDone();

          if (response.success) {
            alert.success("Event created successfully");
            history.push(SidebarLinks.Events, null);
            return;
          }

          alert.error(response.message);
        }}
      >
        {({
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          setFieldValue,
        }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Event</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <FormTextInput
                  label="Name"
                  name="name"
                  placeholder="Event name"
                  autoCapitalize="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Event Category"
                  addLink={
                    permissionsState.hasPermission(
                      Permissions.SystemConfiguration
                    )
                      ? {
                          to: SettingsLinks.CreateEventCategory,
                          state: {
                            key: "create-event",
                            referrer: EventsLinks.CreateEvent,
                            field: "holiday_category_id",
                            values,
                          },
                        }
                      : undefined
                  }
                  as="select"
                  name="holiday_category_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select category
                  </option>
                  {categories?.map(({ id, name }, i) => (
                    <option value={id} key={i}>
                      {name}
                    </option>
                  ))}
                </FormTextInput>
                <DatePicker
                  label="Start of Event"
                  name="start_date"
                  minDate={new Date()}
                  onChange={(date: string) => {
                    if (moment(values.end_date).isBefore(date, "d")) {
                      setFieldValue("end_date", date);
                    }
                  }}
                  disabled={isSubmitting}
                />
                <DatePicker
                  label="End of Event"
                  name="end_date"
                  minDate={values.start_date}
                  disabled={isSubmitting}
                />
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink
                to={
                  isSubmitting
                    ? "#"
                    : {
                        pathname:
                          (state as any)?.referrer || SidebarLinks.Events,
                        state: null,
                      }
                }
              >
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  );
};

export const CreateEvent = Page(View, "Create Event");
