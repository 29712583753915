import React from 'react'
import { DisciplinaryCaseTypesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { CreateNamedEntityForm } from '../common/CreateForm'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.DisciplinaryCaseTypes,
    name: 'Disciplinary Case Types',
  },
  {
    path: '',
    name: 'Create Disciplinary Case Type',
  },
]

const View: React.FC<PageComponentProps> = ({ history }) => {
  return (
    <CreateNamedEntityForm
      breadcrumbs={breadcrumbs}
      service={DisciplinaryCaseTypesService}
      history={history}
      singular="Disciplinary Case Type"
      redirect={SettingsLinks.DisciplinaryCaseTypes}
    />
  )
}

export const CreateDisciplinaryCaseType = Page(
  View,
  'Create Disciplinary Case Type'
)
