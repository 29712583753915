import React from 'react'
import { JobTypesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { SingleNamedEntityPage } from '../common/SinglePage'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.JobTypes,
    name: 'Job Types',
  },
  { path: '', name: 'Job Type' },
]

const View: React.FC<PageComponentProps> = ({ params }) => (
  <SingleNamedEntityPage
    breadcrumbs={breadcrumbs}
    service={JobTypesService}
    singular="Job type"
    id={params.id}
  />
)

export const SingleJobType = Page(View, 'Job Type')
