import { Permissions } from '../../../util/constants'
import { SidebarLinks } from '../links'

export enum JobsLinks {
  ProgressBoard = '/jobs/progress-board',
  CreateJob = '/jobs/create',
  Clients = '/jobs/clients',
  CreateClient = '/jobs/clients/create',
  FinancialRequests = '/jobs/financial-requests',
  CreateFinancialRequest = '/jobs/financial-requests/create',
}

export const links = [
  { path: SidebarLinks.Jobs, name: 'Jobs', exact: true },
  {
    path: JobsLinks.ProgressBoard,
    name: 'Progress Board',
    exact: true,
  },
  {
    path: JobsLinks.Clients,
    name: 'Clients',
    exact: true,
    permission: Permissions.AdminViewAccess,
  },
  {
    path: JobsLinks.FinancialRequests,
    name: 'Financial Requests',
    exact: true,
  },
]
