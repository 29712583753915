import React from 'react'
import { PageBody } from '../../../component/misc'
import { RoleNames } from '../../../util/constants'
import { Page } from '../../Page'
import { SidebarLinks } from '../links'
import { settingsLinks as links } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  { path: '', name: 'Roles' },
]

const View = () => {
  return (
    <PageBody
      title="Roles"
      tagline="View the roles on the platform"
      breadcrumbs={breadcrumbs}
      pageLinks={links}
    >
      <ul>
        {Object.values(RoleNames).map((role, i) => (
          <li key={i}>
            <span>{role}</span>
          </li>
        ))}
      </ul>
    </PageBody>
  )
}

export const Roles = Page(View, 'Roles')
