import { Formik } from 'formik'
import React from 'react'
import { Check } from 'react-feather'
import { Link } from 'react-router-dom'
import { PrimaryButton } from '../../component/button'
import { EntryForm } from '../../component/container'
import { FormTextInput } from '../../component/field'
import { Login as LoginModel } from '../../model/request/auth'
import { validateWithModel } from '../../util/validation'
import { Page, PageComponentProps } from '../Page'
import { CentralizerContainer } from './common'

const View: React.FC<PageComponentProps> = ({ authState, loaderState }) => {
  return (
    <CentralizerContainer>
      <Formik
        initialValues={new LoginModel()}
        validate={validateWithModel(LoginModel)}
        onSubmit={async ({ email, password }) => {
          loaderState.setLoading('Logging you in')
          await authState.login(email, password)
          loaderState.setDone()
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <EntryForm onSubmit={handleSubmit}>
            <header>
              <img src="/img/logo.png" alt="ABDC Logo" />
              <h6>Sign into your ABDC account</h6>
            </header>
            <main>
              <FormTextInput
                label="Email"
                name="email"
                type="email"
                placeholder="Email address"
                disabled={isSubmitting}
              />
              <FormTextInput
                label="Password"
                name="password"
                type="password"
                placeholder="Password"
                disabled={isSubmitting}
              />
              <div>
                <Link to={isSubmitting ? '#' : '/forgot-password'}>
                  <small>Forgot password? Click here</small>
                </Link>
              </div>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Log in</span>
                <Check />
              </PrimaryButton>
            </footer>
          </EntryForm>
        )}
      </Formik>
    </CentralizerContainer>
  )
}

export const Login = Page(View, 'Log into ABDC')
