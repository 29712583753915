export enum Colors {
  CancelButton = '#e2ece4',
  CancelButtonStroke = '#b2c3b5',
  CancelButtonText = '#5c675e',
  Primary = '#569d58',
  PrimaryDark = '#457d47',
  PrimaryBright = '#e6f7e6',
  Header = '#555a55',
  SubHeader = '#313533',
  TextInput = '#7f808d',
  SmallLink = '#9da29e',
  LightGrey = '#ebefee',
  Grey = '#bfc2ce',
  DarkGrey = '#606662',
  Green = '#569d58',
  Red = '#d9350f',
}

export const RandomColors = [
  'ff9800',
  'e8d25a',
  '2196f3',
  '7174f7',
  '49396f',
  '46775a',
  '9e4666',
  '6f9e46',
  'e4c033',
  '292644',
]
