import React from 'react'
import { EventCategoriesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { SingleNamedEntityPage } from '../common/SinglePage'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.EventCategories,
    name: 'Event Categories',
  },
  { path: '', name: 'Event Category' },
]

const View: React.FC<PageComponentProps> = ({ params }) => (
  <SingleNamedEntityPage
    breadcrumbs={breadcrumbs}
    service={EventCategoriesService}
    singular="Event category"
    id={params.id}
  />
)

export const SingleEventCategory = Page(View, 'Event Category')
