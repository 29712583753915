import { Matches, MinLength } from 'class-validator'
import { EmailValidationPattern } from '../../../util/validation'

export class FirstLogin {
  @Matches(EmailValidationPattern, { message: 'Invalid ABDC email' })
  public email!: string

  @MinLength(8, { message: 'Must be at least 8 characters long' })
  public password!: string
}
