import React from 'react'
import { AssetCategoriesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { SingleNamedEntityPage } from '../../settings/common/SinglePage'
import { OrganizationLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Organization, name: 'Organization' },
  {
    path: OrganizationLinks.AssetCategories,
    name: 'Asset Categories',
  },
  {
    path: '',
    name: 'Asset Category',
  },
]

const View: React.FC<PageComponentProps> = ({ params }) => (
  <SingleNamedEntityPage
    breadcrumbs={breadcrumbs}
    service={AssetCategoriesService}
    singular="Asset Category"
    canDelete
    noStatus
    id={params.id}
  />
)

export const SingleAssetCategory = Page(View, 'Asset Category')
