import { IsNotEmpty, IsPositive, MinLength } from 'class-validator'
import { Employee } from '../../response'

export class CreateDisciplinaryCase {
  @IsPositive({ message: 'Employee is invalid' })
  public employee_id!: number

  @IsPositive({ message: 'Disciplinary case type is invalid' })
  public disciplinary_case_id!: number

  @IsPositive({ message: 'Creator is invalid' })
  public creator_id!: number

  @IsNotEmpty({ message: 'Enter a penalty' })
  @MinLength(3, { message: 'Penalty is too short' })
  public penalty!: string

  constructor(
    object: CreateDisciplinaryCase | undefined,
    creator: Employee,
    employeeId?: number
  ) {
    if (object) {
      this.employee_id = object.employee_id
      this.disciplinary_case_id = object.disciplinary_case_id
      this.creator_id = creator?.id || 0
      this.penalty = object.penalty
      return
    }

    this.employee_id = employeeId || 0
    this.disciplinary_case_id = 0
    this.creator_id = creator?.id || 0
    this.penalty = ''
  }

  public static transform(object: CreateDisciplinaryCase) {
    object.employee_id = Number.parseInt(object.employee_id as any, 10)
    object.disciplinary_case_id = Number.parseInt(
      object.disciplinary_case_id as any,
      10
    )
  }
}
