import React from 'react'
import { OfficesService } from '../../../../service'
import { Page } from '../../../Page'
import { SidebarLinks } from '../../links'
import { ListNamedEntitiesPage } from '../common/ListPage'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: '',
    name: 'Offices',
  },
]

const View = () => (
  <ListNamedEntitiesPage
    breadcrumbs={breadcrumbs}
    service={OfficesService}
    singular="Office"
    plural="Offices"
    listLink={SettingsLinks.Offices}
    createLink={SettingsLinks.CreateOffice}
  />
)

export const Offices = Page(View, 'Offices')
