import { Field, Formik } from 'formik'
import { filter, includes, matches } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Link } from 'react-router-dom'
import { Column, Row } from 'react-table'
import {
  ConfirmAlertConfig,
  generateConfirmAlert,
} from '../../../../component/alert'
import { SlickLink } from '../../../../component/button'
import { Input } from '../../../../component/field'
import { FilterBar, PageBody, Table } from '../../../../component/misc'
import { DisciplinaryCase, Employee } from '../../../../model/response'
import { DisciplinaryCasesService } from '../../../../service'
import {
  DisciplinaryCaseStateMap,
  DisciplinaryCaseStates,
  Permissions,
} from '../../../../util/constants'
import { useLoadingMessage } from '../../../../util/hook'
import { Page, PageComponentProps } from '../../../Page'
import { NotCreated } from '../../common'
import { SidebarLinks } from '../../links'
import { EmployeesLinks, employeesLinks as links } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Employees, name: 'Employees' },
  {
    path: '',
    name: 'Disciplinary Cases',
  },
]

enum Filters {
  All,
  Open,
  Closed,
}

const filters = {
  [Filters.All]: {},
  [Filters.Open]: { status: DisciplinaryCaseStates.Open },
  [Filters.Closed]: { status: DisciplinaryCaseStates.Closed },
}

const View: React.FC<PageComponentProps> = ({
  permissionsState,
  loaderState,
}) => {
  const alert = useAlert()
  const { isLoading, updateLoader, message } = useLoadingMessage()
  const [tableColumns, setTableColumns] = useState<any[]>([])
  const [disciplinaryCases, setDisciplinaryCases] = useState<{
    [filter: string]: DisciplinaryCase[]
  }>({})

  const update = async (id: number, open?: boolean) => {
    loaderState.setLoading('Updating disciplinary case')
    const response = await DisciplinaryCasesService[open ? 'open' : 'close'](id)
    loaderState.setDone()

    if (response.success) {
      alert.success('Case successfully updated')
      await getDisciplinaryCases()
      return
    }

    alert.error(response.message)
  }

  const getDisciplinaryCases = async () => {
    updateLoader(true, 'Fetching disciplinary cases')
    const response = await DisciplinaryCasesService.list()
    updateLoader(false)

    if (response.data) {
      const filtered: { [filter: string]: DisciplinaryCase[] } = {}

      Object.entries(filters).forEach(([filterName, constraint]) => {
        filtered[filterName] = filter(response.data, matches(constraint))
      })

      setDisciplinaryCases(filtered)

      const columns: Column<DisciplinaryCase>[] = [
        { Header: 'No.', accessor: 'id' },
        {
          Header: 'Assigned To',
          accessor: ({ case_owner }: DisciplinaryCase) => (
            <Link
              to={
                permissionsState.userAllowed(case_owner.slug)
                  ? `${SidebarLinks.Employees}/${case_owner.slug}`
                  : '?'
              }
            >
              {getFullName(case_owner)}
            </Link>
          ),
          sortType: (
            { original: { case_owner: a } }: Row<DisciplinaryCase>,
            { original: { case_owner: b } }: Row<DisciplinaryCase>
          ) =>
            `${a.first_name} ${a.last_name}`.toLowerCase() >
            `${b.first_name} ${b.last_name}`.toLowerCase()
              ? 1
              : -1,
        },
        {
          Header: 'Assigned By',
          accessor: ({ case_creator }: DisciplinaryCase) => (
            <Link
              to={
                permissionsState.userAllowed(case_creator.slug)
                  ? `${SidebarLinks.Employees}/${case_creator.slug}`
                  : '?'
              }
            >
              {getFullName(case_creator)}
            </Link>
          ),
          sortType: (
            { original: { case_creator: a } }: Row<DisciplinaryCase>,
            { original: { case_creator: b } }: Row<DisciplinaryCase>
          ) => {
            return `${a.first_name} ${a.last_name}`.toLowerCase() >
              `${b.first_name} ${b.last_name}`.toLowerCase()
              ? 1
              : -1
          },
        },
        { Header: 'Case', accessor: 'case_type.name' },
        { Header: 'Penalty', accessor: 'penalty' },
        {
          Header: 'Status',
          accessor: ({ status }: DisciplinaryCase) =>
            DisciplinaryCaseStateMap[status as any],
        },
      ]

      if (permissionsState.hasPermission(Permissions.AdminViewAccess)) {
        columns.push({
          Header: ' ',
          disableSortBy: true,
          accessor: ({ id, status }: DisciplinaryCase) => (
            <button
              onClick={() => {
                const shouldOpen = status === DisciplinaryCaseStates.Closed

                const config: ConfirmAlertConfig = {
                  header: `${shouldOpen ? 'Open' : 'Close'} case`,
                  message: `Are you sure you want to ${
                    shouldOpen ? 'open' : 'close'
                  } this case?`,
                  confirmText: `Yes, ${shouldOpen ? 'open' : 'close'}`,
                  cancelText: 'No, cancel',
                }

                generateConfirmAlert(
                  config,
                  update.bind(undefined, id, shouldOpen)
                )
              }}
            >
              <span>
                {status === DisciplinaryCaseStates.Closed ? 'Open' : 'Close'}
              </span>
            </button>
          ),
        })
      }
      setTableColumns(columns)
      return
    }

    alert.error('Error fetching disciplinary cases')
  }

  const getFullName = ({ first_name, last_name }: Employee) =>
    `${first_name} ${last_name}`

  useEffect(() => {
    getDisciplinaryCases()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Disciplinary Cases"
      pageLinks={links}
      isLoading={isLoading}
      loadingMessage={message}
      tagline="View / manage the disciplinary cases on the platform"
      actionArea={
        permissionsState.hasPermission(Permissions.AdminViewAccess) ? (
          <SlickLink to={EmployeesLinks.CreateDisciplinaryCase}>
            <span>Create Disciplinary Case</span>
          </SlickLink>
        ) : null
      }
    >
      {disciplinaryCases[Filters.All]?.length ? (
        <Formik
          initialValues={{ filter: Filters.All, name: '' }}
          onSubmit={() => {}}
        >
          {({ values }) => {
            const list = disciplinaryCases[values.filter].filter(
              ({ case_owner, case_creator, case_type, status }) => {
                const name =
                  getFullName(case_creator) +
                  getFullName(case_owner) +
                  case_type.name +
                  status
                return values.name.length >= 3
                  ? includes(name.toLowerCase(), values.name.toLowerCase())
                  : true
              }
            )

            return (
              <>
                <FilterBar>
                  <section className="dropdown">
                    <Field as="select" name="filter">
                      <option value={Filters.All}>All</option>
                      <option value={Filters.Open}>Open</option>
                      <option value={Filters.Closed}>Closed</option>
                    </Field>
                  </section>
                  <Field
                    as={Input}
                    name="name"
                    placeholder="Filter by name..."
                  />
                </FilterBar>
                <Table columns={tableColumns} data={list} />
              </>
            )
          }}
        </Formik>
      ) : (
        NotCreated(
          'disciplinary case',
          'disciplinary cases',
          EmployeesLinks.CreateDisciplinaryCase
        )
      )}
    </PageBody>
  )
}

export const DisciplinaryCases = Page(View, 'Disciplinary Cases')
