import { IsPositive, ValidationError } from 'class-validator'
import { ApprovalLimits } from '../response'
import { positiveError } from './leaves/common'

export class SetApprovalLimits {
  @IsPositive({ message: positiveError })
  public managing_partner!: number

  @IsPositive({ message: positiveError })
  public engagement_partner!: number

  constructor(object?: ApprovalLimits) {
    if (object) {
      this.managing_partner = object.managing_partner
      this.engagement_partner = object.engagement_partner
      return
    }

    this.managing_partner = 1000000
    this.engagement_partner = 500000
  }

  public static transform(object: SetApprovalLimits) {
    object.managing_partner = Number.parseInt(
      object.managing_partner as any,
      10
    )

    object.engagement_partner = Number.parseInt(
      object.engagement_partner as any,
      10
    )
  }

  public static extraValidation(object: SetApprovalLimits) {
    if (object.managing_partner <= object.engagement_partner) {
      return [
        {
          ...new ValidationError(),
          property: 'managing_partner',
          constraints: {
            value: 'Limit must be higher than engagement partner\'s',
          },
        },
        {
          ...new ValidationError(),
          property: 'engagement_partner',
          constraints: {
            value: 'Limit must be lower than managing partner\'s',
          },
        },
      ]
    }

    return []
  }
}
