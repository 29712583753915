import { IsNotEmpty, MinLength } from 'class-validator'

export class RejectTeamTimeline {
  @IsNotEmpty({ message: 'Enter a reason for rejection' })
  @MinLength(3, { message: 'Reason for rejection is too short' })
  public response!: string

  constructor() {
    this.response = ''
  }
}
