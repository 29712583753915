import React, { useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useHistory, useLocation } from 'react-router'
import { Employee } from '../model/response'
import { AuthService } from '../service/auth.service'
import { ApiClient } from '../util/ApiClient'
import { Permissions, RolePermissions } from '../util/constants'
import { LoaderContext } from './loader.context'

export interface AuthState {
  user: Employee | null
  isLoading: boolean
  ready?: boolean
  shouldSetUp: boolean
  firstLogin: boolean
  login: (email: string, password: string, redirectTo?: string) => Promise<void>
  logout: (refer?: boolean) => void
  refresh: () => void
  setUser: (user: Employee) => void
  setShouldSetUp: (shouldSetUp: boolean) => void
}

const defaultState: AuthState = {
  user: null as any,
  isLoading: false,
  shouldSetUp: false,
  firstLogin: false,
  login: async (email: string, password: string) => {},
  logout: () => {},
  refresh: () => {},
  setUser: (user: Employee) => {},
  setShouldSetUp: () => {},
}

export const AuthContext = React.createContext<AuthState>(defaultState)
export const AuthConsumer = AuthContext.Consumer

export const AuthProvider: React.FC = ({ children }) => {
  const alert = useAlert()
  const { state } = useLocation<any>()
  const history = useHistory()
  const [user, setUser] = React.useState<Employee | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const { setLoading, setDone } = React.useContext(LoaderContext)
  const [ready, setReady] = React.useState(false)
  const [shouldSetUp, setShouldSetUp] = React.useState(false)
  const [firstLogin, setFirstLogin] = React.useState(false)

  useEffect(() => {
    // setReady(true)
    refresh(state?.referrer)
    // eslint-disable-next-line
  }, [])

  const login = async (
    email: string,
    password: string,
    redirectTo?: string
  ) => {
    setLoading('Logging in')
    const response = await AuthService.login(email, password)
    setDone()

    if (response.success) {
      if (response.data?.user.first_login) {
        alert.show('You have to change your password before logging in')
        history.push('/first-login', { email })
        setFirstLogin(true)
      } else {
        const _ = response.data?.user as Employee
        setUser(_)

        if (
          RolePermissions[_.role].includes(Permissions.SystemConfiguration) &&
          firstLogin
        ) {
          setShouldSetUp(true)
          sessionStorage.setItem('setup', 'true')
        }

        ApiClient.setToken(
          response.data?.access_token as string,
          response.data?.expires_at as string
        )
        alert.success('User logged in successfully')

        if (redirectTo) {
          history.replace(redirectTo)
        }
      }
    } else {
      alert.error(response.message)
    }
  }

  const logout = async (refer?: boolean) => {
    setLoading('Logging out')
    await AuthService.logout()
    setDone()

    setUser(null)
    setShouldSetUp(false)
    sessionStorage.removeItem('setup')
    setFirstLogin(false)

    alert.success('User logged out successfully')
    history.push('/login', { referrer: history.location.pathname })
  }

  const refresh = async (redirectIfValid?: string) => {
    setIsLoading(true)
    try {
      const response = await AuthService.getAuth()
      setReady(true)

      if (!response.success) {
        setUser(null)
        setIsLoading(false)
        return
      }

      setUser(response.data as Employee)
      setShouldSetUp(sessionStorage.getItem('setup') === 'true' ? true : false)

      if (redirectIfValid) {
        history.replace(redirectIfValid)
      }
    } catch (e) {
      setUser(null)
    }

    setIsLoading(false)
    setReady(true)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        shouldSetUp,
        firstLogin,
        ready,
        login,
        logout,
        refresh,
        setUser,
        setShouldSetUp,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
