import React from 'react'
import { useAlert } from 'react-alert'

export interface LoaderState {
  isLoading: boolean
  setLoading: (message?: string) => void
  setDone: (message?: string) => void
  message?: string
}

const defaultState: LoaderState = {
  isLoading: false,
  setLoading: () => {},
  setDone: () => {},
}

export const LoaderContext = React.createContext<LoaderState>(defaultState)
export const LoaderConsumer = LoaderContext.Consumer

export const LoaderProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [message, setMessage] = React.useState<string>()
  const alert = useAlert()

  const setLoading = (loaderMessage?: string) => {
    setMessage(loaderMessage)
    setIsLoading(true)
  }

  const setDone = (completionMessage?: string) => {
    setIsLoading(false)

    if (completionMessage) {
      alert.info(completionMessage)
    }
  }

  return (
    <LoaderContext.Provider value={{ isLoading, setLoading, setDone, message }}>
      {children}
    </LoaderContext.Provider>
  )
}
