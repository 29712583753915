import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { createGlobalStyle } from 'styled-components'
import { generateConfirmAlert } from '../../../component/alert'
import { SlickLink } from '../../../component/button'
import { Loader, PageBody } from '../../../component/misc'
import { Event } from '../../../model/response'
import { EventsService } from '../../../service'
import { Colors, Effects } from '../../../style'
import { Permissions } from '../../../util/constants'
import { useLoadingMessage } from '../../../util/hook'
import { Page, PageComponentProps } from '../../Page'
import { SidebarLinks } from '../links'
import { EventsLinks, links } from './links'

const CustomStyles = createGlobalStyle`
  .rbc-event {
    background: ${Colors.Primary};
    border: 0;
    border-radius: 0;
    padding: 5px;
    font-size: 90%;
    font-weight: bold;
    box-shadow: ${Effects.DropShadow};

    &.rbc-selected {
      background: ${Colors.PrimaryDark};
    }
  }

  .rbc-month-view {
    background: #ffffff50;
    backdrop-filter: blur(10px);
    border: 2px solid ${Colors.LightGrey};
    box-shadow: ${Effects.DropShadow};
  }

  .rbc-row-segment {
    padding: 0;
  }

  .rbc-header {
    padding: 10px;
  }

  .rbc-date-cell {
    padding: 10px;
    text-align: left;
  }

  .rbc-today {
    background: ${Colors.PrimaryBright};
  }

  .rbc-off-range-bg {
    background: #f7f7f7;
  }

  .rbc-day-bg + .rbc-day-bg,
  .rbc-header + .rbc-header {
    border-left: 1px solid ${Colors.LightGrey};
  }

  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid ${Colors.LightGrey};
  }

  .rbc-toolbar {
    margin-bottom: 20px;
  }

  .rbc-toolbar .rbc-toolbar-label {
    padding-left: 20px;
    text-align: left;
    font-weight: bold;
    font-size: 1.5em;
    color: ${Colors.Primary};
  }

  .rbc-btn-group button {
    border-radius: 0;
    border-width: 2px;
    border-color: ${Colors.LightGrey};
  }

  .rbc-btn-group button + button {
    margin-left: 10px;
  }
`

const localizer = momentLocalizer(moment)

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Events, name: 'Events' },
  { path: '', name: 'Calendar' },
]

const View: React.FC<PageComponentProps> = ({ history, permissionsState }) => {
  const alert = useAlert()
  const [events, setEvents] = useState<Event[]>([])
  const { isLoading, message, updateLoader } = useLoadingMessage()

  const goToCreate = (start_date: string | Date, end_date: string | Date) => {
    history.push(EventsLinks.CreateEvent, {
      key: 'create-event',
      values: {
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
      },
    })
  }

  const getEvents = async () => {
    updateLoader(true, 'Fetching events')
    const response = await EventsService.list()
    updateLoader(false)

    if (response.success) {
      setEvents(response.data as Event[])
      return
    }

    alert.error(response.message)
  }

  useEffect(() => {
    getEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      title="Events"
      tagline="View the events on the platform"
      breadcrumbs={breadcrumbs}
      pageLinks={links}
      actionArea={
        permissionsState.hasPermission(Permissions.AdminViewAccess) ? (
          <SlickLink to={EventsLinks.CreateEvent}>
            <span>Create Event</span>
          </SlickLink>
        ) : null
      }
    >
      <CustomStyles />
      <Loader isLoading={isLoading} message={message}>
        <Calendar
          selectable={permissionsState.hasPermission(
            Permissions.AdminViewAccess
          )}
          localizer={localizer}
          titleAccessor="name"
          startAccessor="start_date"
          endAccessor="end_date"
          allDayAccessor={() => true}
          events={events}
          views={{
            month: true,
            week: true,
          }}
          onSelectEvent={({ id }) => {
            if (permissionsState.hasPermission(Permissions.AdminViewAccess)) {
              history.push(`${SidebarLinks.Events}/${id}`)
            }
          }}
          onSelectSlot={({ action, start, end }) => {
            if (action === 'click') {
              return
            }

            generateConfirmAlert(
              {
                header: `Create new event`,
                message: `Do you want to create an event in this interval?`,
                confirmText: `Yes, create`,
                cancelText: 'No, cancel',
              },
              goToCreate.bind(undefined, start, end)
            )
          }}
        />
      </Loader>
    </PageBody>
  )
}

export const Events = Page(View, 'Events')
