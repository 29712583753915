import React from 'react'
import { positions, Provider as AlertProvider } from 'react-alert'
import ReactDOM from 'react-dom'
import App from './App'
import { AlertTemplate } from './component/alert'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <AlertProvider
    template={AlertTemplate}
    position={positions.BOTTOM_RIGHT}
    timeout={5000}
  >
    <App />
  </AlertProvider>,
  document.getElementById('root')
)

serviceWorker.register()
