import { groupBy, toPairs } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { SlickLink } from '../../../component/button'
import { Box, Container } from '../../../component/container'
import { Loader, PageBody } from '../../../component/misc'
import { Event } from '../../../model/response'
import { EventsService } from '../../../service'
import { Permissions } from '../../../util/constants'
import { Page, PageComponentProps } from '../../Page'
import { NotCreated } from '../common'
import { SidebarLinks } from '../links'
import { EventsLinks, links } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Events, name: 'Events' },
  {
    path: '',
    name: 'Manage Events',
  },
]

export enum Filters {
  All,
  Active,
  Inactive,
  OnLeave,
}

export const filters = {
  [Filters.All]: {},
  [Filters.Active]: { active: true },
  [Filters.Inactive]: { active: false },
  [Filters.OnLeave]: { onLeave: true },
}

const View: React.FC<PageComponentProps> = ({ permissionsState }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [groups, setGroups] = useState<{ [name: string]: Event[] }>({})
  const pairs = useMemo(() => toPairs(groups), [groups])

  const getEvents = async () => {
    const response = await EventsService.list()

    if (response.data) {
      setGroups(groupBy(response.data, 'category.name'))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    getEvents()
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Events"
      pageLinks={links}
      tagline="View / manage the events on the platform"
      actionArea={
        permissionsState.hasPermission(Permissions.AdminViewAccess) ? (
          <SlickLink to={EventsLinks.CreateEvent}>
            <span>Create Event</span>
          </SlickLink>
        ) : null
      }
    >
      <Loader isLoading={isLoading}>
        {pairs?.length
          ? pairs.map(([groupedName, groupedEvents], i) => (
              <React.Fragment key={i}>
                <span>{groupedName}</span>
                <Container>
                  {groupedEvents.map(({ id, name, category }) => (
                    <Box to={`${SidebarLinks.Events}/${id}`} key={id}>
                      <span>{name}</span>
                      <h6>{category.name}</h6>
                    </Box>
                  ))}
                </Container>
              </React.Fragment>
            ))
          : NotCreated('event', 'events', EventsLinks.CreateEvent)}
      </Loader>
    </PageBody>
  )
}

export const ManageEvents = Page(View, 'Manage Events')
