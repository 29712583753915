import { Permissions } from '../../../util/constants'
import { SidebarLinks } from '../links'

export enum EventsLinks {
  CreateEvent = '/events/create',
  ManageEvents = '/events/manage',
}

export const links = [
  { path: SidebarLinks.Events, name: 'Calendar', exact: true },
  {
    path: EventsLinks.ManageEvents,
    name: 'Manage',
    exact: true,
    permission: Permissions.AdminViewAccess,
  },
]
