import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { PrimaryButton, PrimaryLink } from '../../../component/button'
import { CreateEditForm } from '../../../component/container'
import { DatePicker, FormTextInput } from '../../../component/field'
import { PageBody } from '../../../component/misc'
import { CreateLeaveRequest as CreateLeaveRequestModel } from '../../../model/request/requests'
import { Employee, Leave } from '../../../model/response'
import { LeaveRequestsService, LeavesService } from '../../../service'
import { Permissions } from '../../../util/constants'
import { useLoadingMessage } from '../../../util/hook'
import { validateWithModel } from '../../../util/validation'
import { Page, PageComponentProps } from '../../Page'
import { SidebarLinks } from '../links'
import { SettingsLinks } from '../settings/links'
import { RequestsLinks } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  {
    path: SidebarLinks.Requests,
    name: 'Requests',
  },
  {
    path: RequestsLinks.LeaveRequests,
    name: 'Leave Requests',
  },
  {
    path: '',
    name: 'Create Leave Request',
  },
]

const View: React.FC<PageComponentProps> = ({
  history,
  loaderState,
  authState,
  permissionsState,
}) => {
  const alert = useAlert()
  const { state } = useLocation()
  const [leaveTypes, setLeaveTypes] = useState<Leave[]>()
  const { isLoading, updateLoader, message } = useLoadingMessage()

  const getLeaveTypes = async () => {
    updateLoader(true, 'Fetching leave types')
    setLeaveTypes((await LeavesService.list()).data)
    updateLoader(false)
  }

  useEffect(() => {
    if (!authState.user?.gender) {
      history.push(`${SidebarLinks.Employees}/${authState.user?.slug}`)
      alert.info('You need to set a gender before you can request for leave')
      return
    }

    getLeaveTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      loadingMessage={message}
    >
      <Formik
        initialValues={
          new CreateLeaveRequestModel(
            (state as any)?.key === 'create-leave-request'
              ? ((state as any)?.values as CreateLeaveRequestModel)
              : undefined,
            authState.user as Employee
          )
        }
        validate={validateWithModel(CreateLeaveRequestModel)}
        onSubmit={async (values) => {
          loaderState.setLoading('Creating leave request')
          const response = await LeaveRequestsService.create(values)
          loaderState.setDone()

          if (response.success) {
            alert.success('Leave request created successfully')
            history.push(RequestsLinks.LeaveRequests, null)
            return
          }

          alert.error(response.message)
        }}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Leave Request</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <FormTextInput
                  label="Leave Type"
                  addLink={
                    permissionsState.hasPermission(
                      Permissions.SystemConfiguration
                    )
                      ? {
                          to: SettingsLinks.CreateLeave,
                          state: {
                            key: 'create-leave-request',
                            referrer: RequestsLinks.CreateLeaveRequest,
                            field: 'leave_type',
                            values,
                          },
                        }
                      : undefined
                  }
                  as="select"
                  name="leave_type"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select leave type
                  </option>
                  {leaveTypes?.map(({ id, name }, i) => (
                    <option value={id} key={i}>
                      {name}
                    </option>
                  ))}
                </FormTextInput>
                <DatePicker
                  label="Leave Start Date"
                  name="start_date"
                  minDate={new Date()}
                  onChange={(date: string) => {
                    if (moment(values.end_date).isBefore(date, 'd')) {
                      setFieldValue('end_date', date)
                    }
                  }}
                  disabled={isSubmitting}
                />
                <DatePicker
                  label="Leave End Date"
                  name="end_date"
                  minDate={values.start_date}
                  disabled={isSubmitting}
                />
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink
                to={
                  isSubmitting
                    ? '#'
                    : { pathname: RequestsLinks.LeaveRequests, state: null }
                }
              >
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  )
}

export const CreateLeaveRequest = Page(View, 'Create Leave Request')
