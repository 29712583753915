import { IsString, MinLength } from 'class-validator'

export class CreateNamedEntity {
  @IsString({ message: 'Name is invalid' })
  @MinLength(3, { message: 'Name is too short' })
  public name!: string

  constructor() {
    this.name = ''
  }
}
