import React, { useContext } from 'react'
import { useAlert } from 'react-alert'
import ReactDOM from 'react-dom'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/auth.context'
import { PermissionsContext } from '../../context/permissions.context'
import { Permissions } from '../../util/constants'
import { FullPageLoader } from './Loaders'

interface SpecialRouteProps extends RouteProps {
  permission?: Permissions
  authFunction?: () => boolean
  isPrivate?: boolean
}

export const SpecialRoute: React.FC<SpecialRouteProps> = ({
  permission,
  authFunction,
  isPrivate,
  ...props
}) => {
  const { hasPermission } = useContext(PermissionsContext)
  const { user, isLoading, ready } = useContext(AuthContext)
  const alert = useAlert()
  const { pathname: currentLocation, ...location } = useLocation()

  if (isLoading || !ready) {
    return ReactDOM.createPortal(
      <FullPageLoader
        isLoading={isLoading}
        message="Checking authentication"
      />,
      document.getElementById('portal') as Element
    )
  }

  if (
    (authFunction && !authFunction()) ||
    (permission !== undefined && !hasPermission(permission))
  ) {
    alert.error('You are not allowed access to that page')

    return (
      <Redirect
        to={{
          pathname: (location.state as any)?.referrer || '/',
          state: location.state,
        }}
      />
    )
  }

  if (user && !isPrivate) {
    return (
      <Redirect
        to={{
          pathname: (location.state as any)?.referrer || '/',
          state: {},
        }}
      />
    )
  } else if (!user && isPrivate) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referrer: currentLocation },
        }}
      />
    )
  } else {
    return <Route {...props} />
  }
}

const AuthedRoute = (isPrivate: boolean) => (props: SpecialRouteProps) => (
  <SpecialRoute {...{ isPrivate, ...props }} />
)

export const InternalRoute = AuthedRoute(true)
export const ExternalRoute = AuthedRoute(false)
