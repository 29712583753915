import React from 'react'
import { Centralizer } from '../../component/container'
import { NamedEntity } from '../../model/response'

export enum Filters {
  All,
  Active,
  Inactive,
}

export const filters = {
  [Filters.All]: {},
  [Filters.Active]: { active: true },
  [Filters.Inactive]: { active: false },
}

export interface Filtered {
  [filter: string]: NamedEntity[]
}

export const NotCreated = (
  singularName: string,
  pluralName: string,
  createLink: string
) => (
  <Centralizer>
    <h2>No {pluralName} are available</h2>
  </Centralizer>
)
