import { History, Location } from 'history'
import queryString from 'query-string'
import React, { Suspense, useContext, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { AuthContext, AuthState } from '../context/auth.context'
import { LoaderContext, LoaderState } from '../context/loader.context'
import {
  PermissionsContext,
  PermissionsState,
} from '../context/permissions.context'

export interface PageComponentProps {
  params: { [name: string]: string }
  query: queryString.ParsedQuery
  location: Location
  history: History
  setTitle: (title: string) => void
  title: string
  authState: AuthState
  loaderState: LoaderState
  permissionsState: PermissionsState
}

interface PageProps extends React.ComponentProps<'div'> {
  component: React.ComponentType<PageComponentProps>
  title: string
}

const PageContent: React.FC<PageProps> = ({
  component: Component,
  title: pageTitle,
  ...props
}) => {
  const [title, setTitle] = useState(pageTitle)
  const [query, setQuery] = useState({})

  const location = useLocation()
  const { params } = useRouteMatch()
  const history = useHistory()

  const loaderState = useContext(LoaderContext)
  const permissionsState = useContext(PermissionsContext)
  const authState = useContext(AuthContext)

  useEffect(() => {
    setQuery(queryString.parse(location.search))
  }, [location.search])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Component
        location={location}
        query={query}
        params={params}
        history={history}
        setTitle={setTitle}
        authState={authState}
        loaderState={loaderState}
        permissionsState={permissionsState}
        title={title}
        {...props}
      />
    </>
  )
}

export const Page = (
  component: React.ComponentType<PageComponentProps>,
  title: string
): React.FC<PageComponentProps> => {
  return () => <PageContent component={component} title={title} />
}

export function LazyPage(
  PageComponent: React.FC<PageComponentProps>
): React.FC<PageComponentProps> {
  return (props) => (
    <Suspense fallback={<div>Loading...</div>}>
      <PageComponent {...props} />
    </Suspense>
  )
}
