import {
  CreateFinancialRequest,
  RejectTeamTimeline,
} from '../model/request/jobs'
import { EditNamedEntity } from '../model/request/named-entities'
import { FinancialRequest } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class FinancialRequestsService {
  public static async create(details: CreateFinancialRequest) {
    return ApiClient.post<CreateFinancialRequest, FinancialRequest>(
      'job/financial-requests',
      details,
      CreateFinancialRequest
    )
  }

  public static async list() {
    return ApiClient.get<FinancialRequest[]>('job/financial-requests/list')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<FinancialRequest>('job/financial-request', id)
  }

  public static async update(id: number | string, details: EditNamedEntity) {
    return ApiClient.patch<EditNamedEntity, FinancialRequest>(
      'job/financial-request',
      id,
      details,
      EditNamedEntity
    )
  }

  public static async approve(id: number | string) {
    return ApiClient.postOne<never, FinancialRequest>(
      'job/financial-request/approve',
      id,
      undefined,
      CreateFinancialRequest
    )
  }

  public static async reject(id: number | string, details: RejectTeamTimeline) {
    return ApiClient.postOne<RejectTeamTimeline, FinancialRequest>(
      'job/financial-request/reject',
      id,
      details,
      RejectTeamTimeline
    )
  }
}
