import { CreateClient, EditClient } from '../model/request/jobs'
import { Client } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class ClientsService {
  public static async create(details: CreateClient) {
    return ApiClient.post<CreateClient, Client>(
      'job/client',
      details,
      CreateClient
    )
  }

  public static async list() {
    return ApiClient.get<Client[]>('job/client')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<Client>('job/client', id)
  }

  public static async update(id: number | string, details: EditClient) {
    return ApiClient.patch<EditClient, Client>(
      'job/client',
      id,
      details,
      EditClient
    )
  }

  public static async delete(id: number | string) {
    return ApiClient.deleteOne('job/client', id)
  }
}
