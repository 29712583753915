import {
  IsBoolean,
  IsEnum,
  IsPhoneNumber,
  IsPositive,
  Matches,
} from 'class-validator'
import { HiddenRoleNames, RoleNames } from '../../../util/constants'
import {
  DateValidationPattern,
  EmailValidationPattern,
  Genders,
  IsNotBeforeOrAfter,
  IsNotWeekend,
  IsOptionalOrEmpty,
  NameValidationPattern,
  PhoneRegion,
  runValidation,
} from '../../../util/validation'
import { Employee } from '../../response'
import { SetAccountDetails } from '../account-details.model'
import { SetNextOfKin } from './set-next-of-kin.model'

export class AdminEditEmployee {
  @Matches(NameValidationPattern, { message: 'First name is invalid' })
  public first_name!: string

  @IsOptionalOrEmpty()
  @Matches(NameValidationPattern, { message: 'Middle name is invalid' })
  public middle_name!: string

  @Matches(NameValidationPattern, { message: 'Last name is invalid' })
  public last_name!: string

  @Matches(EmailValidationPattern, {
    message: 'Invalid ABDC email',
  })
  public email!: string

  @IsOptionalOrEmpty()
  @IsPhoneNumber(PhoneRegion, { message: 'Invalid phone number' })
  public phone_number?: string

  @IsNotBeforeOrAfter(undefined, true, {
    message: 'Date of employment cannot be in the future',
  })
  @IsNotWeekend({
    message: 'Date of employment cannot be on a weekend',
  })
  @Matches(DateValidationPattern, {
    message: 'Date of employment is invalid',
  })
  public date_of_employment!: string

  @IsPositive({ message: 'Office designation is invalid' })
  public office_id!: number

  @IsEnum({ ...RoleNames, ...HiddenRoleNames }, { message: 'Role is invalid' })
  public role!: RoleNames | HiddenRoleNames

  @IsPositive({ message: 'Job title is invalid' })
  public job_title_id!: number

  @IsOptionalOrEmpty()
  @IsNotBeforeOrAfter(undefined, true, {
    message: 'Date of birth cannot be in the future',
  })
  @Matches(DateValidationPattern, {
    message: 'Date of birth is invalid',
  })
  public date_of_birth?: string

  @IsOptionalOrEmpty()
  @IsEnum(Genders, { message: 'Gender is invalid' })
  public gender?: string

  @IsBoolean({ message: 'State is either active or inactive' })
  public active!: boolean

  public next_of_kin!: SetNextOfKin | null
  public account_details!: SetAccountDetails | null

  constructor(employee?: Employee) {
    if (employee) {
      this.first_name = employee.first_name
      this.middle_name = employee.middle_name || ''
      this.last_name = employee.last_name
      this.email = employee.email
      this.phone_number = employee.phone_number || ''
      this.date_of_employment = employee.date_of_employment
      this.office_id = employee.office.id
      this.role = employee.role
      this.job_title_id = employee.job_title.id
      this.date_of_birth = employee.date_of_birth || ''
      this.gender = employee.gender || ''
      this.next_of_kin = employee.next_of_kin || new SetNextOfKin()
      this.account_details = employee.account_details || new SetAccountDetails()
      this.active = employee.active
      return
    }

    this.first_name = ''
    this.middle_name = ''
    this.last_name = ''
    this.email = ''
    this.phone_number = ''
    this.date_of_employment = ''
    this.office_id = 0
    this.role = RoleNames.Employee
    this.job_title_id = 0
    this.date_of_birth = ''
    this.next_of_kin = new SetNextOfKin()
    this.account_details = new SetAccountDetails()
    this.active = true
  }

  public static transform(object: AdminEditEmployee) {
    object.office_id = Number.parseInt(object.office_id as any, 10)
    object.job_title_id = Number.parseInt(object.job_title_id as any, 10)
  }

  public static extraValidation(object: AdminEditEmployee) {
    const errors = []

    if (
      object.next_of_kin &&
      Object.values(object.next_of_kin).some((value) => value)
    ) {
      errors.push(
        ...runValidation(SetNextOfKin, 'next_of_kin')(object.next_of_kin)
      )
    }

    if (
      object.account_details &&
      Object.values(object.account_details).some((value) => value)
    ) {
      errors.push(
        ...runValidation(
          SetAccountDetails,
          'account_details'
        )(object.account_details)
      )
    }

    return errors
  }
}
