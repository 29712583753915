import React from 'react'
import { Employee } from '../model/response'
import { Permissions, RolePermissions } from '../util/constants'
import { AuthConsumer } from './auth.context'

export interface PermissionsState {
  hasPermission: (...permission: Permissions[]) => boolean
  userAllowed: (slug: string) => boolean
}

const defaultState: PermissionsState = {
  hasPermission: (...permissions: Permissions[]) => false,
  userAllowed: (slug: string) => false,
}

export const PermissionsContext = React.createContext<PermissionsState>(
  defaultState
)
export const PermissionsConsumer = PermissionsContext.Consumer

export const PermissionsProvider: React.FC = ({ children }) => {
  const hasPermission = (user: Employee | null) => (
    ...permissions: Permissions[]
  ) => {
    if (!user) {
      return false
    }

    return RolePermissions[user.role].some((rolePermission) =>
      permissions.some((permission) => rolePermission === permission)
    )
  }

  const userAllowed = (user: Employee | null) => (slug: string) => {
    if (!user) {
      return false
    }

    return (
      slug === user.slug || hasPermission(user)(Permissions.AdminViewAccess)
    )
  }

  return (
    <AuthConsumer>
      {({ user }) => (
        <PermissionsContext.Provider
          value={{
            hasPermission: hasPermission(user),
            userAllowed: userAllowed(user),
          }}
        >
          {children}
        </PermissionsContext.Provider>
      )}
    </AuthConsumer>
  )
}
