import { CreateRequest, EditRequest } from '../model/request/requests'
import { Employee, Request } from '../model/response'
import { ApiClient } from '../util/ApiClient'
import { RequestStates } from '../util/constants'

export class RequestsService {
  public static async create(details: CreateRequest) {
    return ApiClient.post<CreateRequest, Request>(
      'request/create',
      details,
      CreateRequest
    )
  }

  public static async list() {
    return ApiClient.get<Request[]>('request/view')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<Request>('request/show', id)
  }

  public static async update(id: number | string, details: EditRequest) {
    return ApiClient.patch<EditRequest, Request>(
      'request/update',
      id,
      details,
      EditRequest
    )
  }

  public static async respond(
    id: number | string,
    details: EditRequest,
    actor: Employee,
    approve?: boolean
  ) {
    return ApiClient.patch<EditRequest, Request>(
      'request/update',
      id,
      {
        ...details,
        status: approve ? RequestStates.Approved : RequestStates.Rejected,
        actor_id: actor.id,
      },
      EditRequest
    )
  }

  public static async delete(id: number | string) {
    return ApiClient.deleteOne<Request>('request/delete', id)
  }
}
