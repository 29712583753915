import { IsEmail, IsPhoneNumber, IsString, MinLength } from 'class-validator'
import { PhoneRegion } from '../../../util/validation'

export class CreateClient {
  @IsString({ message: 'Name is invalid' })
  @MinLength(3, { message: 'Name is too short' })
  public name!: string

  @IsEmail(undefined, { message: 'Email is invalid' })
  public email!: string

  @IsPhoneNumber(PhoneRegion, { message: 'Phone number is invalid' })
  public phone_number!: string

  @IsString({ message: 'Address is invalid' })
  @MinLength(3, { message: 'Address is too short' })
  public address!: string

  constructor() {
    this.name = ''
    this.email = ''
    this.phone_number = ''
    this.address = ''
  }
}
