export const roleKeys = [
  'PrincipalPartner',
  'ManagingPartner',
  'TaxPartner',
  'AuditPartner',
  'BusinessDevelopmentPartner',
  'HRManager',
  'Employee',
]

export enum RoleNames {
  PrincipalPartner = 'Principal Partner',
  ManagingPartner = 'Managing Partner',
  TaxPartner = 'Tax Partner',
  AuditPartner = 'Audit Partner',
  BusinessDevelopmentPartner = 'Business Development Partner',
  HRManager = 'HR Manager',
  Employee = 'Employee',
}

export enum HiddenRoleNames {
  SuperAdmin = 'Super Admin',
}

export enum RoleFieldNames {
  PrincipalPartner = 'principal_partner',
  ManagingPartner = 'managing_partner',
  TaxPartner = 'tax_partner',
  AuditPartner = 'audit_partner',
  BusinessDevelopmentPartner = 'business_development_partner',
  HRManager = 'hr_manager',
  Employee = 'employee',
}

export enum Permissions {
  AdminViewAccess,
  FinancialRequestApproval,
  JobCreation,
  ManageJobs,
  SystemConfiguration,
}

export const RolePermissions: {
  [key: string]: Permissions[]
} = {
  [RoleNames.PrincipalPartner]: [
    Permissions.AdminViewAccess,
    Permissions.FinancialRequestApproval,
    Permissions.JobCreation,
  ],
  [RoleNames.ManagingPartner]: [
    Permissions.AdminViewAccess,
    Permissions.FinancialRequestApproval,
    Permissions.JobCreation,
  ],
  [RoleNames.AuditPartner]: [
    Permissions.AdminViewAccess,
    Permissions.JobCreation,
  ],
  [RoleNames.TaxPartner]: [
    Permissions.AdminViewAccess,
    Permissions.JobCreation,
  ],
  [RoleNames.BusinessDevelopmentPartner]: [
    Permissions.AdminViewAccess,
    Permissions.JobCreation,
  ],
  [RoleNames.HRManager]: [
    Permissions.AdminViewAccess,
    Permissions.SystemConfiguration,
    Permissions.JobCreation,
  ],
  [RoleNames.Employee]: [],
  [HiddenRoleNames.SuperAdmin]: [
    Permissions.AdminViewAccess,
    Permissions.FinancialRequestApproval,
    Permissions.JobCreation,
    Permissions.SystemConfiguration,
  ],
}
