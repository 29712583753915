import moment from 'moment'
import React, { useContext } from 'react'
import { useAlert } from 'react-alert'
import { TableInstance, useTable } from 'react-table'
import styled from 'styled-components'
import { PermissionsContext } from '../../../../context/permissions.context'
import { RejectTeamTimeline } from '../../../../model/request/jobs'
import {
  ApprovalLimits,
  Employee,
  FinancialRequest,
} from '../../../../model/response'
import { Colors, Effects } from '../../../../style'
import {
  Permissions,
  RequestStates,
  RoleNames,
} from '../../../../util/constants'
import { formatCurrency } from '../../../../util/validation'

export const FinancialRequestContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const FinancialRequestBox = styled.div`
  background: white;
  box-shadow: ${Effects.DropShadow};
  border: 2px solid ${Colors.LightGrey};
  display: flex;
  height: 300px;

  > header {
    flex: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > * + * {
      margin-top: 10px;
    }

    > section {
      display: flex;
      flex-wrap: wrap;

      > small {
        width: 100%;
        margin-bottom: 10px;
      }

      > button {
        padding: 2.5px 5px;
      }

      > button + button {
        margin-left: 5px;
      }

      > * + section {
        margin-top: 25px;
      }
    }

    > section > section {
      width: 100%;
      display: flex;
      flex-direction: column;

      > * + * {
        margin-top: 10px;
      }
    }
  }

  > main {
    padding: 30px;
    border-left: 1px solid ${Colors.LightGrey};
    flex: 1;

    > * {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
    }

    > main {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid ${Colors.LightGrey};
    }

    > footer {
      font-size: 125%;

      span {
        font-weight: bold;
      }
    }

    section {
      display: grid;
      grid-template-columns: repeat(2, 135px);
      grid-gap: 10px;

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      * {
        padding: 5px;
        font-size: 80%;
      }
    }
  }

  * em {
    text-decoration: underline;
    cursor: help;

    :hover {
      color: ${Colors.Header};
    }
  }
`

interface FinancialRequestListProps {
  data: FinancialRequest[]
  limits: ApprovalLimits
  handleApproval: (
    id: number,
    approve?: boolean,
    details?: RejectTeamTimeline
  ) => void
  user: Employee | null
  openRejectModal: (id: number) => void
}

export const FinancialRequestList = ({
  data,
  limits,
  handleApproval,
  user,
  openRejectModal,
}: FinancialRequestListProps) => {
  const alert = useAlert()
  const {
    getTableProps,
    prepareRow,
    rows,
  }: TableInstance<FinancialRequest> = useTable({
    data,
    columns: [{ Header: 'id', accessor: 'id' }],
  })
  const { hasPermission } = useContext(PermissionsContext)

  return (
    <FinancialRequestContainer {...getTableProps()}>
      {rows.map((row, i) => {
        prepareRow(row)

        const {
          original: {
            id,
            job,
            status,
            actor,
            job: { client },
            transportation,
            feeding,
            accommodation,
            others,
            response,
            created_at,
          },
        } = row

        const total = transportation + feeding + accommodation + others
        const isEP = job.engagement_partner.id === user?.id
        const roleLimit = +(limits as any)[
          (user?.role as RoleNames).replace(' ', '_').toLowerCase()
        ]

        const canReject =
          !!limits &&
          (hasPermission(Permissions.FinancialRequestApproval) || isEP)

        const canApprove = canReject && roleLimit ? roleLimit >= total : true

        return (
          <FinancialRequestBox key={i}>
            <header>
              <section>
                <section>
                  <small>Client</small>
                  <h2>{client.name}</h2>
                </section>
                <section>
                  <small>Created On</small>
                  <h6>{moment(created_at).format('LL')}</h6>
                </section>
                <section>
                  <small>Status</small>
                  <h6 className={status.toLowerCase()}>{status}</h6>
                </section>
              </section>
              {status !== RequestStates.Pending ? (
                <small
                  onMouseOver={() => {
                    if (response) {
                      alert.info(response)
                    }
                  }}
                >
                  by {actor?.first_name} {actor?.last_name}
                </small>
              ) : canReject ? (
                <section>
                  {canApprove ? (
                    <button
                      style={{ color: Colors.Primary }}
                      onClick={handleApproval.bind(
                        undefined,
                        id,
                        true,
                        undefined
                      )}
                    >
                      Approve
                    </button>
                  ) : null}
                  <button
                    style={{ color: Colors.Red }}
                    onClick={openRejectModal.bind(undefined, id)}
                  >
                    Reject
                  </button>
                </section>
              ) : null}
            </header>
            <main>
              <main>
                <section>
                  <small>Transportation</small>
                  <span>{'N'+ formatCurrency(transportation)}</span>
                </section>
                <section>
                  <small>Feeding</small>
                  <span>{'N' + formatCurrency(feeding)}</span>
                </section>
                <section>
                  <small>Accommodation</small>
                  <span>{'N' + formatCurrency(accommodation)}</span>
                </section>
                <section>
                  <small>Others</small>
                  <span>{formatCurrency(others)}</span>
                </section>
              </main>
              <footer>
                <section>
                  <small>Total</small>
                  <span>{'N'+formatCurrency(total)}</span>
                </section>
              </footer>
            </main>
          </FinancialRequestBox>
        )
      })}
    </FinancialRequestContainer>
  )
}
