import { Formik } from 'formik'
import { isMatch } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'
import {
  ConfirmAlertConfig,
  generateConfirmAlert,
} from '../../../../component/alert'
import {
  CancelSlickButton,
  PrimarySlickButton,
  SlickButton,
} from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { FormTextInput, Switch } from '../../../../component/field'
import { Breadcrumb, Loader, PageBody } from '../../../../component/misc'
import { LoaderContext } from '../../../../context/loader.context'
import { EditNamedEntity } from '../../../../model/request/named-entities'
import { NamedEntity } from '../../../../model/response'
import { ApiResponse } from '../../../../util/ApiClient'
import { validateWithModel } from '../../../../util/validation'

interface SingleNamedEntityPageProps {
  breadcrumbs: Breadcrumb[]
  service: any
  singular: string
  id: string
  canDelete?: boolean
  noStatus?: boolean
}

export const SingleNamedEntityPage: React.FC<SingleNamedEntityPageProps> = ({
  breadcrumbs,
  service,
  singular,
  id,
  canDelete,
  noStatus,
}) => {
  const alert = useAlert()
  const history = useHistory()
  const { setLoading, setDone } = useContext(LoaderContext)
  const [entity, setEntity] = useState<NamedEntity>()
  const [editMode, setEditMode] = useState(false)

  const deleteEntity = async () => {
    setLoading(`Deleting ${singular}`)
    const response = await service.delete(id)
    setDone()

    if (response.success) {
      alert.success('Leave deleted successfully')
      history.replace(breadcrumbs[breadcrumbs.length - 2].path)
      return
    }

    alert.error(response.message)
  }

  const getEntities = async () => {
    setEntity((await service.retrieve(id)).data)
  }

  useEffect(() => {
    getEntities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Formik
      enableReinitialize
      validate={validateWithModel(EditNamedEntity)}
      initialValues={new EditNamedEntity(entity)}
      onSubmit={async (values) => {
        if (!isMatch(entity as NamedEntity, values)) {
          setLoading(`Updating ${singular}`)
          const response = (await service.update(id, values)) as ApiResponse<
            NamedEntity
          >
          setDone()

          if (response.success) {
            alert.success(`${singular} updated successfully`)
            setEntity({
              ...(response.data as NamedEntity),
              ...values,
            })
            setEditMode(false)
            return
          }

          alert.error(response.message)
          return
        }

        alert.info('No changes were made')
        setEditMode(false)
      }}
    >
      {({ handleSubmit, handleChange, resetForm, isSubmitting }) => (
        <Loader
          isLoading={!entity}
          message={`Fetching ${singular} information`}
        >
          <PageBody
            breadcrumbs={breadcrumbs}
            title={singular}
            subTitle={entity?.name}
            tagline={entity?.active || noStatus ? 'Active' : 'Inactive'}
            actionArea={
              editMode ? (
                <>
                  <CancelSlickButton
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => {
                      resetForm()
                      setEditMode(false)
                    }}
                  >
                    <span>Cancel</span>
                  </CancelSlickButton>
                  <PrimarySlickButton
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    <span>Save</span>
                  </PrimarySlickButton>
                </>
              ) : (
                <>
                  {canDelete ? (
                    <CancelSlickButton
                      type="button"
                      onClick={() => {
                        const config: ConfirmAlertConfig = {
                          header: `Delete ${singular}`,
                          message: `Are you sure you want to delete the ${singular}?`,
                          confirmText: 'Yes, delete it!',
                          cancelText: 'No, cancel',
                        }

                        generateConfirmAlert(config, deleteEntity)
                      }}
                    >
                      <span>Delete</span>
                    </CancelSlickButton>
                  ) : null}
                  <SlickButton
                    onClick={() => {
                      setEditMode(true)
                    }}
                  >
                    <span>Edit</span>
                  </SlickButton>
                </>
              )
            }
          >
            {
              <CreateEditForm onSubmit={handleSubmit}>
                <main>
                  {editMode && !noStatus ? (
                    <section>
                      <Switch name="active" label="Status" />
                    </section>
                  ) : null}
                  <section>
                    <FormTextInput
                      label="Name"
                      name="name"
                      placeholder="Name"
                      autoCapitalize="on"
                      spellCheck="on"
                      disabled={!editMode}
                      trim
                      handleChange={handleChange}
                    />
                  </section>
                </main>
              </CreateEditForm>
            }
          </PageBody>
        </Loader>
      )}
    </Formik>
  )
}
