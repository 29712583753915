import React from 'react'
import { ArrowDown, ArrowLeft, ArrowRight, ArrowUp } from 'react-feather'
import {
  TableInstance,
  TableOptions,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useSortBy,
  useTable,
} from 'react-table'
import styled from 'styled-components'
import { Colors, Effects } from '../../style'

const StyledTable = styled.table`
  background: #ffffff50;
  backdrop-filter: blur(10px);
  border-spacing: 0;
  border: 2px solid ${Colors.LightGrey};
  width: 100%;
  box-shadow: ${Effects.DropShadow};

  th:last-child,
  th:first-child {
    width: 80px;
  }

  tr {
    :last-child td {
      border-bottom: 0;
    }
  }

  th {
    color: ${Colors.Primary};

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > svg {
        height: 15px;
        width: 15px;
        margin-left: 5px;
        flex-shrink: 0;
      }
    }
  }

  td {
    font-size: 90%;
  }

  th,
  td {
    text-align: left;
    margin: 0;
    padding: 15px 20px;
    border-bottom: 1px solid ${Colors.LightGrey};
    border-right: 1px solid ${Colors.LightGrey};

    :last-child {
      border-right: 0;
    }
  }

  td > * + * {
    margin-left: 10px;
  }

  td > a {
    font-weight: normal;

    :hover {
      text-decoration: underline;
    }
  }

  td > button,
  td > a.button {
    background: transparent;
    border: 0;
    font-weight: bold;
    font-size: inherit;
    background: ${Colors.Primary};
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 7.5px 10px;
    width: 100%;
    font-size: 80%;
    outline: none;
    transition: all 0.5s ease;

    :focus,
    :hover {
      background: ${Colors.PrimaryDark};
    }
  }
`

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > section {
    display: flex;

    button:disabled {
      background: ${Colors.LightGrey};
      border-color: ${Colors.LightGrey};
      color: ${Colors.CancelButtonText};
    }

    button {
      background: ${Colors.Primary};
      border-color: ${Colors.Primary};
      color: white;
      height: 30px;
      min-width: 30px;
      padding: 5px 7.5px;

      > * + * {
        margin-left: 10px;
      }
    }

    button + button {
      margin-left: 10px;
    }
  }
`

const PAGE_SIZE = 5

interface PaginationInstance extends UsePaginationInstanceProps<any> {
  state: UsePaginationState<any>
}

export const Table = ({ columns, data, ...props }: TableOptions<any>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    rows,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  }: TableInstance<any> & PaginationInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: PAGE_SIZE },
      ...props,
    },
    useSortBy,
    usePagination
  )

  return (
    <>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div>
                    <span>{column.render('Header')}</span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDown />
                      ) : (
                        <ArrowUp />
                      )
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, j) => {
                  return (
                    <td {...cell.getCellProps()} key={j}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </StyledTable>
      <ButtonArea>
        <span>
          Showing {pageIndex * PAGE_SIZE + 1} to{' '}
          {pageIndex * PAGE_SIZE + page.length} of {rows.length}{' '}
          {rows.length !== 1 ? 'entries' : 'entry'}
        </span>
        <section>
          {canPreviousPage ? (
            <button onClick={() => previousPage()}>
              <ArrowLeft width={15} height={15} />
              <span>Previous</span>
            </button>
          ) : null}
          {pageOptions.map(index => (
            <button
              onClick={() => {
                gotoPage(index)
              }}
              disabled={index === pageIndex}
              key={index}
            >
              {index + 1}
            </button>
          ))}
          {canNextPage ? (
            <button onClick={() => nextPage()}>
              <span>Next</span>
              <ArrowRight width={15} height={15} />
            </button>
          ) : null}
        </section>
      </ButtonArea>
    </>
  )
}
