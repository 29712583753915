import { Field, useField } from 'formik'
import React, { useMemo } from 'react'
import { CheckSquare, Square } from 'react-feather'
import styled from 'styled-components'
import { Colors, Effects } from '../../style'

const Container = styled.label`
  display: flex;
  flex-direction: column;
  background: white;
  border: 2px solid ${Colors.LightGrey};
  width: 100%;
  box-shadow: ${Effects.DropShadow};
  position: relative;
  font-size: 80%;

  .image {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  > main {
    display: flex;
    padding: 0 20px 20px;
    flex: 1;

    > * {
      display: flex;
      flex-direction: column;

      > * + * {
        margin-top: 5px;
      }
    }

    > * + * {
      margin-left: 20px;
    }
  }

  &.display > main {
    padding: 10px;
    font-size: 120%;
    align-items: center;

    .image {
      height: 40px;
      width: 40px;
    }
  }

  &.selected {
    border-color: ${Colors.Primary};
  }

  &.selected > header {
    color: ${Colors.Primary};
    /* color: white; */
  }

  > header {
    padding: 20px;
    display: flex;
    font-weight: bold;
    align-items: center;

    svg {
      height: 15px;
      width: 15px;
    }

    > * + * {
      margin-left: 10px;
    }
  }

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: none;
  }
`

interface CheckableBoxProps {
  name: string
  value: string | number
  selected?: boolean
  display?: boolean
}

export const CheckableBox: React.FC<CheckableBoxProps> = ({
  name,
  value,
  children,
  display,
  ...props
}) => {
  const [field] = useField({ name, value })
  const selected =
    props.selected ||
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMemo(
      () => field.value.includes(value) || field.value.includes(`${value}`),
      [field.value, value]
    )

  return (
    <Container className={display ? 'display' : selected ? 'selected' : ''}>
      {display ? null : (
        <header>
          {selected ? (
            <>
              <CheckSquare />
              <span>Selected</span>
            </>
          ) : (
            <>
              <Square />
              <span>Not selected</span>
            </>
          )}
        </header>
      )}
      <main>{children}</main>
      {!props.selected || display ? (
        <Field type="checkbox" {...field} value={value} checked={selected} />
      ) : null}
    </Container>
  )
}
