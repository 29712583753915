import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { AspectRatio } from 'react-aspect-ratio'
import { FilePond, registerPlugin } from 'react-filepond'
import { ApiClient } from '../../../util/ApiClient'

const acceptedMimeTypes = ['image/jpeg', 'image/png']

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
export const ProfilePicturePond: React.FC<{ slug: string }> = ({ slug }) => {
  const alert = useAlert()
  const [background, setBackground] = useState(``)

  useEffect(() => {
    setBackground(
      `${process.env.REACT_APP_STORAGE}/profile/${slug}?${Date.now()}`
    )
  }, [slug])

  return (
    <AspectRatio
      ratio="1/1"
      style={{
        backgroundImage: `url(${background}), url(/svg/portrait.svg)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: 300,
      }}
    >
      <FilePond
        allowMultiple={false}
        name="profile_picture"
        instantUpload={false}
        allowRevert={false}
        labelIdle=""
        onerror={(error, file) => {
          alert.error(error.main)
          file?.abortLoad()
        }}
        onaddfile={(error, file) => {
          if (
            !acceptedMimeTypes.find((mimeType) => mimeType === file.fileType)
          ) {
            alert.error('You can only upload JPEG and PNG formats')
            file.abortLoad()
            return
          }

          if (file.fileSize > 3 * 1000 * 1024) {
            alert.error('File is too large')
            file.abortLoad()
          }
        }}
        onprocessfile={(error, file) => {
          file.abortLoad()
        }}
        server={
          {
            process: async (
              _: string,
              file: File,
              metadata: any,
              load: any,
              error: any,
              progress: any,
              abort: any
            ) => {
              const data = new FormData()
              data.append('profile_picture', file)
              const request = new XMLHttpRequest()

              request.onload = async (e) => {
                if (request.status >= 200 && request.status < 300) {
                  load(request.responseText)
                  alert.success('Image updated successfully')

                  setBackground(
                    `${
                      process.env.REACT_APP_STORAGE
                    }/profile/${slug}?${Date.now()}`
                  )
                } else {
                  console.error(request)
                }
              }

              request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total)
              }

              request.open(
                'POST',
                `${process.env.REACT_APP_BASE_URL}/employee/account/update-profile-pic/${slug}`
              )

              request.setRequestHeader(
                'authorization',
                ApiClient.getBearerToken()
              )

              request.send(data)

              return {
                abort: () => {
                  if (!request.DONE) {
                    request.abort()
                  }

                  abort()
                },
              }
            },
          } as any
        }
      ></FilePond>
    </AspectRatio>
  )
}
