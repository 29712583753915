/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik'
import { reverse } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import ReactModal from 'react-modal'
import { generateConfirmAlert } from '../../../../component/alert'
import { PrimarySlickButton } from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { FormTextInput } from '../../../../component/field'
import { PageBody } from '../../../../component/misc'
import { RejectTeamTimeline } from '../../../../model/request/jobs'
import { ApprovalLimits, FinancialRequest } from '../../../../model/response'
import {
  ApprovalLimitsService,
  FinancialRequestsService,
} from '../../../../service'
import { Colors } from '../../../../style'
import { Permissions, RequestStates } from '../../../../util/constants'
import { useLoadingMessage } from '../../../../util/hook'
import { formatCurrency, validateWithModel } from '../../../../util/validation'
import { Page, PageComponentProps } from '../../../Page'
import { NotCreated } from '../../common'
import { SidebarLinks } from '../../links'
import { JobsLinks, links } from '../links'
import {
  FinancialRequestBox,
  FinancialRequestContainer,
} from './FinancialRequestList'

ReactModal.setAppElement('#root')

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Jobs, name: 'Jobs' },
  {
    path: '',
    name: 'Financial Requests',
  },
]

const View: React.FC<PageComponentProps> = ({
  authState: { user },
  permissionsState: { hasPermission },
  loaderState: { setLoading, setDone },
}) => {
  const alert = useAlert()
  const { message, isLoading, updateLoader } = useLoadingMessage()
  const [requests, setRequests] = useState<FinancialRequest[]>([])
  const [limits, setLimits] = useState<ApprovalLimits>()
  const [activeRequestId, setActiveRequestId] = useState<number>()
  const [rejectModalOpen, setRejectModalOpen] = useState(false)

  const updateRequest = (request: FinancialRequest) => {
    const index = requests.findIndex(({ id }) => request.id === id)
    const newRequests = [
      ...requests.slice(0, index),
      request,
      ...requests.slice(index + 1),
    ]

    setRequests(newRequests)
  }

  const openRejectModal = (id: number) => {
    setActiveRequestId(id)
    setRejectModalOpen(true)
  }

  const handleApproval = async (
    id: number,
    approve?: boolean,
    details?: RejectTeamTimeline
  ) => {
    const approveRequest = async () => {
      setLoading(`${approve ? 'Approv' : 'Reject'}ing financial request`)
      const response = await (approve
        ? FinancialRequestsService.approve(id)
        : FinancialRequestsService.reject(id, details as RejectTeamTimeline))
      setDone()

      if (response.data) {
        alert.success(response.message)
        setActiveRequestId(undefined)
        setRejectModalOpen(false)
        updateRequest(response.data)
        return
      }

      alert.error(response.message)
    }

    generateConfirmAlert(
      {
        header: `${approve ? 'Approve' : 'Reject'} request`,
        message: `Are you sure you want to ${
          approve ? 'approve' : 'reject'
        } this request?`,
        confirmText: `Yes, ${approve ? 'approve' : 'reject'}!`,
        cancelText: 'No, cancel',
      },
      approveRequest
    )
  }

  const getLimits = async () => {
    updateLoader(true, 'Fetching approval limits')
    const response = await ApprovalLimitsService.list()
    updateLoader(false)

    if (response.data) {
      setLimits(response.data)
    }
  }

  const getRequests = async () => {
    updateLoader(true, 'Fetching financial requests')
    const response = await FinancialRequestsService.list()
    updateLoader(false)

    const isAdmin = hasPermission(Permissions.AdminViewAccess)

    if (response.data) {
      setRequests(
        reverse(
          response.data.filter(
            (request) =>
              isAdmin ||
              user?.id === request.job.engagement_manager.id ||
              user?.id === request.job.engagement_partner.id
          )
        )
      )
    }
  }

  useEffect(() => {
    // tslint:disable-next-line: whitespace
    ;(async () => {
      await getLimits()
      await getRequests()
    })()
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Financial Requests"
      pageLinks={links}
      tagline="View / manage the financial requests on the platform"
      isLoading={isLoading}
      loadingMessage={message}
    >
      {requests.length ? (
        <>
          <FinancialRequestContainer>
            {requests.map(
              ({
                id,
                job,
                status,
                actor,
                job: { client },
                transportation,
                feeding,
                accommodation,
                others,
                response,
                created_at,
              }) => {
                const total = transportation + feeding + accommodation + others
                const isEP = job.engagement_partner.id === user?.id
                const roleLimit =
                  +(limits as any)[
                    (user?.role as string).replace(' ', '_').toLowerCase()
                  ] || isEP
                    ? limits?.engagement_partner
                    : false

                const canReject =
                  !!limits &&
                  (hasPermission(Permissions.FinancialRequestApproval) || isEP)

                const canApprove =
                  canReject && roleLimit ? roleLimit >= total : true

                return (
                  <FinancialRequestBox key={id}>
                    <header>
                      <section>
                        <section>
                          <small>Client</small>
                          <h2>{client.name}</h2>
                        </section>
                        <section>
                          <small>Created On</small>
                          <h6>{moment(created_at).format('LL')}</h6>
                        </section>
                        <section>
                          <small>Status</small>
                          <h6 className={status.toLowerCase()}>{status}</h6>
                        </section>
                      </section>
                      {status !== RequestStates.Pending ? (
                        <small>
                          by {actor?.first_name} {actor?.last_name}
                          {status === RequestStates.Rejected ? (
                            <span>
                              .{' '}
                              <em
                                title={response}
                                onClick={() => {
                                  if (response) {
                                    alert.info(response)
                                  }
                                }}
                              >
                                Why?
                              </em>
                            </span>
                          ) : null}
                        </small>
                      ) : canReject ? (
                        <section>
                          {canApprove ? (
                            <button
                              style={{ color: Colors.Primary }}
                              onClick={handleApproval.bind(
                                undefined,
                                id,
                                true,
                                undefined
                              )}
                            >
                              Approve
                            </button>
                          ) : null}
                          <button
                            style={{ color: Colors.Red }}
                            onClick={openRejectModal.bind(undefined, id)}
                          >
                            Reject
                          </button>
                        </section>
                      ) : null}
                    </header>
                    <main>
                      <main>
                        <section>
                          <small>Transportation</small>
                          <span>{'N'+formatCurrency(transportation)}</span>
                        </section>
                        <section>
                          <small>Feeding</small>
                          <span>{'N'+ formatCurrency(feeding)}</span>
                        </section>
                        <section>
                          <small>Accommodation</small>
                          <span>{'N' + formatCurrency(accommodation)}</span>
                        </section>
                        <section>
                          <small>Others</small>
                          <span>{'N' + formatCurrency(others)}</span>
                        </section>
                      </main>
                      <footer>
                        <section>
                          <small>Total</small>
                          <span>{'N' + formatCurrency(total)}</span>
                        </section>
                      </footer>
                    </main>
                  </FinancialRequestBox>
                )
              }
            )}
          </FinancialRequestContainer>
          {rejectModalOpen ? (
            <ReactModal
              overlayClassName="modal__overlay"
              className="modal"
              isOpen={true}
              onRequestClose={setRejectModalOpen.bind(undefined, false)}
            >
              <Formik
                initialValues={new RejectTeamTimeline()}
                validate={validateWithModel(RejectTeamTimeline)}
                onSubmit={handleApproval.bind(
                  undefined,
                  activeRequestId as number,
                  false
                )}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <CreateEditForm onSubmit={handleSubmit}>
                    <header>
                      <h2>Reject Financial Request</h2>
                      <h6>Enter reason for rejection</h6>
                    </header>
                    <main>
                      <section>
                        <FormTextInput
                          label="Reason For Rejection"
                          name="response"
                          placeholder="State your reason for rejecting this financial request"
                          autoCapitalize="on"
                          as="textarea"
                          disabled={isSubmitting}
                        />
                      </section>
                    </main>
                    <footer>
                      <PrimarySlickButton type="submit">
                        <span>Reject</span>
                      </PrimarySlickButton>
                    </footer>
                  </CreateEditForm>
                )}
              </Formik>
            </ReactModal>
          ) : null}
        </>
      ) : (
        NotCreated(
          'financial request',
          'financial requests',
          JobsLinks.CreateFinancialRequest
        )
      )}
    </PageBody>
  )
}

export const FinancialRequests = Page(View, 'Finacial Requests')
