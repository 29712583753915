import {
  CreateNamedEntity,
  EditNamedEntity,
} from '../model/request/named-entities'
import { NamedEntity } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class AssetCategoriesService {
  public static async create(details: CreateNamedEntity) {
    return ApiClient.post<CreateNamedEntity, NamedEntity>(
      'asset/create-asset-category',
      details,
      CreateNamedEntity
    )
  }

  public static async list() {
    return ApiClient.get<NamedEntity[]>('asset/view-asset-category')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<NamedEntity>('asset/show/asset-category', id)
  }

  public static async update(id: number | string, details: EditNamedEntity) {
    return ApiClient.patch<EditNamedEntity, NamedEntity>(
      'asset/update/asset-category',
      id,
      details,
      EditNamedEntity
    )
  }

  public static async delete(id: number | string) {
    return ApiClient.deleteOne('asset/delete/asset-category', id)
  }
}
