import { Field, Formik } from 'formik'
import { filter, includes, matches } from 'lodash'
import { isMatch } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { MoreHorizontal } from 'react-feather'
import ReactModal from 'react-modal'
import { Row } from 'react-table'
import { generateConfirmAlert } from '../../../component/alert'
import { PrimarySlickButton, SlickLink } from '../../../component/button'
import { CreateEditForm } from '../../../component/container'
import { DatePicker, FormTextInput, Input } from '../../../component/field'
import { Dropdown, FilterBar, PageBody, Table } from '../../../component/misc'
import {
  EditLeaveRequest,
  RejectLeaveRequest,
} from '../../../model/request/requests'
import { Leave, LeaveRequest } from '../../../model/response'
import { LeaveRequestsService, LeavesService } from '../../../service'
import { Permissions, RequestStates } from '../../../util/constants'
import { useLoadingMessage } from '../../../util/hook'
import { validateWithModel } from '../../../util/validation'
import { Page, PageComponentProps } from '../../Page'
import { NotCreated } from '../common'
import { SidebarLinks } from '../links'
import { RequestsLinks, requestsLinks } from './links'

ReactModal.setAppElement('#root')

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Requests, name: 'Requests' },
  { path: RequestsLinks.LeaveRequests, name: 'Leave Requests' },
]

export enum Filters {
  All,
  Pending,
  Approved,
  Rejected,
}

const filters = {
  [Filters.All]: {},
  [Filters.Pending]: { status: RequestStates.Pending },
  [Filters.Approved]: { status: RequestStates.Approved },
  [Filters.Rejected]: { status: RequestStates.Rejected },
}

const View: React.FC<PageComponentProps> = ({
  authState,
  permissionsState,
  loaderState,
}) => {
  const alert = useAlert()
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [activeRequest, setActiveRequest] = useState<LeaveRequest>()
  const [requests, setRequests] = useState<{
    [filter: string]: LeaveRequest[]
  }>({})
  const { message, isLoading, updateLoader } = useLoadingMessage()

  const [leaveTypes, setLeaveTypes] = useState<Leave[]>()

  const getLeaveTypes = async () => {
    updateLoader(true, 'Fetching leave types')
    setLeaveTypes((await LeavesService.list()).data)
    updateLoader(false)
  }

  const handleAcceptRequest = (id: number) => {
    const acceptRequest = async () => {
      loaderState.setLoading('Approving leave request')
      const response = await LeaveRequestsService.accept(id)
      loaderState.setDone()

      if (response.success) {
        alert.success(response.message)
        await getRequests()
        return
      }

      alert.error(response.message)
    }

    generateConfirmAlert(
      {
        header: `Accept request`,
        message: `Are you sure you want to approve this request?`,
        confirmText: `Yes, approve`,
        cancelText: 'No, cancel',
      },
      acceptRequest
    )
  }

  const handleDeleteRequest = (id: number) => {
    const deleteRequest = async () => {
      loaderState.setLoading('Deleting leave request')
      const response = await LeaveRequestsService.delete(id)
      loaderState.setDone()

      if (response.success) {
        alert.success(response.message)
        await getRequests()
        return
      }

      alert.error(response.message)
    }

    generateConfirmAlert(
      {
        header: `Delete request`,
        message: `Are you sure you want to delete this request?`,
        confirmText: `Yes, delete!`,
        cancelText: 'No, cancel',
      },
      deleteRequest
    )
  }

  const handleRejectRequest = (request: LeaveRequest) => {
    setActiveRequest(request)
    setRejectModalOpen(true)
  }

  const handleEditRequest = (request: LeaveRequest) => {
    setActiveRequest(request)
    setEditModalOpen(true)
  }

  const getRequests = async () => {
    updateLoader(true, 'Fetching leave requests')
    const retrieved = (await LeaveRequestsService.list()).data
    const filtered: { [filter: string]: LeaveRequest[] } = {}

    Object.entries(filters).forEach(([filterName, constraint]) => {
      filtered[filterName] = filter(retrieved, matches(constraint))
    })

    setRequests(filtered)
    updateLoader(false)
  }

  useEffect(() => {
    // tslint:disable-next-line: whitespace
    ; (async () => {
      await getLeaveTypes()
      await getRequests()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Leave Requests"
      pageLinks={requestsLinks}
      isLoading={isLoading}
      loadingMessage={message}
      tagline="View / manage the leave requests on the platform"
      actionArea={
        <SlickLink to={RequestsLinks.CreateLeaveRequest}>
          <span>Create Leave Request</span>
        </SlickLink>
      }
    >
      {requests[Filters.All]?.length ? (
        <>
          <Formik
            initialValues={{ filter: Filters.All, name: '' }}
            onSubmit={() => { }}
          >
            {({ values }) => {
              const list = requests[values.filter].filter(
                ({ leave_type, status }) => {
                  const name = leave_type.name + status
                  return values.name.length >= 3
                    ? includes(name.toLowerCase(), values.name.toLowerCase())
                    : true
                }
              )

              return (
                <>
                  <FilterBar>
                    <section className="dropdown">
                      <Field as="select" name="filter">
                        <option value={Filters.All}>All</option>
                        <option value={Filters.Pending}>Pending</option>
                        <option value={Filters.Approved}>Approved</option>
                        <option value={Filters.Rejected}>Rejected</option>
                      </Field>
                    </section>
                    <Field
                      as={Input}
                      name="name"
                      placeholder="Filter by name..."
                    />
                  </FilterBar>
                  <Table
                    columns={[
                      { Header: 'No.', id: 'id', accessor: (d, i) => i + 1 },
                      { Header: 'Leave Type', accessor: 'leave_type.name' },
                      {
                        Header: 'Employee Name',
                        accessor: ({ leave_request_creator }: LeaveRequest) =>
                          `${leave_request_creator.first_name} ${leave_request_creator.last_name}`,
                        sortType: (
                          {
                            original: { leave_request_creator: a },
                          }: Row<LeaveRequest>,
                          {
                            original: { leave_request_creator: b },
                          }: Row<LeaveRequest>
                        ) =>
                          `${a.first_name} ${a.last_name}`.toLowerCase() >
                            `${b.first_name} ${b.last_name}`.toLowerCase()
                            ? 1
                            : -1,
                      },
                      {
                        Header: 'Date Requested',
                        accessor: ({ requested_on }: LeaveRequest) =>
                          moment(requested_on).format('LL'),
                        sortType: (
                          a: Row<LeaveRequest>,
                          b: Row<LeaveRequest>
                        ) =>
                          moment(a.original.requested_on).diff(
                            b.original.requested_on,
                            'd'
                          ),
                      },
                      {
                        Header: 'Start Date',
                        accessor: ({ start_date }: LeaveRequest) =>
                          moment(start_date).format('LL'),
                        sortType: (
                          a: Row<LeaveRequest>,
                          b: Row<LeaveRequest>
                        ) =>
                          moment(a.original.start_date).diff(
                            b.original.start_date,
                            'd'
                          ),
                      },
                      {
                        Header: 'End Date',
                        accessor: ({ end_date }: LeaveRequest) =>
                          moment(end_date).format('LL'),
                        sortType: (
                          a: Row<LeaveRequest>,
                          b: Row<LeaveRequest>
                        ) =>
                          moment(a.original.end_date).diff(
                            b.original.end_date,
                            'd'
                          ),
                      },
                      { Header: 'Leave Days', accessor: 'duration' },
                      { Header: 'Status', accessor: 'status' },
                      {
                        Header: ' ',
                        disableSortBy: true,
                        accessor: (request: LeaveRequest) =>
                          request.status === RequestStates.Pending &&
                            permissionsState.userAllowed(
                              request.leave_request_creator.slug
                            ) ? (
                              <Dropdown.Menu right component={MoreHorizontal}>
                                {authState.user?.slug ===
                                  request.leave_request_creator.slug ? (
                                    <>
                                      <Dropdown.Item
                                        onClick={() => {
                                          handleEditRequest(request)
                                        }}
                                      >
                                        Edit
                                  </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          handleDeleteRequest(request.id)
                                        }}
                                      >
                                        Delete
                                  </Dropdown.Item>
                                    </>
                                  ) : null}

                                {permissionsState.hasPermission(
                                  Permissions.AdminViewAccess
                                ) &&
                                  authState.user?.slug !==
                                  request.leave_request_creator.slug ? (
                                    <>
                                      <Dropdown.Item
                                        onClick={() => {
                                          handleAcceptRequest(request.id)
                                        }}
                                      >
                                        Approve
                                  </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => {
                                          handleRejectRequest(request)
                                        }}
                                      >
                                        Reject
                                  </Dropdown.Item>
                                    </>
                                  ) : null}
                              </Dropdown.Menu>
                            ) : null,
                      },
                    ]}
                    data={list}
                  />
                </>
              )
            }}
          </Formik>
          {editModalOpen ? (
            <ReactModal
              overlayClassName="modal__overlay"
              className="modal"
              isOpen={true}
              onRequestClose={setEditModalOpen.bind(undefined, false)}
            >
              <Formik
                enableReinitialize
                initialValues={new EditLeaveRequest(activeRequest)}
                validate={validateWithModel(EditLeaveRequest)}
                onSubmit={async (original) => {
                  if (isMatch(new EditLeaveRequest(activeRequest), original)) {
                    alert.info('No changes were made')
                    setEditModalOpen(false)
                    return
                  }

                  const rejectAccept = async () => {
                    loaderState.setLoading('Updating leave request')
                    const response = await LeaveRequestsService.update(
                      activeRequest?.id as number,
                      original
                    )
                    loaderState.setDone()

                    if (response.success) {
                      alert.success(response.message)
                      setActiveRequest(undefined)
                      setEditModalOpen(false)
                      await getRequests()
                      return
                    }

                    alert.error(response.message)
                    return
                  }

                  generateConfirmAlert(
                    {
                      header: `Edit leave request`,
                      message: `Are you sure you want to save changes to this request?`,
                      confirmText: `Yes, save`,
                      cancelText: 'No, cancel',
                    },
                    rejectAccept
                  )
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <CreateEditForm onSubmit={handleSubmit}>
                    <header>
                      <h2>Edit Request</h2>
                      <h6>Fill in the required details below</h6>
                    </header>
                    <main>
                      <section>
                        <FormTextInput
                          label="Leave Type"
                          as="select"
                          name="leave_type"
                          disabled={isSubmitting}
                        >
                          <option value={0} disabled>
                            Select leave type
                          </option>
                          {leaveTypes?.map(({ id, name }, i) => (
                            <option value={id} key={i}>
                              {name}
                            </option>
                          ))}
                        </FormTextInput>
                        <DatePicker
                          label="Leave Start Date"
                          name="start_date"
                          disabled={isSubmitting}
                        />
                        <DatePicker
                          label="Leave End Date"
                          name="end_date"
                          disabled={isSubmitting}
                        />
                      </section>
                    </main>
                    <footer>
                      <PrimarySlickButton type="submit">
                        <span>Save</span>
                      </PrimarySlickButton>
                    </footer>
                  </CreateEditForm>
                )}
              </Formik>
            </ReactModal>
          ) : null}
          {rejectModalOpen ? (
            <ReactModal
              overlayClassName="modal__overlay"
              className="modal"
              isOpen={true}
              onRequestClose={setRejectModalOpen.bind(undefined, false)}
            >
              <Formik
                initialValues={new RejectLeaveRequest()}
                validate={validateWithModel(RejectLeaveRequest)}
                onSubmit={async (original) => {
                  const rejectAccept = async () => {
                    loaderState.setLoading('Rejecting leave request')
                    const response = await LeaveRequestsService.reject(
                      activeRequest?.id as number,
                      original
                    )
                    loaderState.setDone()

                    setActiveRequest(undefined)

                    if (response.success) {
                      alert.success(response.message)
                      await getRequests()
                      setRejectModalOpen(false)
                      return
                    }

                    alert.error(response.message)
                  }

                  generateConfirmAlert(
                    {
                      header: `Reject request`,
                      message: `Are you sure you want to reject this request?`,
                      confirmText: `Yes, reject`,
                      cancelText: 'No, cancel',
                    },
                    rejectAccept
                  )
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <CreateEditForm onSubmit={handleSubmit}>
                    <header>
                      <h2>Reject Request</h2>
                      <h6>Enter reason for rejection</h6>
                    </header>
                    <main>
                      <section>
                        <FormTextInput
                          label="Reason For Rejection"
                          name="reason_for_rejection"
                          placeholder="State your reason for rejecting this request"
                          autoCapitalize="on"
                          as="textarea"
                          disabled={isSubmitting}
                        />
                      </section>
                    </main>
                    <footer>
                      <PrimarySlickButton type="submit">
                        <span>Reject</span>
                      </PrimarySlickButton>
                    </footer>
                  </CreateEditForm>
                )}
              </Formik>
            </ReactModal>
          ) : null}
        </>
      ) : (
          NotCreated(
            'leave request',
            'leave requests',
            RequestsLinks.CreateLeaveRequest
          )
        )}
    </PageBody>
  )
}

export const LeaveRequests = Page(View, 'Leave Requests')
