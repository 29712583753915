import {
  Briefcase,
  Calendar,
  Folder,
  Image,
  Layers,
  Monitor,
  Settings,
  Tag,
  User,
  UserPlus,
  Users,
} from 'react-feather'
import { Employee } from '../../../model/response'
import { Permissions } from '../../../util/constants'
import { EmployeesLinks } from '../employees/links'
import { JobsLinks } from '../jobs/links'
import { SidebarLinks } from '../links'
import { OrganizationLinks } from '../organization/links'
import { RequestsLinks } from '../requests/links'
import { SettingsLinks } from '../settings/links'

interface Action {
  icon: any
  name: string
  path: string
  description: string
  permission?: Permissions
}

interface Section {
  name: string
  permission?: Permissions
  actions: Action[]
}

export const sections: (user: Employee) => Section[] = (user: Employee) => [
  {
    name: 'Settings',
    permission: Permissions.SystemConfiguration,
    actions: [
      {
        icon: Settings,
        path: `${SettingsLinks.Leaves}`,
        name: 'System Set-Up',
        description: `Create and manage leave types, disciplinary case types, and other
              important building blocks for effective use of the application`,
      },
    ],
  },
  {
    name: 'Personal',
    actions: [
      {
        icon: User,
        path: `${SidebarLinks.Employees}/${user.slug}`,
        name: 'User Profile',
        description: `Update your profile information. It is important for you to
              keep your profile information up to date`,
      },
      {
        icon: Image,
        path: `${RequestsLinks.LeaveRequests}`,
        name: 'Leave Request',
        description: `Make a request for leave. Select from the options available to you`,
      },
      {
        icon: Tag,
        path: `${SidebarLinks.Requests}`,
        name: 'Employee Request',
        description: `Is there something you need to increase productivity personally or in
            the office? Make a request`,
      },
    ],
  },
  {
    name: 'Manage Employees',
    permission: Permissions.AdminViewAccess,
    actions: [
      {
        icon: Users,
        path: `${SidebarLinks.Employees}`,
        name: 'Employees',
        description: `Register employees and manage their information (offices, job titles, roles)`,
      },
      {
        icon: User,
        path: `${EmployeesLinks.Freelancers}`,
        name: 'Freelancers',
        description: `Create and track freelancers for jobs on the platform`,
      },
      {
        icon: Folder,
        path: `${EmployeesLinks.DisciplinaryCases}`,
        name: 'Disciplinary Cases',
        description: `Assign disciplinary cases and their associated penalties to employees`,
      },
    ],
  },
  {
    name: 'Organization Structure / Assets',
    permission: Permissions.AdminViewAccess,
    actions: [
      {
        icon: Layers,
        path: `${SidebarLinks.Organization}`,
        name: 'Update Structure',
        description: `Upload a graphic that details the hierarchy and spread of the office positions
            and roles`,
      },
      {
        icon: Monitor,
        path: `${OrganizationLinks.Assets}`,
        name: 'Assets',
        description: `Manage and assign office assets to employees. Manage asset categories`,
      },
    ],
  },
  {
    name: 'Events',
    actions: [
      {
        icon: Calendar,
        path: `${SidebarLinks.Events}`,
        name: 'Holidays / Events',
        description: `Check the calendar. See which holidays and events are available`,
      },
    ],
  },
  {
    name: 'Job Creation / Management',
    permission: Permissions.JobCreation,
    actions: [
      {
        icon: UserPlus,
        path: `${JobsLinks.Clients}`,
        name: 'Clients',
        description: `Create clients and manage their information on the platform
              to track important jobs and projects`,
      },
      {
        icon: Briefcase,
        path: `${SidebarLinks.Jobs}`,
        name: 'Jobs',
        description: `Create and track jobs with clients as they progress through the
              different stages`,
      },
    ],
  },
]
