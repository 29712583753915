import { IsPositive, IsString, Matches, MinLength } from 'class-validator'
import {
  DateValidationPattern,
  getFormattedDate,
  IsNotBeforeOrAfter,
  IsNotWeekend,
} from '../../../util/validation'
import { Event } from '../../response'

export class EditEvent {
  @IsString({ message: 'Name is invalid' })
  @MinLength(3, { message: 'Name is too short' })
  public name!: string

  @IsNotWeekend({
    message: 'Start date cannot be on a weekend',
  })
  @Matches(DateValidationPattern, {
    message: 'Start date is invalid',
  })
  public start_date!: string

  @IsNotBeforeOrAfter('start_date', false, {
    message: 'End date cannot be before the start date',
  })
  @IsNotWeekend({
    message: 'End date cannot be on a weekend',
  })
  @Matches(DateValidationPattern, {
    message: 'End date is invalid',
  })
  public end_date!: string

  @IsPositive({ message: 'Event category is invalid' })
  public holiday_category_id!: number

  constructor(object?: Event) {
    this.name = object?.name || ''
    this.start_date = object?.start_date || getFormattedDate()
    this.end_date = object?.end_date || getFormattedDate()
    this.holiday_category_id = object?.category.id || 0
  }

  public static transform(object: EditEvent) {
    object.holiday_category_id = +object.holiday_category_id
  }
}
