import { Field, Formik } from 'formik'
import Fuse, { IFuseOptions } from 'fuse.js'
import { filter, matches } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row } from 'react-table'
import { ListViewButton, SlickLink } from '../../../component/button'
import { Box, Container } from '../../../component/container'
import { Input } from '../../../component/field'
import { FilterBar, Loader, PageBody, Table } from '../../../component/misc'
import { Employee } from '../../../model/response'
import { EmployeesService } from '../../../service'
import { Permissions } from '../../../util/constants'
import { useLoadingMessage } from '../../../util/hook'
import { Page, PageComponentProps } from '../../Page'
import { NotCreated } from '../common'
import { SidebarLinks } from '../links'
import { EmployeesLinks, employeesLinks as links } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  {
    path: '',
    name: 'Employees',
  },
]

export enum Filters {
  All,
  Active,
  Inactive,
  OnLeave,
}

export const filters = {
  [Filters.All]: {},
  [Filters.Active]: { active: true },
  [Filters.Inactive]: { active: false },
  [Filters.OnLeave]: { onLeave: true },
}

const View: React.FC<PageComponentProps> = ({
  permissionsState,
  authState,
  query,
}) => {
  const { isLoading, message, updateLoader } = useLoadingMessage()
  const [employees, setEmployees] = useState<{
    [filter: string]: Employee[]
  }>({})

  const isListMode = useMemo(() => query.view === 'list', [query.view])
  const getEmployees = async () => {
    updateLoader(true, 'Fetching employees')
    const retrieved = (await EmployeesService.list()).data
    const filtered: { [filter: string]: Employee[] } = {}

    Object.entries(filters).forEach(([filterName, constraint]) => {
      filtered[filterName] = filter(retrieved, matches(constraint))
    })

    setEmployees(filtered)
    updateLoader(false)
  }

  const getList = useCallback(
    (search: string, currentFilter: Filters) => {
      const options: IFuseOptions<Employee> = {
        minMatchCharLength: 0,
        threshold: 0.2,
        keys: ['first_name', 'last_name', 'office.name', 'job_title.name'],
      }

      const list = search
        ? new Fuse(employees[currentFilter], options)
            .search(search)
            .map(({ item }) => item)
        : employees[currentFilter]

      return list || []
    },
    [employees]
  )

  useEffect(() => {
    getEmployees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Employees"
      pageLinks={links}
      tagline="View / manage the employees on the platform"
      actionArea={
        permissionsState.hasPermission(Permissions.AdminViewAccess) ? (
          <SlickLink to={EmployeesLinks.CreateEmployee}>
            <span>Create Employee</span>
          </SlickLink>
        ) : null
      }
    >
      <Loader isLoading={isLoading} message={message}>
        {employees[Filters.All]?.length ? (
          <Formik
            initialValues={{ filter: Filters.All, name: '' }}
            onSubmit={() => {}}
          >
            {({ values: { name, filter: currentFilter } }) => {
              const list = getList(name, currentFilter)

              return (
                <>
                  <FilterBar>
                    <section className="dropdown">
                      <Field as="select" name="filter">
                        <option value={Filters.All}>All</option>
                        <option value={Filters.Active}>Active</option>
                        <option value={Filters.Inactive}>Inactive</option>
                        <option value={Filters.OnLeave}>On Leave</option>
                      </Field>
                    </section>
                    <section>
                      <ListViewButton />
                      <Field
                        as={Input}
                        name="name"
                        placeholder="Filter by name..."
                      />
                    </section>
                  </FilterBar>
                  {isListMode ? (
                    <Table
                      columns={[
                        { Header: 'No.', accessor: 'id' },
                        {
                          Header: 'Name',
                          accessor: ({
                            first_name,
                            last_name,
                            slug,
                          }: Employee) => (
                            <Link to={`${SidebarLinks.Employees}/${slug}`}>
                              {first_name} {last_name}
                            </Link>
                          ),
                          sortType: (
                            { original: a }: Row<Employee>,
                            { original: b }: Row<Employee>
                          ) =>
                            `${a.first_name} ${a.last_name}`.toLowerCase() >
                            `${b.first_name} ${b.last_name}`.toLowerCase()
                              ? 1
                              : -1,
                        },
                        ...(permissionsState.hasPermission(
                          Permissions.AdminViewAccess
                        )
                          ? [{ Header: 'Email', accessor: 'email' }]
                          : []),
                        { Header: 'Job Title', accessor: 'job_title.name' },
                        { Header: 'Office', accessor: 'office.name' },
                        {
                          Header: 'Status',
                          accessor: ({ active }: Employee) =>
                            active ? 'Active' : 'Inactive',
                        },
                      ]}
                      data={list}
                    />
                  ) : (
                    <Container>
                      {list.map(
                        ({
                          id,
                          first_name,
                          last_name,
                          active,
                          office,
                          job_title,
                          slug,
                        }) => (
                          <Box
                            to={
                              permissionsState.userAllowed(slug)
                                ? `${SidebarLinks.Employees}/${slug}`
                                : '?'
                            }
                            background={`${process.env.REACT_APP_STORAGE}/profile/${slug}`}
                            ratio="5/7"
                            noLink={!permissionsState.userAllowed(slug)}
                            inactive={!active}
                            key={id}
                          >
                            <span>
                              {first_name} {last_name}
                              {slug === authState.user?.slug ? ' (You)' : ''}
                            </span>
                            <h6>
                              {job_title.name} in {office.name}
                            </h6>
                            <small>{active ? 'Active' : 'Inactive'}</small>
                          </Box>
                        )
                      )}
                    </Container>
                  )}
                </>
              )
            }}
          </Formik>
        ) : (
          NotCreated('employee', 'employees', EmployeesLinks.CreateEmployee)
        )}
      </Loader>
    </PageBody>
  )
}

export const Employees = Page(View, 'Employees')
