import { Permissions } from '../../../util/constants'
import { SidebarLinks } from '../links'

export enum OrganizationLinks {
  CreateAssetCategory = '/organization/asset-categories/create',
  AssetCategories = '/organization/asset-categories',
  CreateAsset = '/organization/assets/create',
  Assets = '/organization/assets',
}

export const links = [
  {
    path: SidebarLinks.Organization,
    name: 'Office Structure',
    exact: true,
  },
  {
    path: OrganizationLinks.AssetCategories,
    name: 'Asset Categories',
    exact: true,
    permission: Permissions.AdminViewAccess,
  },
  {
    path: OrganizationLinks.Assets,
    name: 'Assets',
    exact: true,
  },
]
