import {
  CreateJob,
  RejectTeamTimeline,
  SetTeam,
  SetTimeline,
} from '../model/request/jobs'
import { Job, Team } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class JobsService {
  public static async create(details: CreateJob) {
    return ApiClient.post<CreateJob, Job>('job', details, CreateJob)
  }

  public static async update(slug: string, details: CreateJob) {
    return ApiClient.patch<CreateJob, Job>('job',slug, details,CreateJob)
  }

  public static async list() {
    return ApiClient.get<Job[]>('job')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<Job>('job', id)
  }

  public static async approve(id: number | string) {
    return ApiClient.postOne<never, Job>('job/approval', id)
  }

  public static async reject(id: number | string, details: RejectTeamTimeline) {
    return ApiClient.deleteOne<RejectTeamTimeline, Job>(
      'job/approval',
      id,
      details,
      RejectTeamTimeline
    )
  }

  public static async setTimeline(id: number | string, timeline: SetTimeline) {
    return ApiClient.patch<SetTimeline, Job>(
      'job/timeline',
      id,
      timeline,
      SetTimeline
    )
  }

  public static async setTeam(id: number | string, team: SetTeam) {
    return ApiClient.patch<SetTeam, Team>('job/team', id, team, SetTeam)
  }

  public static async advance(id: number | string) {
    return ApiClient.postOne<never, Job>('job/advance', id)
  }
}
