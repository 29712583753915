export const Banks = [
  'Access Bank Plc',
  'Citibank Nigeria Limited',
  'Ecobank Nigeria Plc',
  'Fidelity Bank Plc',
  'First Bank of Nigeria Limited',
  'First City Monument Bank Limited',
  'Globus Bank Limited',
  'Guaranty Trust Bank Plc',
  'Heritage Banking Company Limited',
  'Jaiz Bank Plc',
  'Keystone Bank Limited',
  'Polaris Bank Limited',
  'Providus Bank Limited',
  'Stanbic IBTC Bank Plc',
  'Standard Chartered',
  'Sterling Bank Plc',
  'SunTrust Bank Nigeria Limited',
  'TAJBank Limited',
  'Titan Trust Bank Limited',
  'Union Bank of Nigeria Plc',
  'United Bank for Africa Plc',
  'Unity Bank Plc',
  'Wema Bank Plc',
  'Zenith Bank Plc',
]
