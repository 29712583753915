import { MinLength, ValidationError } from 'class-validator'

export class ResetPassword {
  @MinLength(8, { message: 'Must be at least 8 characters long' })
  public password!: string
  public password_confirmation!: string

  constructor() {
    this.password = ''
    this.password_confirmation = ''
  }

  public static extraValidation(object: ResetPassword) {
    if (object.password_confirmation !== object.password) {
      return [
        {
          ...new ValidationError(),
          property: 'password_confirmation',
          constraints: { value: 'Passwords do not match' },
        },
      ]
    }

    return []
  }
}
