import React, { useContext } from 'react'
import {
  Briefcase,
  Calendar,
  Clipboard,
  Layers,
  LogOut,
  Settings,
  TrendingUp,
  Users,
} from 'react-feather'
import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'
import { AuthContext } from '../../context/auth.context'
import { PermissionsContext } from '../../context/permissions.context'
import { SidebarLinks } from '../../page/main/links'
import { Permissions } from '../../util/constants'
import { ConfirmAlertConfig, generateConfirmAlert } from '../alert'

const Circle = styled(Link)`
  width: 40px;
  height: 40px;
  background: #d2d7d6;
  border-radius: 100%;
`

const Div = styled.div`
  width: 280px;
  padding: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > header {
    display: flex;
    color: white;
    font-weight: bold;
  }

  > header ${Circle} {
    flex-shrink: 0;
  }

  > header > section {
    margin-left: 20px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    overflow: hidden;
    white-space: nowrap;

    * {
      text-overflow: ellipsis;
    }
  }

  > nav {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 40px;
  }

  > nav > a,
  > section > button {
    appearance: none;
    color: #717a87;
    display: flex;
    align-items: center;
    font-size: 100%;
    background: transparent;
    outline: none;
    border: none;
    font-weight: bold;
    margin: 0;
    padding: 0;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }

    &.active {
      color: white;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 80px;
    padding: 80px 30px;

    > nav > a svg,
    > section > button svg {
      margin: 0;
    }

    ${Circle} {
      width: 20px;
      height: 20px;
    }

    > header > section,
    > nav > a span,
    > section > button span {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    width: 60px;
    padding: 20px;
  }
`

interface Route {
  name: string
  path: string
  exact: boolean
  icon: React.ComponentType<any>
  permission?: Permissions
  hidden?: boolean
}

const routes: Route[] = [
  {
    name: 'Dashboard',
    path: SidebarLinks.Index,
    exact: true,
    icon: Clipboard,
  },
  {
    name: 'Employees',
    path: SidebarLinks.Employees,
    exact: false,
    icon: Users,
  },
  {
    name: 'Organization',
    path: SidebarLinks.Organization,
    exact: false,
    icon: Layers,
  },
  { name: 'Events', path: SidebarLinks.Events, exact: false, icon: Calendar },
  {
    name: 'Jobs',
    path: SidebarLinks.Jobs,
    exact: false,
    icon: Briefcase,
  },
  {
    name: 'Requests',
    path: SidebarLinks.Requests,
    exact: false,
    icon: TrendingUp,
  },
  {
    name: 'Settings',
    path: SidebarLinks.Settings,
    exact: false,
    icon: Settings,
    permission: Permissions.SystemConfiguration,
  },
]

export const SideBar = () => {
  const { logout: systemLogout, user } = useContext(AuthContext)
  const { hasPermission } = useContext(PermissionsContext)

  const logout = () => {
    const config: ConfirmAlertConfig = {
      header: 'Log out',
      message: 'Are you sure you want to log out of the system?',
      confirmText: 'Yes, log out!',
      cancelText: 'Stay signed in',
    }

    generateConfirmAlert(config, systemLogout)
  }

  return (
    <Div>
      <header>
        <Circle
          to={`${SidebarLinks.Employees}/${user?.slug}`}
          style={{
            backgroundImage: `url(${process.env.REACT_APP_STORAGE}/profile/${user?.slug}),
                         url(/svg/portrait.svg)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
        <section>
          <span>{user?.first_name}</span>
          <Link to={`${SidebarLinks.Employees}/${user?.slug}`}>
            <small>View Profile</small>
          </Link>
        </section>
      </header>
      <nav>
        {routes
          .filter(({ permission, hidden }) =>
            hidden
              ? false
              : permission !== undefined
              ? hasPermission(permission)
              : true
          )
          .map(({ name, path, exact, icon: Icon }, i) => (
            <Link exact={exact} to={path} title={name} key={i}>
              <Icon />
              <span>{name}</span>
            </Link>
          ))}
      </nav>
      <section>
        <button onClick={logout}>
          <LogOut />
          <span>Log out</span>
        </button>
      </section>
    </Div>
  )
}
