import { Formik } from 'formik'
import { isMatch } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  CancelSlickButton,
  PrimarySlickButton,
  SlickButton,
} from '../../../component/button'
import { CreateEditForm } from '../../../component/container'
import { FormTextInput } from '../../../component/field'
import { Loader, PageBody } from '../../../component/misc'
import { SetApprovalLimits } from '../../../model/request/set-approval-limits.model'
import { ApprovalLimits as ApprovalLimitsModel } from '../../../model/response/approval-limits.model'
import { ApprovalLimitsService } from '../../../service'
import { validateWithModel } from '../../../util/validation'
import { Page, PageComponentProps } from '../../Page'
import { SidebarLinks } from '../links'
import { settingsLinks as links } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  { path: '', name: 'Approval Limits' },
]

const View: React.FC<PageComponentProps> = ({ loaderState }) => {
  const alert = useAlert()
  const [approvalLimits, setApprovalLimits] = useState<ApprovalLimitsModel>()
  const [editMode, setEditMode] = useState(false)

  const getApprovalLimits = async () => {
    setApprovalLimits(
      (await ApprovalLimitsService.list()).data as ApprovalLimitsModel
    )
  }

  useEffect(() => {
    getApprovalLimits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Formik
      enableReinitialize
      validate={validateWithModel(SetApprovalLimits)}
      initialValues={new SetApprovalLimits(approvalLimits)}
      onSubmit={async (values) => {
        if (!isMatch(approvalLimits as ApprovalLimitsModel, values)) {
          loaderState.setLoading()
          const response = await ApprovalLimitsService.update(values)
          loaderState.setDone()

          if (response.success) {
            alert.success('Approval limits updated successfully')
            setApprovalLimits({
              ...(approvalLimits as ApprovalLimitsModel),
              ...values,
            })
            setEditMode(false)
            return
          }

          alert.error(response.message)
          return
        }

        alert.info('No changes were made')
        setEditMode(false)
      }}
    >
      {({ handleSubmit, resetForm, isSubmitting }) => (
        <PageBody
          breadcrumbs={breadcrumbs}
          title="Approval Limits"
          tagline="View / manage the approval limits on the platform"
          pageLinks={links}
          actionArea={
            approvalLimits ? (
              editMode ? (
                <>
                  <CancelSlickButton
                    type="button"
                    onClick={() => {
                      resetForm()
                      setEditMode(false)
                    }}
                  >
                    <span>Cancel</span>
                  </CancelSlickButton>
                  <PrimarySlickButton
                    type="submit"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    <span>Save</span>
                  </PrimarySlickButton>
                </>
              ) : (
                <SlickButton
                  onClick={() => {
                    setEditMode(true)
                  }}
                >
                  <span>Edit</span>
                </SlickButton>
              )
            ) : null
          }
        >
          <Loader isLoading={!approvalLimits}>
            <CreateEditForm onSubmit={handleSubmit}>
              <main>
                <section>
                  <FormTextInput
                    label="Managing Partner"
                    name="managing_partner"
                    placeholder="Approval limit for managing partner"
                    type="number"
                    min={0}
                    disabled={!editMode || isSubmitting}
                  />
                  <FormTextInput
                    label="Engagement Partner"
                    name="engagement_partner"
                    placeholder="Approval limit for engagement partner"
                    type="number"
                    min={0}
                    disabled={!editMode || isSubmitting}
                  />
                </section>
              </main>
            </CreateEditForm>
          </Loader>
        </PageBody>
      )}
    </Formik>
  )
}

export const ApprovalLimits = Page(View, 'Approval Limits')
