import { IsBoolean, IsString, MinLength } from 'class-validator'
import { NamedEntity } from '../../response'

export class EditNamedEntity {
  @IsString({ message: 'Name is invalid' })
  @MinLength(3, { message: 'Name is too short' })
  public name!: string

  @IsBoolean({ message: 'Status must either be active or inactive' })
  public active!: boolean

  constructor(object?: NamedEntity) {
    this.name = object?.name || ''
    this.active = object ? object.active : true
  }
}
