/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik'
import React, { useEffect, useState } from 'react'
import { Check, Eye, EyeOff, Plus, X } from 'react-feather'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from '../../style'
import {
  formatCurrency,
  generateValidationMessage,
} from '../../util/validation'

export const FieldContainer = styled.div`
  background: white;
  width: 100%;
  display: flex;
  position: relative;
  transition: background-color 0.5s ease;

  &.disabled {
    label {
      color: ${Colors.Header} !important;
    }

    input,
    select,
    textarea {
      color: ${Colors.DarkGrey};
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-style: dashed;
      padding: 0 0 10px;
      font-weight: bold;
      background: #f9fbf9;
      background: transparent;
      height: unset;
    }
  }

  > label {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 70%;
    color: ${Colors.Header};
    position: absolute;
    width: 100px;
    transition: background-color 0.5s ease;

    > section {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    > section > span {
      display: flex;
      /* margin-left: 10px; */

      &::before {
        display: block;
        margin: 0 5px;
        content: '/';
      }
    }

    > a > svg {
      height: 10px;
      width: 10px;
      margin-left: 2.5px;
    }

    > section > svg {
      height: 10px;
      width: 10px;
      margin-right: 10px;
    }

    > a {
      display: flex;
      align-items: center;
      margin-left: 5px;
      font-weight: normal;
      color: ${Colors.DarkGrey};
    }
  }

  > label.error > section > span {
    color: ${Colors.Red};
  }

  > label.valid {
    color: ${Colors.Green};
  }

  input,
  select,
  textarea {
    height: 50px;
    width: 100%;
    color: ${Colors.Header};
    z-index: 0;
    background: white;
    padding: 15px 20px;

    /* Experimental */
    font-weight: bold;
    padding: 10px 20px;
    /* border-width: 0 0 2px 0; */
  }

  > span.button + input,
  > span.button + select {
    padding-right: 70px;
  }

  > span.button {
    position: absolute;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
    height: 50px;
    width: 50px;
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    color: ${Colors.Grey};
    transition: color 0.5s ease;
    z-index: 1;

    > * {
      width: 16px;
      height: 16px;
    }

    &:active,
    &:hover {
      color: ${Colors.Header};
    }
  }

  div[id^='currency-display-'] {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    height: 50px;
    width: 100%;
    background: red;
    color: white;
  }

  flex-direction: column;
  background: transparent;

  > label {
    position: initial;
    background: transparent;
    margin: 0 1px 10px;
    width: 100%;
    padding: 0;
  }

  textarea {
    height: 85px;
    min-height: 85px;
    max-height: 85px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`

export const Input = styled.input`
  appearance: none;
  background: white;
  border: 2px solid ${Colors.LightGrey};
  border-radius: 0;
  font-family: inherit;
  outline: none;
  padding: 0 20px;
  font-size: 85%;
  line-height: 16px;
  color: ${Colors.TextInput};
  transition: color 0.5s ease, border-color 0.5s ease, padding 0.25s ease;

  &:focus {
    border-color: ${Colors.Header};
    color: ${Colors.Header};
  }
`

interface LabelLink {
  to: string
  name: string
}

export const FormTextInput: React.FC<any> = ({
  info,
  required,
  label,
  trim,
  handleChange,
  type,
  addLink,
  ...props
}) => {
  const [isVisible, setVisible] = useState(false)
  const [field, meta, helpers] = useField(props)
  const [displayValue, setDisplayValue] = useState<string | number>(field.value)

  useEffect(() => {
    setDisplayValue(
      type === 'number' ? formatCurrency(field.value) : field.value
    )
  }, [field.value])

  return (
    <FieldContainer className={props.disabled ? 'disabled' : ''}>
      {label ? (
        <label className={meta.touched ? (meta.error ? 'error' : 'valid') : ''}>
          <section>
            {meta.touched && !props.disabled ? (
              meta.error ? (
                <X />
              ) : (
                <Check />
              )
            ) : null}
            {label}
            {generateValidationMessage(
              meta.initialError || meta.error,
              meta.touched,
              !!info
            )}
            {required ? (
              <b style={{ color: 'red', fontSize: '150%' }}>*</b>
            ) : null}
          </section>
          {addLink ? (
            <Link
              to={{
                pathname: addLink.to,
                state: addLink.state,
              }}
            >
              <span>Create</span>
              <Plus />
            </Link>
          ) : null}
        </label>
      ) : null}
      {type === 'password' ? (
        <span
          className={'button' + (isVisible ? ' active' : '')}
          onClick={() => {
            setVisible(!isVisible)
          }}
        >
          {!isVisible ? <EyeOff /> : <Eye />}
        </span>
      ) : null}
      <Input
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        {...field}
        onInput={(e: any) => {
          const value = e.target.value
          helpers.setValue(value)
        }}
        value={props.disabled ? displayValue : field.value}
        {...props}
        type={
          props.disabled
            ? ''
            : type === 'password'
            ? isVisible
              ? 'text'
              : 'password'
            : type
        }
      />
    </FieldContainer>
  )
}

export const TextInput: React.FC<any> = ({ label, ...props }) => {
  return (
    <FieldContainer>
      {label ? (
        <label>
          <span>{label}</span>
        </label>
      ) : null}
      <Input
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        {...props}
      />
    </FieldContainer>
  )
}
