import { CreateLeaveWizard, EditLeave } from '../model/request/leaves'
import { Leave } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class LeavesService {
  public static async create(details: CreateLeaveWizard) {
    return ApiClient.post<CreateLeaveWizard, Leave>(
      'leave/create',
      details,
      CreateLeaveWizard
    )
  }

  public static async list() {
    return ApiClient.get<Leave[]>('leave/view-list')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<Leave>('leave/show', id)
  }

  public static async update(id: number | string, details: EditLeave) {
    return ApiClient.patch<EditLeave, Leave>(
      'leave/update',
      id,
      details,
      EditLeave
    )
  }

  public static async delete(id: number | string) {
    return ApiClient.deleteOne<Leave>('leave/delete', id)
  }
}
