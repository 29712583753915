import { IsPositive, Min, ValidationError } from 'class-validator'
import { Job } from '../../response'

export class CreateFinancialRequest {
  @IsPositive({ message: 'Job is invalid' })
  public client_job_id!: number

  @Min(0, { message: 'Accommodation cannot be negative' })
  public accommodation!: number

  @Min(0, { message: 'Transportation cannot be negative' })
  public transportation!: number

  @Min(0, { message: 'Feeding cannot be negative' })
  public feeding!: number

  @Min(0, { message: 'Others cannot be negative' })
  public others!: number

  public total!: number

  constructor(job: Job) {
    this.client_job_id = job.id
    this.accommodation = 0
    this.transportation = 0
    this.feeding = 0
    this.others = 0
  }

  public static extraValidation(object: CreateFinancialRequest) {
    const { accommodation, transportation, feeding, others } = object
    object.total = +accommodation + +transportation + +feeding + +others

    if (object.total <= 0) {
      return [
        {
          ...new ValidationError(),
          property: 'total',
          constraints: {
            value: 'Total must be greater than zero',
          },
        },
      ]
    }

    return []
  }
}
