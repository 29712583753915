import {
  IsBoolean,
  IsEmail,
  IsPhoneNumber,
  IsString,
  MinLength,
} from 'class-validator'
import { PhoneRegion } from '../../../util/validation'
import { Client } from '../../response'

export class EditClient {
  @IsString({ message: 'Name is invalid' })
  @MinLength(3, { message: 'Name is too short' })
  public name!: string

  @IsEmail(undefined, { message: 'Email is invalid' })
  public email!: string

  @IsPhoneNumber(PhoneRegion, { message: 'Phone number is invalid' })
  public phone_number!: string

  @IsString({ message: 'Address is invalid' })
  @MinLength(3, { message: 'Address is too short' })
  public address!: string

  @IsBoolean({ message: 'Status must either be active or inactive' })
  public active!: boolean

  constructor(client?: Client) {
    this.name = client?.name || ''
    this.email = client?.email || ''
    this.phone_number = client?.phone_number || ''
    this.address = client?.address || ''
    this.active = client?.active || true
  }
}
