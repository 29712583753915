import { IsNumberString, IsString, Length, MinLength } from 'class-validator'

export class SetAccountDetails {
  @IsString({ message: 'Bank name is invalid' })
  @MinLength(3, { message: 'Bank name is too short' })
  public bank_name!: string

  @IsString({ message: 'Account name is invalid' })
  @MinLength(3, { message: 'Account name is too short' })
  public account_name!: string

  @IsNumberString({ message: 'Account number is invalid' })
  @Length(10, 10, { message: 'Account number is invalid' })
  public account_number!: string

  constructor() {
    this.bank_name = ''
    this.account_name = ''
    this.account_number = ''
  }
}
