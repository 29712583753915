import { Formik } from 'formik'
import React from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { PrimaryButton, PrimaryLink } from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { FormTextInput } from '../../../../component/field'
import { PageBody } from '../../../../component/misc'
import { CreateLeave as CreateLeaveModel } from '../../../../model/request/leaves'
import { LeavesService } from '../../../../service'
import { RoleFieldNames, roleKeys, RoleNames } from '../../../../util/constants'
import { validateWithModel } from '../../../../util/validation'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.Leaves,
    name: 'Leaves',
  },
  {
    path: '',
    name: 'Create Leave',
  },
]

const View: React.FC<PageComponentProps> = ({ history, loaderState }) => {
  const alert = useAlert()
  const { state } = useLocation<any>()
  const realRedirect = state?.referrer || SettingsLinks.Leaves

  return (
    <PageBody breadcrumbs={breadcrumbs}>
      <Formik
        initialValues={new CreateLeaveModel()}
        validate={validateWithModel(CreateLeaveModel)}
        onSubmit={async (values, { setErrors }) => {
          loaderState.setLoading('Creating leave')
          const response = await LeavesService.create(values)
          loaderState.setDone()

          if (response.success) {
            alert.success('Leave created successfully')
            history.push(
              realRedirect,
              state
                ? {
                    key: state.key,
                    values: {
                      ...state.values,
                      [state.field]: response.data?.id,
                    },
                  }
                : null
            )
            return
          }

          if (response.errors) {
            alert.error(
              'There was a problem with your data. Check the form again'
            )
            setErrors(response.errors as any)
            return
          }

          alert.error(response.message)
        }}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Leave</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <span>Basic Information</span>
                <FormTextInput
                  label="Name"
                  name="name"
                  placeholder="Leave name"
                  autoCapitalize="on"
                  spellCheck="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Number of Days"
                  name="length"
                  placeholder="Default number of days"
                  type="number"
                  min={0}
                  onChange={(e: any) => {
                    const previousValue = values.length
                    const { value: length } = e.target
                    handleChange(e)

                    console.log(length)

                    Object.values(RoleFieldNames).forEach((roleName) => {
                      if (values[roleName] === previousValue) {
                        setFieldTouched(roleName, false)
                      }

                      if (touched && touched[roleName]) {
                        return
                      }

                      setFieldValue(
                        roleName,
                        length ? Number.parseInt(length, 10) : 0
                      )
                    })
                  }}
                  disabled={isSubmitting}
                />
              </section>
              <section>
                <span>Role-Specific Length (Days)</span>
                {roleKeys.map((key, i) => (
                  <FormTextInput
                    label={(RoleNames as any)[key]}
                    name={(RoleFieldNames as any)[key]}
                    placeholder={`Number of days for ${
                      (RoleNames as any)[key]
                    }`}
                    type="number"
                    min={0}
                    disabled={isSubmitting}
                    key={i}
                  />
                ))}
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink
                to={
                  isSubmitting
                    ? '#'
                    : {
                        pathname: realRedirect,
                        state: state
                          ? { key: state.key, values: state.values }
                          : null,
                      }
                }
              >
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  )
}

export const CreateLeave = Page(View, 'Create Leave')
