import styled from 'styled-components'
import { Effects } from '../../style'

export const EntryForm = styled.form`
  box-shadow: ${Effects.DropShadow};
  background: #fff;
  border-radius: 2.5px;
  padding: 40px;
  width: 450px;
  display: flex;
  flex-direction: column;

  > header > img {
    height: 30px;
    margin-bottom: 20px;
  }

  > main {
    margin: 40px 0;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;
  }

  > footer {
    display: flex;
    align-items: flex-end;
    flex: 1;
  }

  @media screen and (max-width: 500px) {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    border-radius: 0;
    padding: 40px;
  }
`
