import { IsString, Matches, MinLength } from 'class-validator'
import { EmailValidationPattern } from '../../../util/validation'

export class ResetPasswordRequest {
  @Matches(EmailValidationPattern, { message: 'Email is invalid' })
  public email!: string

  @MinLength(8, { message: 'Must be at least 8 characters long' })
  public password!: string

  @IsString({ message: 'Password confirmation is invalid' })
  public password_confirmation!: string

  @IsString({ message: 'Token is invalid' })
  public token!: string
}
