/* eslint-disable react-hooks/exhaustive-deps */
import fetchInterceptor from 'fetch-intercept'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { FullPageLoader } from './component/misc'
import { AuthContext, AuthProvider } from './context/auth.context'
import { LoaderContext, LoaderProvider } from './context/loader.context'
import { PermissionsProvider } from './context/permissions.context'
import { Employee } from './model/response'
import { Routes } from './route'
import { Colors, GlobalStyle } from './style'
import { ApiClient } from './util/ApiClient'

const SESSION_TIMEOUT_DURATION = 10 * 60 * 1000

const LoaderWrapper = () => {
  const alert = useAlert()
  const { isLoading, message } = useContext(LoaderContext)
  const { user, logout, refresh } = useContext(AuthContext)
  let timer = setTimeout(() => {}, SESSION_TIMEOUT_DURATION)
  const [tracker] = useState({ user: null as Employee | null, timedOut: false })

  useEffect(() => {
    tracker.user = user
  }, [user])

  useEffect(() => {
    return register()
  }, [])

  const register = useCallback(() => {
    const resetTimer = () => {
      clearTimeout(timer)

      timer = setTimeout(() => {
        if (tracker.user) {
          ApiClient.abort()
          alert.info('You have been logged out due to inactivity')
          logout(true)
          tracker.timedOut = false
        }
      }, SESSION_TIMEOUT_DURATION)
    }

    resetTimer()

    return fetchInterceptor.register({
      request: (url, config) => {
        resetTimer()
        return [url, config]
      },
      response: (response) => {
        if (moment().isSameOrAfter(ApiClient.expires_at as string)) {
          ApiClient.abort()
          ApiClient.unsetToken()
          refresh()
        }

        return response
      },
    })
  }, [tracker])

  return (
    <ThemeProvider theme={Colors}>
      <GlobalStyle />
      {ReactDOM.createPortal(
        <FullPageLoader isLoading={isLoading} message={message} />,
        document.getElementById('portal') as Element
      )}
      <Routes />
    </ThemeProvider>
  )
}

const App = () => (
  <BrowserRouter>
    <LoaderProvider>
      <AuthProvider>
        <PermissionsProvider>
          <LoaderWrapper />
        </PermissionsProvider>
      </AuthProvider>
    </LoaderProvider>
  </BrowserRouter>
)

export default App
