import { CreateEvent, EditEvent } from '../model/request/events'
import { Event } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class EventsService {
  public static async create(details: CreateEvent) {
    return ApiClient.post<CreateEvent, Event>(
      'holiday/create',
      details,
      CreateEvent
    )
  }

  public static async list() {
    return ApiClient.get<Event[]>('holiday/view')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<Event>('holiday/show', id)
  }

  public static async update(id: number | string, details: EditEvent) {
    return ApiClient.patch<EditEvent, Event>(
      'holiday/update',
      id,
      details,
      EditEvent
    )
  }

  public static async delete(id: number | string) {
    return ApiClient.deleteOne<Event>('holiday/delete', id)
  }
}
