import { Field, Formik } from 'formik'
import { filter, includes, matches } from 'lodash'
import React, { useEffect, useState } from 'react'
import { SlickLink } from '../../../../component/button'
import { Box, Container } from '../../../../component/container'
import { Input } from '../../../../component/field'
import { FilterBar, PageBody, PageLink } from '../../../../component/misc'
import { NamedEntity } from '../../../../model/response'
import { ApiClient, ApiResponse } from '../../../../util/ApiClient'
import { useLoadingMessage } from '../../../../util/hook'
import { Filtered, NotCreated } from '../../common'
import { Filters, filters } from '../common'
import { settingsLinks as links } from '../links'

interface ListNamedEntitiesPageProps {
  breadcrumbs: any
  service: any
  singular: string
  plural: string
  listLink: string
  createLink: string
  links?: PageLink[]
  noFilter?: boolean
}

export const ListNamedEntitiesPage: React.FC<ListNamedEntitiesPageProps> = ({
  breadcrumbs,
  service,
  singular,
  plural,
  listLink,
  createLink,
  links: customLinks,
  noFilter,
}) => {
  const [entities, setEntities] = useState<Filtered>({})
  const { message, isLoading, updateLoader } = useLoadingMessage()

  const getEntities = async () => {
    updateLoader(true, `Fetching ${plural}`)
    const response = (await service.list()) as ApiResponse<NamedEntity[]>
    updateLoader(false)
    const filtered: Filtered = {}

    if (!response.errors) {
      Object.entries(filters).forEach(([filterName, constraint]) => {
        filtered[filterName] = filter(response.data, matches(constraint))
      })

      setEntities(filtered)
    }
  }

  useEffect(() => {
    getEntities()

    return () => {
      ApiClient.abort()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title={plural}
      pageLinks={customLinks || links}
      tagline={`View / manage the ${plural.toLowerCase()} on the platform`}
      isLoading={isLoading}
      loadingMessage={message}
      actionArea={
        <SlickLink to={createLink}>
          <span>Create {singular}</span>
        </SlickLink>
      }
    >
      {entities[Filters.All]?.length ? (
        <Formik
          initialValues={{ filter: Filters.All, name: '' }}
          onSubmit={() => {}}
        >
          {({ values }) => {
            let list = entities[values.filter]

            if (values.name.length >= 3) {
              list = list.filter(({ name }) =>
                includes(name.toLowerCase(), values.name.toLowerCase())
              )
            }

            return (
              <>
                {noFilter ? null : (
                  <FilterBar>
                    <section className="dropdown">
                      <Field as="select" name="filter">
                        <option value={Filters.All}>All</option>
                        <option value={Filters.Active}>Active</option>
                        <option value={Filters.Inactive}>Inactive</option>
                      </Field>
                    </section>
                    <Field
                      as={Input}
                      name="name"
                      placeholder="Filter by name..."
                    />
                  </FilterBar>
                )}
                <Container>
                  {list.map(({ id, name, active }) => (
                    <Box to={`${listLink}/${id}`} inactive={active === undefined ? false : !active} key={id}>
                      <span>{name}</span>
                      <small>
                        {active || active === undefined ? 'Active' : 'Inactive'}
                      </small>
                    </Box>
                  ))}
                </Container>
              </>
            )
          }}
        </Formik>
      ) : (
        NotCreated(singular.toLowerCase(), plural.toLowerCase(), createLink)
      )}
    </PageBody>
  )
}
