import { Field, Formik } from 'formik'
import { filter, includes, isMatch, matches } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import ReactModal from 'react-modal'
import { Row } from 'react-table'
import { generateConfirmAlert } from '../../../component/alert'
import {
  PrimarySlickButton,
  SlickButton,
  SlickLink,
} from '../../../component/button'
import { CreateEditForm } from '../../../component/container'
import { FormTextInput, Input } from '../../../component/field'
import { FilterBar, PageBody, Table } from '../../../component/misc'
import { EditRequest } from '../../../model/request/requests'
import { Employee, Request } from '../../../model/response'
import { RequestsService } from '../../../service'
import { Permissions, RequestStates } from '../../../util/constants'
import { useLoadingMessage } from '../../../util/hook'
import { formatCurrency, validateWithModel } from '../../../util/validation'
import { Page, PageComponentProps } from '../../Page'
import { NotCreated } from '../common'
import { SidebarLinks } from '../links'
import { RequestsLinks, requestsLinks } from './links'

ReactModal.setAppElement('#root')

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Requests, name: 'Requests' },
]

export enum Filters {
  All,
  Pending,
  Approved,
  Rejected,
}

const filters = {
  [Filters.All]: {},
  [Filters.Pending]: { status: RequestStates.Pending },
  [Filters.Approved]: { status: RequestStates.Approved },
  [Filters.Rejected]: { status: RequestStates.Rejected },
}

const View: React.FC<PageComponentProps> = ({
  authState,
  permissionsState,
  loaderState,
}) => {
  const alert = useAlert()
  const [isOpen, setIsOpen] = useState(false)
  const [requests, setRequests] = useState<{ [filter: string]: Request[] }>({})
  const [request, setRequest] = useState<Request>()
  const { message, isLoading, updateLoader } = useLoadingMessage()

  const openRequest = (id: number) => {
    setRequest(requests[Filters.All].find(matches({ id })))
    setIsOpen(true)
  }

  const closeRequest = () => {
    setRequest(undefined)
    setIsOpen(false)
  }

  const deleteRequest = async () => {
    loaderState.setLoading('Deleting request')
    const response = await RequestsService.delete(request?.id as number)
    loaderState.setDone()

    if (response.success) {
      closeRequest()
      alert.success(response.message)
      await getRequests()
      return
    }

    alert.error(response.message)
  }

  const updateRequest = async (
    isValid: boolean,
    details: EditRequest,
    approve?: boolean
  ) => {
    if (isValid) {
      loaderState.setLoading('Updating request')
      const response = await RequestsService.respond(
        request?.id as number,
        details,
        authState.user as Employee,
        approve
      )
      loaderState.setDone()

      if (response.success) {
        closeRequest()
        alert.success(response.message)
        await getRequests()
        return
      }

      alert.error(response.message)
      return
    }

    alert.error('Please check your details')
  }

  const verifyUpdateRequest = (
    isValid: boolean,
    details: EditRequest,
    approve?: boolean
  ) => {
    generateConfirmAlert(
      {
        header: `${approve ? 'Approve' : 'Reject'} request`,
        message: `Are you sure you want to ${
          approve ? 'approve' : 'reject'
        } this request?`,
        confirmText: `Yes, ${approve ? 'approve' : 'reject'}`,
        cancelText: 'No, cancel',
      },
      updateRequest.bind(undefined, isValid, details, approve)
    )
  }

  const verifyDeleteRequest = () => {
    generateConfirmAlert(
      {
        header: `Delete request`,
        message: `Are you sure you want to delete this request?`,
        confirmText: `Yes, delete`,
        cancelText: 'No, cancel',
      },
      deleteRequest.bind(undefined)
    )
  }

  const getRequests = async () => {
    updateLoader(true, 'Fetching requests')
    const retrieved = (await RequestsService.list()).data
    updateLoader(false)
    const filtered: { [filter: string]: Request[] } = {}

    Object.entries(filters).forEach(([filterName, constraint]) => {
      filtered[filterName] = filter(retrieved, matches(constraint))
    })

    setRequests(filtered)
  }

  useEffect(() => {
    getRequests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Requests"
      pageLinks={requestsLinks}
      isLoading={isLoading}
      loadingMessage={message}
      tagline="View / manage the requests on the platform"
      actionArea={
        <SlickLink to={RequestsLinks.CreateRequest}>
          <span>Create Request</span>
        </SlickLink>
      }
    >
      {requests[Filters.All]?.length ? (
        <>
          <Formik
            initialValues={{ filter: Filters.All, name: '' }}
            onSubmit={() => {}}
          >
            {({ values }) => {
              const list = requests[values.filter].filter(
                ({ type, description, status }) => {
                  const name = type + description + status
                  return values.name.length >= 3
                    ? includes(name.toLowerCase(), values.name.toLowerCase())
                    : true
                }
              )

              return (
                <>
                  <FilterBar>
                    <section className="dropdown">
                      <Field as="select" name="filter">
                        <option value={Filters.All}>All</option>
                        <option value={Filters.Pending}>Pending</option>
                        <option value={Filters.Approved}>Approved</option>
                        <option value={Filters.Rejected}>Rejected</option>
                      </Field>
                    </section>
                    <Field
                      as={Input}
                      name="name"
                      placeholder="Filter by name..."
                    />
                  </FilterBar>
                  <Table
                    columns={[
                      { Header: 'No.',id:'id', accessor: (d,i)=> i+1},
                      { Header: 'Request Type', accessor: 'type' },
                      {
                        Header: 'Request Creator',
                        accessor: ({ request_creator }: Request) =>
                          `${request_creator.first_name} ${request_creator.last_name}`,
                      },
                      { Header: 'Description', accessor: 'description' },
                      {
                        Header: 'Price',
                        accessor: 'price',
                        Cell: ({
                          row: {
                            original: { price },
                          },
                        }: {
                          row: Row<Request>
                        }) => {
                          return 'N'+formatCurrency(price)
                        },
                      },
                      { Header: 'Status', accessor: 'status' },
                      {
                        Header: ' ',
                        disableSortBy: true,
                        accessor: ({ id, request_creator, status }: Request) =>
                          permissionsState.userAllowed(request_creator.slug) ? (
                            <button
                              onClick={openRequest.bind(undefined, id)}
                              className="button"
                            >
                              <span>
                                {request_creator.slug ===
                                  authState.user?.slug &&
                                status === RequestStates.Pending
                                  ? 'Edit'
                                  : 'Open'}
                              </span>
                            </button>
                          ) : null,
                      },
                    ]}
                    data={list}
                  />
                </>
              )
            }}
          </Formik>
          <ReactModal
            overlayClassName="modal__overlay"
            className="modal"
            isOpen={isOpen}
            onRequestClose={closeRequest.bind(undefined)}
          >
            <Formik
              initialValues={new EditRequest(request)}
              enableReinitialize
              validate={validateWithModel(EditRequest)}
              onSubmit={async (values) => {
                if (!isMatch(new EditRequest(request), values)) {
                  loaderState.setLoading('Updating request')
                  const response = await RequestsService.update(
                    request?.id as number,
                    values
                  )
                  loaderState.setDone()

                  if (response.success) {
                    closeRequest()
                    alert.success(response.message)
                    await getRequests()
                    return
                  }

                  alert.error(response.message)
                  return
                }

                alert.info('No changes were made')
                closeRequest()
              }}
            >
              {({
                handleChange,
                handleSubmit,
                isSubmitting,
                isValid,
                values,
              }) => (
                <CreateEditForm onSubmit={handleSubmit}>
                  <header>
                    <h2>Request</h2>
                    <h6>Request information</h6>
                  </header>
                  <main>
                    <section>
                      <FormTextInput
                        label="Description"
                        name="description"
                        placeholder="Description"
                        autoCapitalize="on"
                        as="textarea"
                        handleChange={handleChange}
                        disabled={
                          authState.user?.slug !==
                            request?.request_creator.slug ||
                          request?.status !== RequestStates.Pending ||
                          isSubmitting
                        }
                      />
                      <FormTextInput
                        label="Price (N)"
                        name="price"
                        placeholder="Price"
                        type="number"
                        handleChange={handleChange}
                        disabled={
                          authState.user?.slug !==
                            request?.request_creator.slug ||
                          request?.status !== RequestStates.Pending ||
                          isSubmitting
                        }
                      />
                      <FormTextInput
                        label="Response"
                        name="response"
                        placeholder="Response"
                        autoCapitalize="on"
                        as="textarea"
                        handleChange={handleChange}
                        disabled={
                          !permissionsState.hasPermission(
                            Permissions.AdminViewAccess
                          ) ||
                          authState.user?.slug ===
                            request?.request_creator.slug ||
                          request?.status !== RequestStates.Pending ||
                          isSubmitting
                        }
                      />
                    </section>
                  </main>
                  {request?.status === RequestStates.Pending ? (
                    <footer>
                      {permissionsState.hasPermission(
                        Permissions.AdminViewAccess
                      ) &&
                      authState.user?.slug !== request?.request_creator.slug ? (
                        <>
                          <PrimarySlickButton
                            type="button"
                            onClick={verifyUpdateRequest.bind(
                              undefined,
                              isValid,
                              values,
                              true
                            )}
                          >
                            <span>Approve</span>
                          </PrimarySlickButton>
                          <SlickButton
                            type="button"
                            onClick={verifyUpdateRequest.bind(
                              undefined,
                              isValid,
                              values,
                              false
                            )}
                          >
                            <span>Reject</span>
                          </SlickButton>
                        </>
                      ) : (
                        <>
                          <PrimarySlickButton type="submit">
                            <span>Update</span>
                          </PrimarySlickButton>
                          <SlickButton
                            type="button"
                            onClick={verifyDeleteRequest.bind(undefined)}
                          >
                            <span>Delete</span>
                          </SlickButton>
                        </>
                      )}
                    </footer>
                  ) : null}
                </CreateEditForm>
              )}
            </Formik>
          </ReactModal>
        </>
      ) : (
        NotCreated('request', 'requests', RequestsLinks.CreateRequest)
      )}
    </PageBody>
  )
}

export const Requests = Page(View, 'Requests')
