import React from 'react'
import { Prompt } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { Colors } from '../../style'

const FixedBodyStyle = createGlobalStyle`
  body {
    height: 100vh !important;
    width: 100vw !important;
  }
`

const PageLoaderContainer = styled.div`
  background: ${Colors.Primary}dd;
  backdrop-filter: blur(10px);
  color: white;
  font-weight: bold;
  font-size: 160%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;

  img {
    height: 160px;
  }

  * + * {
    margin-top: 10px;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  padding: 30px;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  font-weight: bold;
  font-size: 160%;

  img {
    height: 160px;
  }

  * + * {
    margin-top: 10px;
  }
`

interface LoaderProps {
  isLoading?: boolean
  message?: string
}

const navigateMessage =
  'Are you sure you want to navigate away? There is data loading'

export const FullPageLoader: React.FC<LoaderProps> = ({ isLoading, message }) =>
  isLoading ? (
    <PageLoaderContainer>
      <FixedBodyStyle />
      <img src="/img/needle-loader-white.gif" alt="Loader" />
      {message ? <span>{message}</span> : null}
    </PageLoaderContainer>
  ) : null

export const Loader: React.FC<LoaderProps> = ({
  isLoading,
  message,
  children,
}) =>
  isLoading ? (
    <LoaderContainer>
      {process.env.NODE_ENV === 'production' ? (
        <Prompt when={true} message={navigateMessage} />
      ) : null}
      <img src="/img/needle-loader-white.gif" alt="Loader" />
      {message ? <span>{message}</span> : null}
    </LoaderContainer>
  ) : (
    <>{children}</>
  )
