import React from 'react'
import { AssetCategoriesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { CreateNamedEntityForm } from '../../settings/common/CreateForm'
import { OrganizationLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Organization, name: 'Organization' },
  {
    path: OrganizationLinks.AssetCategories,
    name: 'Asset Categories',
  },
  {
    path: '',
    name: 'Create Asset Category',
  },
]

const View: React.FC<PageComponentProps> = ({ history }, ...props) => {
  return (
    <CreateNamedEntityForm
      breadcrumbs={breadcrumbs}
      service={AssetCategoriesService}
      history={history}
      singular="Asset Category"
      redirect={OrganizationLinks.AssetCategories}
    />
  )
}

export const CreateAssetCategory = Page(View, 'Create Asset Category')
