import { IsNotEmpty, Matches } from 'class-validator'
import { EmailValidationPattern } from '../../../util/validation'

export class Login {
  @Matches(EmailValidationPattern, { message: 'Invalid ABDC email' })
  public email!: string

  @IsNotEmpty({ message: 'Enter a password' })
  public password!: string

  constructor() {
    this.email = ''
    this.password = ''
  }
}
