import { IsEnum, IsNotEmpty, MinLength } from 'class-validator'
import { DisciplinaryCaseStates } from '../../../util/constants'

export class EditDisciplinaryCase {
  @IsNotEmpty({ message: 'Enter a penalty' })
  @MinLength(3, { message: 'Penalty is too short' })
  public penalty!: string

  @IsEnum(DisciplinaryCaseStates, { message: 'State is invalid' })
  public status!: DisciplinaryCaseStates
}
