import { CreateAsset, EditAsset } from '../model/request/assets'
import { Asset } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class AssetsService {
  public static async create(details: CreateAsset) {
    return ApiClient.post<CreateAsset, Asset>(
      'asset/create-office-asset',
      details,
      CreateAsset
    )
  }

  public static async list() {
    return ApiClient.get<Asset[]>('asset/view-office-asset')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<Asset>('asset/show/office-asset', id)
  }

  public static async update(id: number | string, details: EditAsset) {
    return ApiClient.patch<EditAsset, Asset>(
      'asset/update/office-asset',
      id,
      details,
      EditAsset
    )
  }

  public static async delete(id: number | string) {
    return ApiClient.deleteOne('asset/delete/office-asset', id)
  }
}
