import { SidebarLinks } from '../links'

export enum EmployeesLinks {
  CreateEmployee = '/employees/create',
  Freelancers = '/employees/freelancers',
  CreateFreelancer = '/employees/freelancers/create',
  DisciplinaryCases = '/employees/disciplinary-cases',
  CreateDisciplinaryCase = '/employees/disciplinary-cases/create',
}

export const employeesLinks = [
  { path: SidebarLinks.Employees, name: 'Employees', exact: true },
  { path: EmployeesLinks.Freelancers, name: 'Freelancers', exact: true },
  {
    path: EmployeesLinks.DisciplinaryCases,
    name: 'Disciplinary Cases',
    exact: true,
  },
]
