import { Formik } from 'formik'
import { isMatch } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  CancelSlickButton,
  PrimarySlickButton,
  SlickButton,
} from '../../../../component/button'
import { CreateEditForm, FormDivider } from '../../../../component/container'
import { FormTextInput, Switch } from '../../../../component/field'
import { Loader, PageBody } from '../../../../component/misc'
import { EditFreelancer } from '../../../../model/request/freelancers'
import { Freelancer } from '../../../../model/response'
import { FreelancersService } from '../../../../service'
import { Banks } from '../../../../util/constants'
import { validateWithModel } from '../../../../util/validation'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { EmployeesLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Employees, name: 'Employees' },
  { path: EmployeesLinks.Freelancers, name: 'Freelancers' },
  {
    path: '',
    name: 'Freelancer',
  },
]

const View: React.FC<PageComponentProps> = ({
  history,
  params,
  authState,
  loaderState,
  permissionsState,
}) => {
  const alert = useAlert()
  const [freelancer, setFreelancer] = useState<Freelancer>()
  const [editMode, setEditMode] = useState(false)

  const getFreelancer = async () => {
    const response = (await FreelancersService.retrieve(params.slug)).data
    setFreelancer(response)
  }

  useEffect(() => {
    getFreelancer()
    // eslint-disable-next-line
  }, [params.slug])

  return (
    <Formik
      enableReinitialize
      validate={validateWithModel(EditFreelancer)}
      initialValues={new EditFreelancer(freelancer)}
      onSubmit={async values => {
        if (!isMatch(freelancer as Freelancer, values)) {
          loaderState.setLoading('Updating freelancer')
          const response = await FreelancersService.update(params.slug, values)
          loaderState.setDone()

          if (response.success) {
            alert.success(`Freelancer updated successfully`)
            setFreelancer({
              ...(response.data as Freelancer),
              ...values,
            })
            setEditMode(false)
            return
          }

          alert.error(response.message)
          return
        }

        alert.info('No changes were made')
        setEditMode(false)
      }}
    >
      {({ handleSubmit, handleChange, resetForm, isSubmitting }) => (
        <Loader
          isLoading={!freelancer}
          message="Fetching freelancer information"
        >
          <PageBody
            breadcrumbs={breadcrumbs}
            title={`${freelancer?.first_name} ${freelancer?.last_name}`}
            tagline={freelancer?.active ? 'Active' : 'Inactive'}
            actionArea={
              editMode ? (
                <>
                  <CancelSlickButton
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => {
                      resetForm()
                      setEditMode(false)
                    }}
                  >
                    <span>Cancel</span>
                  </CancelSlickButton>
                  <PrimarySlickButton
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    <span>Save</span>
                  </PrimarySlickButton>
                </>
              ) : (
                <SlickButton
                  onClick={() => {
                    setEditMode(true)
                  }}
                >
                  <span>Edit</span>
                </SlickButton>
              )
            }
          >
            <CreateEditForm onSubmit={handleSubmit}>
              <main>
                {editMode ? (
                  <section>
                    <Switch
                      name="active"
                      label="Status"
                      disabled={isSubmitting}
                    />
                  </section>
                ) : null}
                <FormDivider>
                  <section>
                    <span>Basic Information</span>
                    <FormTextInput
                      label="First Name"
                      name="first_name"
                      placeholder="First name"
                      autoCapitalize="on"
                      trim
                      handleChange={handleChange}
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Middle Name"
                      name="middle_name"
                      placeholder="Middle name"
                      autoCapitalize="on"
                      trim
                      handleChange={handleChange}
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Last Name"
                      name="last_name"
                      placeholder="Last name"
                      autoCapitalize="on"
                      trim
                      handleChange={handleChange}
                      disabled={!editMode || isSubmitting}
                    />
                  </section>
                  <section>
                    <span>Contact Information</span>
                    <FormTextInput
                      label="Company Name"
                      name="company_name"
                      placeholder="Company name"
                      trim
                      handleChange={handleChange}
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Phone Number"
                      name="phone_number"
                      placeholder="Phone number"
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Job Title"
                      placeholder="Job title"
                      name="job_title"
                      trim
                      handleChange={handleChange}
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Address"
                      placeholder="Address"
                      name="address"
                      as="textarea"
                      handleChange={handleChange}
                      disabled={!editMode || isSubmitting}
                    />
                  </section>
                </FormDivider>
                <FormDivider>
                  <section>
                    <span>Account Details</span>
                    <FormTextInput
                      label="Bank Name"
                      placeholder="Bank name"
                      name="bank_name"
                      as="select"
                      disabled={!editMode || isSubmitting}
                    >
                      <option value="" disabled>
                        Select bank
                      </option>
                      {Banks.map((bank, i) => (
                        <option key={i}>{bank}</option>
                      ))}
                    </FormTextInput>
                    <FormTextInput
                      label="Account Name"
                      placeholder="Account name"
                      name="account_name"
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Account Number"
                      placeholder="Account number"
                      name="account_number"
                      disabled={!editMode || isSubmitting}
                    />
                  </section>
                </FormDivider>
              </main>
            </CreateEditForm>
          </PageBody>
        </Loader>
      )}
    </Formik>
  )
}

export const SingleFreelancer = Page(View, 'Freelancer')
