import React from 'react'
import { JobTypesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { CreateNamedEntityForm } from '../common/CreateForm'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.JobTypes,
    name: 'Job Types',
  },
  {
    path: '',
    name: 'Create Job Type',
  },
]

const View: React.FC<PageComponentProps> = ({ history }) => {
  return (
    <CreateNamedEntityForm
      breadcrumbs={breadcrumbs}
      service={JobTypesService}
      history={history}
      singular="Job Type"
      redirect={SettingsLinks.JobTypes}
    />
  )
}

export const CreateJobType = Page(View, 'Create Job Type')
