import styled from 'styled-components'
import { Colors } from '../../style'

export const FilterBar = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 30px;

  input {
    border-color: ${Colors.LightGrey} !important;
    width: 150px;
    height: 100%;
  }

  > * {
    height: 100%;
    display: flex-inline;
  }

  svg {
    height: 15px !important;
    width: 15px !important;
  }

  > * + * {
    margin-left: 20px;
  }

  > section.dropdown::after {
    content: ' ';
    width: 10px;
    height: 10px;
    background: url(/svg/chevron-down.svg);
    transform: rotate(90deg);
  }

  > section {
    display: flex;
    align-items: center;
  }

  > section > * {
    height: 100%;
  }

  > section > span,
  > section > select {
    padding: 10px 20px;
  }

  > section > span {
    border: none;
  }

  > section > select {
    border: none;
    background: ${Colors.LightGrey};
    outline: 0;
    font-size: 90%;
    color: #606276;
    appearance: none;
    position: relative;
  }

  > section > select::before {
    position: absolute;
    content: '>';
    top: 10px;
    right: 20px;
  }

  > section > select::-ms-expand {
    display: none;
  }
`
