import { IsEnum, IsPositive, Matches } from 'class-validator'
import { RoleNames } from '../../../util/constants'
import {
  DateValidationPattern,
  EmailValidationPattern,
  getFormattedDate,
  IsNotBeforeOrAfter,
  IsNotWeekend,
  IsOptionalOrEmpty,
  NameValidationPattern,
} from '../../../util/validation'

export class CreateEmployee {
  @Matches(NameValidationPattern, { message: 'First name is invalid' })
  public first_name!: string

  @IsOptionalOrEmpty()
  @Matches(NameValidationPattern, { message: 'Middle name is invalid' })
  public middle_name!: string

  @Matches(NameValidationPattern, { message: 'Last name is invalid' })
  public last_name!: string

  @Matches(EmailValidationPattern, {
    message: 'Not a valid ABDC email address',
  })
  public email!: string

  @IsNotBeforeOrAfter(undefined, true, {
    message: 'Date of employment cannot be in the future',
  })
  @IsNotWeekend({
    message: 'Date of employment cannot be on a weekend',
  })
  @Matches(DateValidationPattern, {
    message: 'Date of employment is invalid',
  })
  public date_of_employment!: string

  @IsPositive({ message: 'Office designation is invalid' })
  public office_id!: number

  @IsEnum(RoleNames, { message: 'Role is invalid' })
  public role!: RoleNames

  @IsPositive({ message: 'Job title is invalid' })
  public job_title_id!: number

  constructor(object?: CreateEmployee) {
    this.first_name = object?.first_name || ''
    this.middle_name = object?.middle_name || ''
    this.last_name = object?.last_name || ''
    this.email = object?.email || ''
    this.date_of_employment = object?.date_of_employment || getFormattedDate()
    this.office_id = object?.office_id || 0
    this.role = object?.role || RoleNames.Employee
    this.job_title_id = object?.job_title_id || 0
  }

  public static transform(object: CreateEmployee) {
    object.office_id = Number.parseInt(object.office_id as any, 10)
    object.job_title_id = Number.parseInt(object.job_title_id as any, 10)
  }
}
