import {
  CreateNamedEntity,
  EditNamedEntity,
} from '../model/request/named-entities'
import { NamedEntity } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class OfficesService {
  public static async create(details: CreateNamedEntity) {
    return ApiClient.post<CreateNamedEntity, NamedEntity>(
      'office/create',
      details,
      CreateNamedEntity
    )
  }

  public static async list() {
    return ApiClient.get<NamedEntity[]>('office/view-list')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<NamedEntity>('office/show', id)
  }

  public static async update(id: number | string, details: EditNamedEntity) {
    return ApiClient.patch<EditNamedEntity, NamedEntity>(
      'office/update',
      id,
      details,
      EditNamedEntity
    )
  }
}
