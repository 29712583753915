import { SetApprovalLimits } from '../model/request/set-approval-limits.model'
import { ApprovalLimits } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class ApprovalLimitsService {
  public static async list() {
    const response = await ApiClient.get<ApprovalLimits>(
      'approval-limit/view-list'
    )

    if (response.success) {
      return { ...response, data: (response.data as any)[0] as ApprovalLimits }
    }

    return response
  }

  public static async update(details: SetApprovalLimits) {
    return ApiClient.patch<SetApprovalLimits, ApprovalLimits>(
      'approval-limit/update',
      1, // There is only one entry for approval limits
      details,
      SetApprovalLimits
    )
  }
}
