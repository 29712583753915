import { Matches } from 'class-validator'
import {
  DateValidationPattern,
  IsNotBeforeOrAfter,
  IsNotWeekend,
} from '../../../util/validation'
import { Job } from '../../response'

export class SetTimeline {
  @IsNotBeforeOrAfter(undefined, false, {
    message: 'End date cannot be in the past',
  })
  @IsNotWeekend({
    message: 'Start date cannot be on a weekend',
  })
  @Matches(DateValidationPattern, {
    message: 'Start date is invalid',
  })
  public start_date!: string

  @IsNotBeforeOrAfter('start_date', false, {
    message: 'End date cannot be before the start date',
  })
  @IsNotWeekend({
    message: 'End date cannot be on a weekend',
  })
  @Matches(DateValidationPattern, {
    message: 'End date is invalid',
  })
  public end_date!: string

  constructor(job?: Job) {
    this.start_date = job?.start_date || ''
    this.end_date = job?.end_date || ''
  }
}
