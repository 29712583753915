import { filter } from 'lodash'
import React, { ReactNode, useContext, useMemo, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { PermissionsContext } from '../../context/permissions.context'
import { Colors } from '../../style'
import { Permissions } from '../../util/constants'
import { Loader } from './Loaders'

const Div = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  position: absolute;
  height: 100%;
  width: calc(100vw - 280px);
  top: 0;
  left: 280px;
  transition: width 0.25s ease;
  overflow: hidden;

  > nav.breadcrumbs {
    padding: 20px 60px;
    background: #232b29;

    > section {
      display: flex;
      align-items: center;

      > * {
        font-size: 70%;
        font-weight: 700;
        display: flex;
        align-items: center;
      }

      > span {
        color: ${Colors.Primary};
        color: white;
      }

      > a {
        color: ${Colors.DarkGrey};
        color: #b7b7b7;
      }

      > * + *::before {
        content: '/';
        display: flex;
        margin: 0 5px;
        font-size: inherit;
      }
    }
  }

  > header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: white;
    border-bottom: 2px solid ${Colors.LightGrey};
    position: relative;
    padding: 30px 60px;
  }

  > header h1 {
    text-transform: capitalize;
    color: ${Colors.Primary};
    font-size: 26px;

    & + * {
      margin-top: 10px;
    }
  }

  > header h1 > span {
    color: ${Colors.Header};
    text-transform: initial;

    &::before {
      content: '/';
      margin: 0 5px;
    }
  }

  > header *.action-area {
    display: flex;

    > * + * {
      margin-left: 20px;
    }
  }

  > nav.page-links {
    background: linear-gradient(#fafbfc, white);
    background: #ddefe2;
    background: #ebf1ec;
    padding: 0 60px;
    display: flex;
  }

  > nav.page-links section {
    width: 100%;
    display: flex;
    overflow-x: auto;
  }

  > nav.page-links section > a {
    flex-shrink: 0;
    padding: 15px 0;
    display: flex;
    border: 1px solid transparent;
    justify-content: center;
    align-items: center;
    color: #576961;
    font-size: 80%;
    position: relative;
  }

  > nav.page-links section > a + a {
    margin-left: 40px;

    ::after {
      --color: #bbd0cc;
      display: block;
      content: '';
      height: 16px;
      width: 1px;
      top: calc(50% - 8px);
      position: absolute;
      left: -20px;
      background: var(--color);
    }
  }

  > nav.page-links section > a.active {
    color: ${Colors.Header};

    ::before {
      display: block;
      content: '';
      position: absolute;
      top: calc(100% - 2px);
      height: 2px;
      width: 100%;
      background: #a7bdab;
    }
  }

  > main {
    background: white;
    /* background: url(/img/backdrop.svg) white; */
    background-position: center;
    background-size: 800px;
    row-gap: 30px;
    flex: 1;
    padding: 30px 60px;

    /* This is because of the scroller plugin */
    > div > div > span {
      display: flex;
    }

    > div > div > * + * {
      margin-top: 20px;
    }

    ul {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 20px;
      padding: 0;
      margin: 0;
    }

    li {
      font-size: 90%;
      list-style: none;
    }

    li > * {
      color: #576961;
      display: inline-flex;
      background: #f9f9f9;
      padding: 5px 7.5px;
    }

    li.split {
      display: grid;
      grid-template-columns: 280px 1fr;
      column-gap: 20px;
    }

    li.split *:nth-child(2) {
      color: #6a6f85;
    }
  }

  @media screen and (max-width: 1200px) {
    width: calc(100vw - 80px);
    border-radius: 0;
    top: 0;
    left: 80px;
    height: 100%;
  }

  @media screen and (max-width: 800px) {
    > header {
      flex-direction: column;
    }

    > header *.action-area {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 440px;
    left: 60px;

    > nav.breadcrumbs {
      padding: 10px 30px !important;
      font-size: 80%;
    }

    > * {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }
`

export interface Breadcrumb {
  path: string
  name: string
}

export interface PageLink extends Breadcrumb {
  exact: boolean
  permission?: Permissions
}

interface PageContentProps {
  className?: string
  title?: string
  subTitle?: string
  tagline?: string | React.ReactNode
  breadcrumbs: Breadcrumb[]
  pageLinks?: PageLink[]
  actionArea?: ReactNode
  isLoading?: boolean
  loadingMessage?: string
}

export const PageBody: React.FC<PageContentProps> = ({
  className,
  title,
  subTitle,
  tagline,
  breadcrumbs,
  pageLinks,
  actionArea,
  isLoading,
  loadingMessage,
  children,
}) => {
  const { hasPermission } = useContext(PermissionsContext)
  const [previousPages] = useState(breadcrumbs.slice(0, breadcrumbs.length - 1))
  const [currentPage] = useState(breadcrumbs.slice(-1)[0])
  const filteredLinks = useMemo(
    () =>
      pageLinks
        ? filter(pageLinks, ({ permission }) =>
            permission !== undefined ? hasPermission(permission) : true
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Div className={className}>
      <nav className="breadcrumbs">
        <section>
          {previousPages.map(({ name, path }, i) => (
            <Link to={path} key={i}>
              <span>{name}</span>
            </Link>
          ))}
          <span>{currentPage?.name}</span>
        </section>
      </nav>
      {title ? (
        <header>
          <section>
            <h1>
              {title}
              {subTitle ? <span>{subTitle}</span> : null}
            </h1>
            <h6>{tagline}</h6>
          </section>
          {actionArea ? (
            <section className="action-area">{actionArea}</section>
          ) : null}
        </header>
      ) : null}
      {pageLinks ? (
        <nav className="page-links">
          <section>
            {filteredLinks.map(({ path, name, exact }, i) => (
              <NavLink exact={exact} to={path} key={i}>
                <span>{name}</span>
              </NavLink>
            ))}
          </section>
        </nav>
      ) : null}
      <Loader isLoading={isLoading} message={loadingMessage}>
        <main>
          <Scrollbars>{children}</Scrollbars>
        </main>
      </Loader>
    </Div>
  )
}
