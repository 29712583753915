import { Formik, FormikErrors } from "formik";
import React from "react";
import { useAlert } from "react-alert";
import { Check, X } from "react-feather";
import { PrimaryButton, PrimaryLink } from "../../../component/button";
import { CreateEditForm } from "../../../component/container";
import { FormTextInput } from "../../../component/field";
import { PageBody } from "../../../component/misc";
import { CreateRequest as CreateRequestModel } from "../../../model/request/requests";
import { Employee } from "../../../model/response";
import { RequestsService } from "../../../service";
import { RequestTypes } from "../../../util/constants";
import { validateWithModel } from "../../../util/validation";
import { Page, PageComponentProps } from "../../Page";
import { SidebarLinks } from "../links";

const breadcrumbs = [
  { path: SidebarLinks.Index, name: "Dashboard" },
  {
    path: SidebarLinks.Requests,
    name: "Requests",
  },
  {
    path: "",
    name: "Create Request",
  },
];

const View: React.FC<PageComponentProps> = ({
  history,
  loaderState,
  authState,
}) => {
  const alert = useAlert();

  return (
    <PageBody breadcrumbs={breadcrumbs}>
      <Formik
        initialValues={
          new CreateRequestModel(undefined, authState.user as Employee)
        }
        validate={validateWithModel(CreateRequestModel)}
        onSubmit={async (values, { setErrors }) => {
          loaderState.setLoading("Creating request");
          const response = await RequestsService.create(values);
          loaderState.setDone();

          if (response.success) {
            alert.success("Request created successfully");
            history.push(SidebarLinks.Requests, null);
            return;
          }

          alert.error(response.message);
          setErrors(response.errors as FormikErrors<CreateRequestModel>);
        }}
      >
        {({ handleChange, handleSubmit, isSubmitting }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Request</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <FormTextInput
                  label="Request Type"
                  as="select"
                  name="type"
                  disabled={isSubmitting}
                >
                  <option value="" disabled>
                    Select request type
                  </option>
                  {Object.values(RequestTypes).map((type, i) => (
                    <option value={type} key={i}>
                      {type}
                    </option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Description"
                  name="description"
                  as="textarea"
                  placeholder="Request description"
                  autoCapitalize="on"
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Price"
                  name="price"
                  placeholder="Price"
                  type="number"
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink
                to={
                  isSubmitting
                    ? "#"
                    : { pathname: SidebarLinks.Requests, state: null }
                }
              >
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  );
};

export const CreateRequest = Page(View, "Create Request");
