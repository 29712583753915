import {
  CreateLeaveRequest,
  EditLeaveRequest,
  RejectLeaveRequest,
} from '../model/request/requests'
import { LeaveRequest } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class LeaveRequestsService {
  public static async create(details: CreateLeaveRequest) {
    return ApiClient.post<CreateLeaveRequest, LeaveRequest>(
      'leave-request/create',
      details,
      CreateLeaveRequest
    )
  }

  public static async list() {
    return ApiClient.get<LeaveRequest[]>('leave-request/view')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<LeaveRequest>('leave-request/show', id)
  }

  public static async update(id: number | string, details: EditLeaveRequest) {
    return ApiClient.patch<EditLeaveRequest, LeaveRequest>(
      'leave-request/update',
      id,
      details,
      EditLeaveRequest
    )
  }

  public static async accept(id: number) {
    return ApiClient.postOne<never, LeaveRequest>('leave-request/approve', id)
  }

  public static async reject(id: number, details: RejectLeaveRequest) {
    return ApiClient.postOne<RejectLeaveRequest, LeaveRequest>(
      'leave-request/reject',
      id,
      details,
      RejectLeaveRequest
    )
  }

  public static async delete(id: number | string) {
    return ApiClient.deleteOne<LeaveRequest>('leave-request/delete', id)
  }
}
