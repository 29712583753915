import { IsPositive, IsString, MinLength } from 'class-validator'
import { Leave } from '../../response'
import { positiveError } from './common'

export class EditLeave {
  @IsString({ message: 'Name is invalid' })
  @MinLength(3, { message: 'Name is too short' })
  public name!: string

  @IsPositive({ message: positiveError })
  public principal_partner!: number

  @IsPositive({ message: positiveError })
  public managing_partner!: number

  @IsPositive({ message: positiveError })
  public tax_partner!: number

  @IsPositive({ message: positiveError })
  public audit_partner!: number

  @IsPositive({ message: positiveError })
  public business_development_partner!: number

  @IsPositive({ message: positiveError })
  public hr_manager!: number

  @IsPositive({ message: positiveError })
  public employee!: number

  constructor(leave?: Leave) {
    this.name = leave?.name || ''
    this.principal_partner = leave?.principal_partner || 0
    this.managing_partner = leave?.managing_partner || 0
    this.tax_partner = leave?.tax_partner || 0
    this.audit_partner = leave?.audit_partner || 0
    this.business_development_partner = leave?.business_development_partner || 0
    this.hr_manager = leave?.hr_manager || 0
    this.employee = leave?.employee || 0
  }
}
