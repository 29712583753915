import { SidebarLinks } from '../links'

export enum SettingsLinks {
  Leaves = '/settings/leaves',
  CreateLeave = '/settings/leaves/create',

  JobStages = '/settings/job-stages',

  JobTitles = '/settings/job-titles',
  CreateJobTitle = '/settings/job-titles/create',

  JobTypes = '/settings/job-types',
  CreateJobType = '/settings/job-types/create',

  Offices = '/settings/offices',
  CreateOffice = '/settings/offices/create',

  DisciplinaryCaseTypes = '/settings/disciplinary-case-types',
  CreateDisciplinaryCaseType = '/settings/disciplinary-case-types/create',

  EventCategories = '/settings/holiday-categories',
  CreateEventCategory = '/settings/holiday-categories/create',

  ApprovalLimits = '/settings/approval-limits',
}

export const settingsLinks = [
  { path: SidebarLinks.Settings, name: 'Roles', exact: true },
  { path: SettingsLinks.Leaves, name: 'Leaves', exact: false },
  { path: SettingsLinks.JobStages, name: 'Job Stages', exact: false },
  { path: SettingsLinks.JobTitles, name: 'Job Titles', exact: false },
  { path: SettingsLinks.JobTypes, name: 'Job Types', exact: false },
  {
    path: SettingsLinks.Offices,
    name: 'Offices',
    exact: false,
  },
  {
    path: SettingsLinks.DisciplinaryCaseTypes,
    name: 'Disciplinary Case Types',
    exact: false,
  },
  {
    path: SettingsLinks.EventCategories,
    name: 'Event Categories',
    exact: false,
  },
  {
    path: SettingsLinks.ApprovalLimits,
    name: 'Approval Limits',
    exact: false,
  },
]
