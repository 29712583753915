import React from 'react'
import { JobTitlesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { CreateNamedEntityForm } from '../common/CreateForm'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.JobTitles,
    name: 'Job Titles',
  },
  {
    path: '',
    name: 'Create Job Title',
  },
]

const View: React.FC<PageComponentProps> = ({ history }) => {
  return (
    <CreateNamedEntityForm
      breadcrumbs={breadcrumbs}
      service={JobTitlesService}
      history={history}
      singular="Job Title"
      redirect={SettingsLinks.JobTitles}
    />
  )
}

export const CreateJobTitle = Page(View, 'Create Job Title')
