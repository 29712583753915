import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Colors, Effects } from '../../style'

export const PrimaryButton = styled.button`
  box-shadow: ${Effects.DropShadow};
  background: blue;
  display: flex;
  height: 50px;
  align-items: center;
  background: ${Colors.Primary};
  border: 2px solid ${Colors.Primary};
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 85%;
  outline: none;
  transition: all 0.5s ease;

  &[disabled] {
    background: white;
    border-color: white;

    * {
      color: ${Colors.PrimaryDark};
    }
  }

  &:hover {
    background: ${Colors.PrimaryDark};
    border-color: ${Colors.PrimaryDark};
  }

  > * + * {
    margin-left: 20px;
  }

  * {
    color: white;
  }
`

export const BlackPrimaryButton = styled(PrimaryButton)`
  background: #181a1f;
  border: 2px solid #181a1f;

  &:hover {
    background: ${Colors.DarkGrey};
    border-color: ${Colors.DarkGrey};
  }

  * {
    color: white;
  }
`

export const PrimaryLink = styled(Link)`
  box-shadow: ${Effects.DropShadow};
  background: blue;
  display: flex;
  height: 50px;
  align-items: center;
  background: #172b42;
  border: 2px solid #172b42;
  border-radius: 25px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 85%;
  outline: none;
  transition: all 0.5s ease;

  &:hover {
    background: #101a25;
    border-color: #101a25;
  }

  > * + * {
    margin-left: 20px;
  }

  * {
    color: white;
  }
`
