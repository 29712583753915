import * as React from 'react'
import { AlertComponentProps } from 'react-alert'
import { Check, Info, X } from 'react-feather'
import { Toast } from './Toast'

export const AlertTemplate = ({
  options,
  // close,
  message,
}: AlertComponentProps) => {
  return (
    <Toast className={options.type} style={{ marginBottom: 20 }}>
      {options.type === 'success' ? (
        <Check />
      ) : options.type === 'error' ? (
        <X />
      ) : (
        <Info />
      )}
      <span>{message}</span>
    </Toast>
  )
}
