import queryString from 'query-string'
import React, { useMemo } from 'react'
import { Grid, List } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from '../../style'

const StyledLink = styled(Link)`
  background: ${Colors.LightGrey};
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  display: flex;
`

export const ListViewButton: React.FC = ({ ...props }) => {
  const location = useLocation()
  const isListView = useMemo(
    () => queryString.parse(location.search)?.view === 'list',
    [location.search]
  )

  return (
    <StyledLink to={isListView ? '?view=grid' : '?view=list'} {...props}>
      {isListView ? <List /> : <Grid />}
    </StyledLink>
  )
}
