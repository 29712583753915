import { Formik } from 'formik'
import { isMatch } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  ConfirmAlertConfig,
  generateConfirmAlert,
} from '../../../../component/alert'
import {
  CancelSlickButton,
  PrimarySlickButton,
  SlickButton,
} from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { FormTextInput } from '../../../../component/field'
import { Loader, PageBody } from '../../../../component/misc'
import { EditLeave } from '../../../../model/request/leaves'
import { Leave } from '../../../../model/response'
import { LeavesService } from '../../../../service'
import { RoleFieldNames, roleKeys, RoleNames } from '../../../../util/constants'
import { validateWithModel } from '../../../../util/validation'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.Leaves,
    name: 'Leaves',
  },
  {
    path: '',
    name: 'Leave',
  },
]

const View: React.FC<PageComponentProps> = ({
  params,
  history,
  loaderState,
}) => {
  const alert = useAlert()
  const [leave, setLeave] = useState<Leave>()
  const [editMode, setEditMode] = useState(false)

  const deleteLeave = async () => {
    loaderState.setLoading('Deleting leave')
    const response = await LeavesService.delete(params.id)
    loaderState.setDone()

    if (response.success) {
      alert.success('Leave deleted successfully')
      history.replace(SettingsLinks.Leaves)
      return
    }

    alert.error(response.message)
  }

  const getLeave = async () => {
    setLeave((await LeavesService.retrieve(params.id)).data)
  }

  useEffect(() => {
    getLeave()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Formik
      enableReinitialize
      validate={validateWithModel(EditLeave)}
      initialValues={new EditLeave(leave)}
      onSubmit={async (values) => {
        const {
          id: _1,
          created_at: _2,
          updated_at: _3,
          ...original
        } = leave as any

        if (!isMatch(original, values)) {
          loaderState.setLoading()
          const response = await LeavesService.update(params.id, values)
          loaderState.setDone()

          if (response.success) {
            alert.success(`Leave updated successfully`)
            setLeave({ ...(response.data as Leave), ...values })
            setEditMode(false)
            return
          }

          alert.error(response.message)
          return
        }

        alert.info('No changes were made')
        setEditMode(false)
      }}
    >
      {({ handleSubmit, handleChange, resetForm }) => (
        <Loader isLoading={!leave} message="Fetching leave information">
          <PageBody
            breadcrumbs={breadcrumbs}
            title="Leaves"
            subTitle={leave?.name}
            tagline="Active"
            actionArea={
              editMode ? (
                <>
                  <CancelSlickButton
                    type="button"
                    onClick={() => {
                      resetForm()
                      setEditMode(false)
                    }}
                  >
                    <span>Cancel</span>
                  </CancelSlickButton>
                  <PrimarySlickButton
                    type="submit"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    <span>Save</span>
                  </PrimarySlickButton>
                </>
              ) : (
                <>
                  <CancelSlickButton
                    type="button"
                    onClick={() => {
                      const config: ConfirmAlertConfig = {
                        header: 'Delete Leave',
                        message: 'Are you sure you want to delete the leave?',
                        confirmText: 'Yes, delete it!',
                        cancelText: 'No, cancel',
                      }

                      generateConfirmAlert(config, deleteLeave)
                    }}
                  >
                    <span>Delete</span>
                  </CancelSlickButton>
                  <SlickButton
                    onClick={() => {
                      setEditMode(true)
                    }}
                  >
                    <span>Edit</span>
                  </SlickButton>
                </>
              )
            }
          >
            <CreateEditForm onSubmit={handleSubmit}>
              <main>
                <section>
                  <FormTextInput
                    label="Name"
                    name="name"
                    placeholder="Leave name"
                    autoCapitalize="on"
                    spellCheck="on"
                    disabled={!editMode}
                    trim
                    handleChange={handleChange}
                  />
                </section>
                <section>
                  <span>Role-Specific Length (Days)</span>
                  {roleKeys.map((key, i) => (
                    <FormTextInput
                      label={(RoleNames as any)[key]}
                      name={(RoleFieldNames as any)[key]}
                      placeholder={`Number of days for ${
                        (RoleNames as any)[key]
                      }`}
                      type="number"
                      min={0}
                      disabled={!editMode}
                      key={i}
                    />
                  ))}
                </section>
              </main>
            </CreateEditForm>
          </PageBody>
        </Loader>
      )}
    </Formik>
  )
}

export const SingleLeave = Page(View, 'Leave')
