import { Formik } from 'formik'
import React from 'react'
import { useAlert } from 'react-alert'
import { Check } from 'react-feather'
import { Link } from 'react-router-dom'
import { PrimaryButton } from '../../component/button'
import { EntryForm } from '../../component/container'
import { FormTextInput } from '../../component/field'
import { ResetPassword as ResetPasswordModel } from '../../model/request/auth'
import { AuthService } from '../../service'
import { validateWithModel } from '../../util/validation'
import { Page, PageComponentProps } from '../Page'
import { CentralizerContainer } from './common'

const View: React.FC<PageComponentProps> = ({
  history,
  params,
  query,
  loaderState,
}) => {
  const alert = useAlert()
  const token: string = params.token
  const email: string = query.email as string

  return (
    <CentralizerContainer>
      <Formik
        initialValues={new ResetPasswordModel()}
        validate={validateWithModel(ResetPasswordModel)}
        onSubmit={async ({ password, password_confirmation }) => {
          loaderState.setLoading()
          const response = await AuthService.resetPassword(
            email,
            password,
            password_confirmation,
            token
          )
          loaderState.setDone()

          if (response.success) {
            history.replace('/login', {})
            alert.success('Password reset successfully')
            return
          }

          alert.error(response.message)
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <EntryForm onSubmit={handleSubmit}>
            <header>
              {/* <h1>Reset Password</h1> */}
              <img src="/img/logo.png" alt="ABDC Logo" />
              <h6>Please set a new password</h6>
            </header>
            <main>
              <FormTextInput
                label="Password"
                name="password"
                type="password"
                placeholder="Password"
                disabled={isSubmitting}
              />
              <FormTextInput
                label="Confirm Password"
                name="password_confirmation"
                type="password"
                placeholder="Confirm Password"
                disabled={isSubmitting}
              />
              <Link to={isSubmitting ? '#' : '/login'}>
                <small>Go to login</small>
              </Link>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Reset Password</span>
                <Check />
              </PrimaryButton>
            </footer>
          </EntryForm>
        )}
      </Formik>
    </CentralizerContainer>
  )
}

export const ResetPassword = Page(View, 'Reset Your Password')
