import {
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  MaxLength,
  MinLength,
} from 'class-validator'
import { RequestStates, RequestTypes } from '../../../util/constants'
import { IsOptionalOrEmpty } from '../../../util/validation'
import { Request } from '../../response'

export class EditRequest {
  @IsNotEmpty({ message: 'Enter a description' })
  @MinLength(3, { message: 'Description is too short' })
  public description!: string

  @IsNumber()
  public price!: number

  @IsPositive()
  public creator_id!: number

  @IsEnum(RequestTypes)
  public type!: RequestTypes

  @IsEnum(RequestStates)
  public status!: RequestStates

  @IsOptional()
  public actor_id?: number

  @IsOptionalOrEmpty()
  @MinLength(3, { message: 'Response is too short' })
  @MaxLength(140, { message: 'Response is too long' })
  public response!: string

  constructor(object?: Request) {
    this.description = object?.description || ''
    this.type = object?.type || RequestTypes.Individual
    this.price = object?.price || 0
    this.creator_id = object?.request_creator.id || 0
    this.actor_id = object?.request_actor?.id
    this.status = object?.status || RequestStates.Pending
    this.response = object?.response || ''
  }
}
