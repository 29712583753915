import React from 'react'
import AspectRatio from 'react-aspect-ratio'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Colors, Effects } from '../../style'

const StyledLink = styled(Link)`
  box-shadow: ${Effects.DropShadow};
  border: 2px solid ${Colors.LightGrey};
`

const BoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.PrimaryDark};
  background: white;
  color: #53625f;

  &.inactive {
    background: #f5f7f6;
    color: #869290;

    > header {
      filter: grayscale();
    }

    > main {
      background: #f5f7f6 !important;
    }
  }

  span {
    font-weight: bold;
  }

  > header {
    display: none;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  > main {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 30px;
    /* font-weight: bold; */
  }

  > main > * {
    flex-shrink: 0;
    overflow: hidden;
  }

  &.withImage > header {
    display: block;
    margin: 5px;
    height: 50%;
  }

  &.withImage > main {
    background: #ffffffe8;
    height: 50%;
  }

  > main > * + * {
    display: flex;
    margin-top: 10px;
  }

  section > * + * {
    margin-top: 10px;
  }

  svg {
    height: 15px;
    width: 15px;
    margin: 0 10px;
  }

  section {
    display: flex;
    flex-direction: column;
  }

  section + section {
    margin-top: 25px;
  }
`

const JumboBoxDiv = styled(BoxDiv)`
  display: grid;
  grid-template-columns: 1fr 40%;

  > main > *:not(:first-child) {
    background: #fbfbfb;
    flex: 1;
  }

  > main,
  > aside {
    justify-content: space-between;
    padding: 0;

    > * {
      padding: 30px;
    }
  }

  > aside {
    border-left: 1px solid ${Colors.LightGrey};
    background: #ffffff60;
    backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
  }

  > aside * + * {
    display: flex;
    margin-top: 10px;
  }
`

interface TinyCircleProps extends React.ComponentProps<'span'> {
  background: string
}

export const TinyCircle = styled.span`
  display: inline-block !important;
  margin-top: 0 !important;
  height: 30px;
  width: 30px;
  border-radius: 100%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props: TinyCircleProps) => props.background}),
    url(/svg/portrait.svg);
`

interface BoxProps extends React.Props<'div'> {
  ratio?: string | number
  background?: string
  to: string
  noLink?: boolean
  inactive?: boolean
}

export const Box: React.FC<BoxProps> = ({
  ratio,
  background,
  to,
  noLink,
  inactive,
  children,
}) => (
  <StyledLink
    to={to}
    onClick={(e) => {
      if (noLink) {
        e.preventDefault()
      }
    }}
  >
    <AspectRatio ratio={ratio || '5/3'}>
      <BoxDiv
        className={
          (background ? 'withImage ' : ' ') + (inactive ? 'inactive' : '')
        }
      >
        <header
          style={{
            backgroundImage: `url(${background}),
            url(/svg/portrait.svg)`,
          }}
        />
        <main>{children}</main>
      </BoxDiv>
    </AspectRatio>
  </StyledLink>
)

export const JumboBox: React.FC<BoxProps> = ({ to, children }) => (
  <StyledLink to={to}>
    <AspectRatio ratio="1/1">
      <JumboBoxDiv>{children}</JumboBoxDiv>
    </AspectRatio>
  </StyledLink>
)

interface ContainerProps extends React.Props<'div'> {
  large?: boolean
}

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${(props: ContainerProps) => (props.large ? 3 : 4)},
    1fr
  );
  grid-gap: 30px;

  & + * {
    margin-top: 40px !important;
  }

  @media screen and (max-width: 1500px) {
    grid-template-columns: repeat(
      ${(props: ContainerProps) => (props.large ? 2 : 3)},
      1fr
    );
    grid-gap: 20px;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(
      ${(props: ContainerProps) => (props.large ? 1 : 2)},
      1fr
    );
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
