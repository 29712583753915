import { Formik } from 'formik'
import { isMatch } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  ConfirmAlertConfig,
  generateConfirmAlert,
} from '../../../component/alert'
import {
  CancelSlickButton,
  PrimarySlickButton,
  SlickButton,
} from '../../../component/button'
import { CreateEditForm } from '../../../component/container'
import { DatePicker, FormTextInput } from '../../../component/field'
import { Loader, PageBody } from '../../../component/misc'
import { EditEvent } from '../../../model/request/events'
import { Event, NamedEntity } from '../../../model/response'
import { EventCategoriesService, EventsService } from '../../../service'
import { validateWithModel } from '../../../util/validation'
import { Page, PageComponentProps } from '../../Page'
import { SidebarLinks } from '../links'
import { EventsLinks } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Events, name: 'Events' },
  {
    path: '',
    name: 'Event',
  },
]

const View: React.FC<PageComponentProps> = ({
  params,
  history,
  loaderState,
}) => {
  const alert = useAlert()
  const [event, setEvent] = useState<Event>()
  const [editMode, setEditMode] = useState(false)
  const [categories, setCategories] = useState<NamedEntity[]>()

  const getDependencies = async () => {
    setCategories((await EventCategoriesService.list()).data)
  }

  useEffect(() => {
    getDependencies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteEvent = async () => {
    loaderState.setLoading('Deleting event')
    const response = await EventsService.delete(params.id)
    loaderState.setDone()

    if (response.success) {
      alert.success('Event deleted successfully')
      history.replace(EventsLinks.ManageEvents)
      return
    }

    alert.error(response.message)
  }

  const getEvent = async () => {
    setEvent((await EventsService.retrieve(params.id)).data)
  }

  useEffect(() => {
    getEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Formik
      enableReinitialize
      validate={validateWithModel(EditEvent)}
      initialValues={new EditEvent(event)}
      onSubmit={async (values) => {
        if (!isMatch(new EditEvent(event), values)) {
          loaderState.setLoading()
          const response = await EventsService.update(params.id, values)
          loaderState.setDone()

          if (response.success) {
            alert.success(`Event updated successfully`)
            setEvent({ ...(response.data as Event), ...values })
            setEditMode(false)
            return
          }

          alert.error(response.message)
          return
        }

        alert.info('No changes were made')
        setEditMode(false)
      }}
    >
      {({
        handleSubmit,
        handleChange,
        resetForm,
        isSubmitting,
        values,
        initialValues,
        setFieldValue,
      }) => (
        <Loader
          isLoading={!event || !categories?.length}
          message="Fetching event information"
        >
          <PageBody
            breadcrumbs={breadcrumbs}
            title="Events"
            subTitle={event?.name}
            tagline="Active"
            actionArea={
              editMode ? (
                <>
                  <CancelSlickButton
                    type="button"
                    onClick={() => {
                      resetForm()
                      setEditMode(false)
                    }}
                  >
                    <span>Cancel</span>
                  </CancelSlickButton>
                  <PrimarySlickButton
                    type="submit"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    <span>Save</span>
                  </PrimarySlickButton>
                </>
              ) : (
                <>
                  <CancelSlickButton
                    type="button"
                    onClick={() => {
                      const config: ConfirmAlertConfig = {
                        header: 'Delete Event',
                        message: 'Are you sure you want to delete the event?',
                        confirmText: 'Yes, delete it!',
                        cancelText: 'No, cancel',
                      }

                      generateConfirmAlert(config, deleteEvent)
                    }}
                  >
                    <span>Delete</span>
                  </CancelSlickButton>
                  <SlickButton
                    onClick={() => {
                      setEditMode(true)
                    }}
                  >
                    <span>Edit</span>
                  </SlickButton>
                </>
              )
            }
          >
            <CreateEditForm onSubmit={handleSubmit}>
              <main>
                <section>
                  <FormTextInput
                    label="Name"
                    name="name"
                    placeholder="Event name"
                    autoCapitalize="on"
                    spellCheck="on"
                    disabled={!editMode}
                    trim
                    handleChange={handleChange}
                  />
                  <FormTextInput
                    label="Event Category"
                    as="select"
                    name="holiday_category_id"
                    disabled={!editMode || isSubmitting}
                  >
                    <option value={0} disabled>
                      Select category
                    </option>
                    {categories?.map(({ id, name }, i) => (
                      <option value={id} key={i}>
                        {name}
                      </option>
                    ))}
                  </FormTextInput>
                  <DatePicker
                    label="Start of Event"
                    name="start_date"
                    onChange={(date: string) => {
                      if (moment(values.end_date).isBefore(date, 'd')) {
                        setFieldValue('end_date', date)
                      }
                    }}
                    disabled={!editMode || isSubmitting}
                  />
                  <DatePicker
                    label="End of Event"
                    name="end_date"
                    minDate={values.start_date}
                    disabled={!editMode || isSubmitting}
                  />
                </section>
              </main>
            </CreateEditForm>
          </PageBody>
        </Loader>
      )}
    </Formik>
  )
}

export const SingleEvent = Page(View, 'Event')
