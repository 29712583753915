import styled from 'styled-components'
import { Colors, Effects } from '../../style'

export const Toast = styled.div`
  width: 300px;
  padding: 20px;
  font-size: 80%;
  background: #ffffffdd;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${Colors.Header};
  box-shadow: ${Effects.DropShadow};

  > svg {
    flex-shrink: 0;
  }

  * + * {
    margin-left: 10px;
  }

  &.success {
    background: ${Colors.Green};
    color: white;
  }

  &.error {
    background: ${Colors.Red};
    color: white;
  }
`
