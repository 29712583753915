/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import Scrollbars from 'react-custom-scrollbars'
import { Check, CheckCircle, Circle, X } from 'react-feather'
import { Switch } from 'react-router-dom'
import styled from 'styled-components'
import { generateConfirmAlert } from '../../../component/alert'
import { BlackPrimaryButton, PrimaryButton } from '../../../component/button'
import { CreateEditForm } from '../../../component/container'
import { FormTextInput } from '../../../component/field'
import { Loader } from '../../../component/misc'
import { AuthContext } from '../../../context/auth.context'
import { LoaderContext } from '../../../context/loader.context'
import { PermissionsContext } from '../../../context/permissions.context'
import { CreateNamedEntity } from '../../../model/request/named-entities'
import { Leave, NamedEntity } from '../../../model/response'
import {
  DisciplinaryCaseTypesService,
  EventCategoriesService,
  JobTitlesService,
  JobTypesService,
  LeavesService,
  OfficesService,
} from '../../../service'
import { Colors } from '../../../style'
import { ApiResponse } from '../../../util/ApiClient'
import { Permissions } from '../../../util/constants'
import { useLoadingMessage } from '../../../util/hook'
import { validateWithModel } from '../../../util/validation'
import { FormSetup } from './FormSetup'

const Shadow = styled.div`
  position: absolute;
  background: #000000dd;
  width: 100vw;
  height: 100vh;
`

export const Div = styled(CreateEditForm)`
  position: fixed;
  top: 20px;
  left: 20px;
  height: calc(100vh - 40px);
  width: calc(100vw);
  max-width: 600px;
  background: white;
  padding: 30px;
  display: flex;
  border: 1px solid ${Colors.LightGrey};
  overflow: hidden;

  > nav {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  > main {
    flex: 1;
  }

  ${CreateEditForm} {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;

    > main {
      flex: 1;
      overflow: hidden;
      overflow-y: auto;
    }

    > footer {
      flex: unset;
    }
  }
`

const Bubble = styled.div`
  background: ${Colors.LightGrey};
  padding: 10px 15px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;

  &.disabled {
    background: #f8faf9;
    color: ${Colors.Primary};
  }

  > * + * {
    margin-left: 10px;
  }
`

const generateBubble = (name: string, array: any[]) => {
  return (
    <Bubble key={name} className={array.length ? 'disabled' : ''}>
      {array.length ? <CheckCircle /> : <Circle />}
      <span>{name}</span>
    </Bubble>
  )
}

export const StartupWizard = () => {
  const [leaves, setLeaves] = useState<Leave[]>([])
  const [jobTitles, setJobTitles] = useState<NamedEntity[]>([])
  const [jobTypes, setJobTypes] = useState<NamedEntity[]>([])
  const [offices, setOffices] = useState<NamedEntity[]>([])
  const [caseTypes, setCaseTypes] = useState<NamedEntity[]>([])
  const [eventCategories, setEventCategories] = useState<NamedEntity[]>([])

  const alert = useAlert()
  const { setLoading, setDone } = useContext(LoaderContext)
  const { hasPermission } = useContext(PermissionsContext)
  const { user, shouldSetUp, setShouldSetUp } = useContext(AuthContext)
  const { isLoading, updateLoader, message } = useLoadingMessage()

  const getComponents = async () => {
    updateLoader(true, 'Retrieving setup information. Please wait')

    const [
      leavesResponse,
      jobTitlesResponse,
      jobTypesResponse,
      officesResponse,
      caseTypesResponse,
      eventCategoriesResponse,
    ] = await Promise.all([
      LeavesService.list(),
      JobTitlesService.list(),
      JobTypesService.list(),
      OfficesService.list(),
      DisciplinaryCaseTypesService.list(),
      EventCategoriesService.list(),
    ])

    setLeaves(leavesResponse.data || leaves)
    setJobTitles(jobTitlesResponse.data || jobTitles)
    setJobTypes(jobTypesResponse.data || jobTypes)
    setOffices(officesResponse.data || offices)
    setCaseTypes(caseTypesResponse.data || caseTypes)
    setEventCategories(eventCategoriesResponse.data || eventCategories)

    updateLoader(false)
  }

  const navigation = useMemo(() => {
    return [
      { name: 'Leaves', data: leaves },
      { name: 'Job Titles', data: jobTitles },
      { name: 'Job Types', data: jobTypes },
      { name: 'Offices', data: offices },
      { name: 'Case Types', data: caseTypes },
      { name: 'Event Categories', data: eventCategories },
    ].map(({ name, data }) => generateBubble(name, data))
  }, [user, leaves, jobTitles, jobTypes, offices, caseTypes, eventCategories])

  const forms = useMemo(() => {
    return FormSetup(
      leaves,
      setLeaves,
      jobTitles,
      setJobTitles,
      jobTypes,
      setJobTypes,
      offices,
      setOffices,
      caseTypes,
      setCaseTypes,
      eventCategories,
      setEventCategories
    )
      .filter(({ data }) => !data.length)
      .map(
        ({ name, fields, schema: Schema, service, data, updater, example }) => {
          return (
            <Formik
              initialValues={new (Schema || CreateNamedEntity)()}
              validate={validateWithModel(Schema || CreateNamedEntity)}
              onSubmit={async (values, { setErrors }) => {
                setLoading()
                const response = (await (service as any).create(
                  values
                )) as ApiResponse<any>
                setDone()

                if (response.data) {
                  updater(data.concat([response.data]))
                  alert.success(response.message)
                  return
                }

                if (response.errors) {
                  alert.error(
                    'There was a problem with your data. Check the form again'
                  )
                  setErrors(response.errors as any)
                  return
                }

                alert.error(response.message)
              }}
              key={name}
            >
              {({ handleSubmit, isSubmitting }) => (
                <CreateEditForm onSubmit={handleSubmit}>
                  <header>
                    <h2>Create {name}</h2>
                  </header>
                  <main>
                    <section>
                      {fields ? (
                        fields.map(
                          (
                            { name: fieldName, label, placeholder, type },
                            i
                          ) => (
                            <FormTextInput
                              label={label}
                              name={fieldName}
                              placeholder={placeholder}
                              key={i}
                              {...(type === 'number' ? { min: 0, type } : {})}
                            />
                          )
                        )
                      ) : (
                        <FormTextInput
                          label={name}
                          name="name"
                          placeholder={`Example: ${example}`}
                        />
                      )}
                    </section>
                  </main>
                  <footer>
                    <PrimaryButton type="submit" disabled={isSubmitting}>
                      <span>Create</span>
                      <Check />
                    </PrimaryButton>
                    <BlackPrimaryButton
                      type="reset"
                      disabled={isSubmitting}
                      onClick={() => {
                        const close = () => {
                          setShouldSetUp(false)
                          sessionStorage.removeItem('setup')
                        }

                        generateConfirmAlert(
                          {
                            header: 'Close wizard',
                            message:
                              'Are you sure you want to close the setup wizard',
                            confirmText: 'Yes, close',
                            cancelText: 'No, continue',
                          },
                          close
                        )
                      }}
                    >
                      <span>Cancel</span>
                      <X />
                    </BlackPrimaryButton>
                  </footer>
                </CreateEditForm>
              )}
            </Formik>
          )
        }
      )
  }, [leaves, jobTitles, jobTypes, offices, caseTypes, eventCategories])

  useEffect(() => {
    getComponents()
  }, [user])

  useEffect(() => {
    if (
      leaves.length &&
      jobTitles.length &&
      jobTypes.length &&
      offices.length &&
      caseTypes.length &&
      eventCategories.length &&
      shouldSetUp
    ) {
      alert.info('System setup is complete')
      setShouldSetUp(false)
      sessionStorage.removeItem('setup')
    }
  }, [user, leaves, jobTitles, jobTypes, offices, caseTypes, eventCategories])

  return shouldSetUp && hasPermission(Permissions.SystemConfiguration) ? (
    <>
      <Shadow />
      <Div as="div">
        <Loader isLoading={isLoading} message={message}>
          <header>
            <h1>Welcome to the Startup Wizard</h1>
            <h6>Fill in the information below to set your system up</h6>
            <small>
              You can modify any of these later in the system settings
            </small>
          </header>
          <nav>{navigation}</nav>
          <main>
            <Scrollbars>
              <Switch>{forms}</Switch>
            </Scrollbars>
          </main>
        </Loader>
      </Div>
    </>
  ) : null
}
