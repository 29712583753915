import React from 'react'
import { PageBody } from '../../../component/misc'
import { Permissions } from '../../../util/constants'
import { Page, PageComponentProps } from '../../Page'
import { SidebarLinks } from '../links'
import { links } from './links'
import { OfficeStructurePond } from './OfficeStructurePond'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Organization, name: 'Organization' },
  { path: '', name: 'Office Structure' },
]

const View: React.FC<PageComponentProps> = ({ permissionsState }) => {
  return (
    <PageBody
      title="Office Structure"
      tagline="View / change the office structure"
      breadcrumbs={breadcrumbs}
      pageLinks={links}
    >
      {permissionsState.hasPermission(Permissions.AdminViewAccess) ? (
        <span>Click on the image below to upload a new structure</span>
      ) : null}
      <OfficeStructurePond />
    </PageBody>
  )
}

export const OfficeStructure = Page(View, 'Office Structure')
