import {
  ValidateIf,
  validateSync,
  ValidationError,
  ValidationOptions,
} from 'class-validator'
import { set } from 'lodash'
import React from 'react'

export const NameValidationPattern = /^[A-Za-z\-'\s]+[A-Za-z]$/i
export const EmailValidationPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@abdc.com.ng$/
export const DateValidationPattern = /^\d\d\d\d-\d\d-\d\d$/
export const PhoneRegion = 'NG'

export const formatCurrency = (value: number | string) => {
  return new Intl.NumberFormat('en-US').format(+value)
}

export enum Genders {
  Male = 'Male',
  Female = 'Female',
}

export const mapErrors = (errors: ValidationError[]) => {
  const errorMap: { [property: string]: any } = {}

  errors.forEach((error) => {
    set(errorMap, error.property, Object.values(error.constraints)[0])
  })

  return errorMap
}

export const runValidation = (Model: any, prefix?: string) => (values: any) => {
  if (!(values instanceof Model)) {
    values = Object.assign(new Model(), values)
  }

  if (Model.transform) {
    Model.transform(values)
  }

  const errors = validateSync(values)

  if (Model.extraValidation) {
    errors.push(...Model.extraValidation(values))
  }

  return prefix
    ? errors.map(({ property, ...error }) => ({
        property: `${prefix}.${property}`,
        ...error,
      }))
    : errors
}

export const validateWithModel = (Model: any) => (values: any) => {
  const errors = runValidation(Model)(values)
  const map = mapErrors(errors as ValidationError[])
  return map
}

export const generateValidationMessage = (
  error: string | undefined,
  touched: boolean,
  info?: boolean
) => {
  return (!info ? touched : true) && error ? (
    <span className="error">{error}</span>
  ) : null
}

export function IsOptionalOrEmpty(validationOptions?: ValidationOptions) {
  return ValidateIf((obj, value) => {
    return value !== null && value !== undefined && value !== ''
  }, validationOptions)
}

export * from './dates'
