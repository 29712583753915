import { IsPhoneNumber, IsString, Matches, MinLength } from 'class-validator'
import { NameValidationPattern, PhoneRegion } from '../../../util/validation'

export class SetNextOfKin {
  @Matches(NameValidationPattern, { message: 'Name is invalid' })
  public name!: string

  @Matches(NameValidationPattern, { message: 'Relationship is invalid' })
  public relationship!: string

  @IsString({ message: 'Address is invalid' })
  @MinLength(3, { message: 'Address is too short' })
  public address!: string

  @IsPhoneNumber(PhoneRegion, { message: 'Invalid phone number' })
  public phone_number!: string

  constructor() {
    this.name = ''
    this.relationship = ''
    this.address = ''
    this.phone_number = ''
  }
}
