import React from 'react'
import { AssetCategoriesService } from '../../../../service'
import { Page } from '../../../Page'
import { SidebarLinks } from '../../links'
import { ListNamedEntitiesPage } from '../../settings/common/ListPage'
import { links, OrganizationLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Organization, name: 'Organization' },
  {
    path: '',
    name: 'Asset Categories',
  },
]

const View = () => (
  <ListNamedEntitiesPage
    breadcrumbs={breadcrumbs}
    service={AssetCategoriesService}
    singular="Asset Category"
    plural="Asset Categories"
    listLink={OrganizationLinks.AssetCategories}
    createLink={OrganizationLinks.CreateAssetCategory}
    links={links}
    noFilter
  />
)

export const AssetCategories = Page(View, 'Asset Categories')
