import React from 'react'
import { PageBody } from '../../../component/misc'
import { Page } from '../../Page'
import { SidebarLinks } from '../links'
import { settingsLinks as links } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  { path: '', name: 'Job Stages' },
]

const stages = [
  'Planning',
  'Deployment',
  'Initial Draft Report',
  'Initial Draft Review',
  'Final Draft',
  'Draft Presentation',
  'Draft Approval',
]

const View = () => {
  return (
    <PageBody
      title="Job Stages"
      tagline="View the job stages on the platform"
      breadcrumbs={breadcrumbs}
      pageLinks={links}
    >
      <ul>
        {stages.map((stage, i) => (
          <li key={i}>
            <span>{stage}</span>
          </li>
        ))}
      </ul>
    </PageBody>
  )
}

export const JobStages = Page(View, 'Job Stages')
