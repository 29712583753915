import { CreateFreelancer, EditFreelancer } from '../model/request/freelancers'
import { Freelancer } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class FreelancersService {
  public static async create(details: CreateFreelancer) {
    return ApiClient.post<CreateFreelancer, Freelancer>(
      'freelancer/account/create',
      details,
      CreateFreelancer
    )
  }

  public static async list() {
    return ApiClient.get<Freelancer[]>('freelancer/account')
  }

  public static async retrieve(slug: string) {
    return ApiClient.getOne<Freelancer>('freelancer/account/show', slug)
  }

  public static async update(slug: string, details: EditFreelancer) {
    return ApiClient.patch<EditFreelancer, Freelancer>(
      'freelancer/account/update',
      slug,
      details,
      EditFreelancer
    )
  }
}
