import moment from 'moment'
import React from 'react'
import AspectRatio from 'react-aspect-ratio'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Container, CreateEditForm } from '../../../component/container'
import { PageBody } from '../../../component/misc'
import { Employee } from '../../../model/response'
import { Colors, Effects } from '../../../style'
import { Page, PageComponentProps } from '../../Page'
import { sections } from './sections'

const ActionBox = styled(Link)`
  box-shadow: ${Effects.DropShadow};
  border: 2px solid #232b29;
  display: flex;
  flex-direction: column;
  height: 300px;
  transition: all 0.5s ease;
  background: url(/img/backdrop.svg), linear-gradient(135deg, #192926, #1d3838);
  background-position: center;
  background-size: cover;
  color: #c9cfcb;
  position: relative;
  cursor: default;

  ::after {
    display: block;
    content: '';
    position: absolute;
    top: calc(100% - 10px);
    background: ${Colors.Primary};
    height: 10px;
    left: 0;
    width: 0;
    transition: width 0.5s ease;
  }

  :hover {
    box-shadow: 0px 5px 15px 10px rgba(0, 0, 0, 0.05);

    ::after {
      width: 100%;
    }
  }

  > header {
    font-weight: bold;
    font-size: 130%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 30px 1fr;
    padding: 30px;
    transition: all 0.5s ease;

    * {
      color: ${Colors.Primary};
      transition: color 0.5s ease;
    }

    svg {
      height: 30px;
      width: 30px;
    }

    > section {
      display: flex;
      align-items: center;
    }
  }

  > main {
    padding: 0 30px 30px;
    flex: 1;
    font-size: 90%;
    line-height: 20px;
  }
`

const breadcrumbs = [
  {
    path: '/',
    name: 'Dashboard',
  },
]

const View: React.FC<PageComponentProps> = ({
  authState: { user },
  permissionsState: { hasPermission },
}) => {
  user = user as Employee

  return (
    <PageBody breadcrumbs={breadcrumbs}>
      <h1>Welcome, {user.first_name}</h1>
      <CreateEditForm as="div">
        <header>
          <h6>
            Your last login was [ <b>{moment(user.last_login_at).fromNow()}</b>{' '}
            ] from address [ <b>{user.last_login_ip}</b> ]
          </h6>
          <hr />
          <small>{user.role}</small>
        </header>
      </CreateEditForm>
      {sections(user).map(
        ({ permission: sectionPermission, name: sectionName, actions }, i) => {
          if (
            sectionPermission === undefined ||
            hasPermission(sectionPermission)
          ) {
            return (
              <React.Fragment key={i}>
                <h2>{sectionName}</h2>
                <Container>
                  {actions
                    .filter(
                      ({ permission }) =>
                        permission === undefined || hasPermission(permission)
                    )
                    .map(({ icon: Icon, name, description, path }, j) => (
                      <AspectRatio ratio="1/1" key={j}>
                        <ActionBox to={path}>
                          <header>
                            <section>
                              <Icon />
                            </section>
                            <section>{name}</section>
                          </header>
                          <main>
                            <span>{description}</span>
                          </main>
                        </ActionBox>
                      </AspectRatio>
                    ))}
                </Container>
              </React.Fragment>
            )
          }

          return null
        }
      )}
    </PageBody>
  )
}

export const Dashboard = Page(View, 'Dashboard')
