import React from 'react'
import { JobTypesService } from '../../../../service'
import { Page } from '../../../Page'
import { SidebarLinks } from '../../links'
import { ListNamedEntitiesPage } from '../common/ListPage'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: '',
    name: 'Job Types',
  },
]

const View = () => (
  <ListNamedEntitiesPage
    breadcrumbs={breadcrumbs}
    service={JobTypesService}
    singular="Job Type"
    plural="Job Types"
    listLink={SettingsLinks.JobTypes}
    createLink={SettingsLinks.CreateJobType}
  />
)

export const JobTypes = Page(View, 'Job Types')
