import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { InternalRoute, SideBar, SpecialRoute } from '../../component/misc'
import { Dashboard } from '../../page/main/dashboard/Dashboard'
import { CreateEmployee } from '../../page/main/employees/CreateEmployee'
import { CreateDisciplinaryCase } from '../../page/main/employees/disciplinary-cases/CreateDisciplinaryCase'
import { DisciplinaryCases } from '../../page/main/employees/disciplinary-cases/DisciplinaryCases'
import { Employees } from '../../page/main/employees/Employees'
import { CreateFreelancer } from '../../page/main/employees/freelancers/CreateFreelancer'
import { Freelancers } from '../../page/main/employees/freelancers/Freelancers'
import { SingleFreelancer } from '../../page/main/employees/freelancers/SingleFreelancer'
import { EmployeesLinks } from '../../page/main/employees/links'
import { SingleEmployee } from '../../page/main/employees/SingleEmployee'
import { CreateEvent } from '../../page/main/events/CreateEvent'
import { Events } from '../../page/main/events/Events'
import { EventsLinks } from '../../page/main/events/links'
import { ManageEvents } from '../../page/main/events/ManageEvents'
import { SingleEvent } from '../../page/main/events/SingleEvent'
import { Clients } from '../../page/main/jobs/clients/Clients'
import { CreateClient } from '../../page/main/jobs/clients/CreateClient'
import { SingleClient } from '../../page/main/jobs/clients/SingleClient'
import { CreateJob } from '../../page/main/jobs/CreateJob'
import { FinancialRequests } from '../../page/main/jobs/financial-requests/FinancialRequests'
import { Jobs } from '../../page/main/jobs/Jobs'
import { JobsLinks } from '../../page/main/jobs/links'
import { ProgressBoard } from '../../page/main/jobs/ProgressBoard'
import { SingleJob } from '../../page/main/jobs/SingleJob'
import { SidebarLinks } from '../../page/main/links'
import { AssetCategories } from '../../page/main/organization/asset-categories/AssetCategories'
import { CreateAssetCategory } from '../../page/main/organization/asset-categories/CreateAssetCategory'
import { SingleAssetCategory } from '../../page/main/organization/asset-categories/SingleAssetCatgory'
import { Assets } from '../../page/main/organization/assets/Assets'
import { CreateAsset } from '../../page/main/organization/assets/CreateAsset'
import { OrganizationLinks } from '../../page/main/organization/links'
import { OfficeStructure } from '../../page/main/organization/OfficeStructure'
import { CreateLeaveRequest } from '../../page/main/requests/CreateLeaveRequest'
import { CreateRequest } from '../../page/main/requests/CreateRequest'
import { LeaveRequests } from '../../page/main/requests/LeaveRequests'
import { RequestsLinks } from '../../page/main/requests/links'
import { Requests } from '../../page/main/requests/Requests'
import { StartupWizard } from '../../page/main/startup/Startup'
import { Permissions } from '../../util/constants'
import { SettingsRoutes } from './settings'

export const MainRoutes = () => (
  <InternalRoute>
    <SideBar />
    {/* Employee Management */}
    <Switch>
      <Route exact path={EmployeesLinks.Freelancers} component={Freelancers} />
      <SpecialRoute
        exact
        path={EmployeesLinks.CreateFreelancer}
        isPrivate
        permission={Permissions.AdminViewAccess}
        component={CreateFreelancer}
      />
      <SpecialRoute
        exact
        path={EmployeesLinks.DisciplinaryCases}
        isPrivate
        component={DisciplinaryCases}
      />
      <SpecialRoute
        exact
        path={EmployeesLinks.CreateDisciplinaryCase}
        isPrivate
        permission={Permissions.AdminViewAccess}
        component={CreateDisciplinaryCase}
      />
      <SpecialRoute
        exact
        path={`${EmployeesLinks.Freelancers}/:slug`}
        isPrivate
        permission={Permissions.AdminViewAccess}
        component={SingleFreelancer}
      />
      <Route exact path={SidebarLinks.Employees} component={Employees} />
      <SpecialRoute
        exact
        path={EmployeesLinks.CreateEmployee}
        isPrivate
        permission={Permissions.AdminViewAccess}
        component={CreateEmployee}
      />
      <Route
        exact
        path={`${SidebarLinks.Employees}/:slug`}
        component={SingleEmployee}
      />
      {/* Organization */}
      <Route
        exact
        path={SidebarLinks.Organization}
        component={OfficeStructure}
      />
      <SpecialRoute
        path={OrganizationLinks.AssetCategories}
        isPrivate
        permission={Permissions.AdminViewAccess}
      >
        <Switch>
          <Route
            exact
            path={OrganizationLinks.AssetCategories}
            component={AssetCategories}
          />
          <Route
            exact
            path={OrganizationLinks.CreateAssetCategory}
            component={CreateAssetCategory}
          />
          <Route
            exact
            path={`${OrganizationLinks.AssetCategories}/:id`}
            component={SingleAssetCategory}
          />
        </Switch>
      </SpecialRoute>
      <SpecialRoute path={OrganizationLinks.Assets} isPrivate>
        <Switch>
          <Route exact path={OrganizationLinks.Assets} component={Assets} />
          <Route
            exact
            path={OrganizationLinks.CreateAsset}
            component={CreateAsset}
          />
        </Switch>
      </SpecialRoute>
      {/* Jobs */}
      <InternalRoute
        exact
        path={JobsLinks.FinancialRequests}
        component={FinancialRequests}
      />
      <InternalRoute
        exact
        path={JobsLinks.Clients}
        component={Clients}
        permission={Permissions.AdminViewAccess}
      />
      <InternalRoute
        exact
        path={JobsLinks.CreateClient}
        component={CreateClient}
        permission={Permissions.JobCreation}
      />
      <InternalRoute
        exact
        path={`${JobsLinks.Clients}/:id`}
        component={SingleClient}
        permission={Permissions.JobCreation}
      />
      <InternalRoute exact path={SidebarLinks.Jobs} component={Jobs} />
      <InternalRoute
        exact
        path={JobsLinks.CreateJob}
        component={CreateJob}
        permission={Permissions.JobCreation}
      />
      <Route exact path={JobsLinks.ProgressBoard} component={ProgressBoard} />
      <Route path={`${SidebarLinks.Jobs}/:id`} component={SingleJob} />
      {/* Requests */}
      <Route
        exact
        path={RequestsLinks.CreateRequest}
        component={CreateRequest}
      />
      <Route exact path={SidebarLinks.Requests} component={Requests} />
      <Route
        exact
        path={RequestsLinks.CreateLeaveRequest}
        component={CreateLeaveRequest}
      />
      <Route
        exact
        path={RequestsLinks.LeaveRequests}
        component={LeaveRequests}
      />
      {/* Events */}
      <Route exact path={SidebarLinks.Events} component={Events} />
      <SpecialRoute
        path={EventsLinks.CreateEvent}
        isPrivate
        permission={Permissions.AdminViewAccess}
        component={CreateEvent}
      />
      <SpecialRoute
        path={EventsLinks.ManageEvents}
        isPrivate
        permission={Permissions.AdminViewAccess}
        component={ManageEvents}
      />
      <SpecialRoute
        path={`${SidebarLinks.Events}/:id`}
        isPrivate
        permission={Permissions.AdminViewAccess}
        component={SingleEvent}
      />
      {/* Dashboard */}
      <Route exact path="/" component={Dashboard} />
      <SettingsRoutes />
    </Switch>
    <InternalRoute component={StartupWizard} />
  </InternalRoute>
)
