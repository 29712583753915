import React from 'react'
import { OfficesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { CreateNamedEntityForm } from '../common/CreateForm'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.Offices,
    name: 'Offices',
  },
  {
    path: '',
    name: 'Create Office',
  },
]

const View: React.FC<PageComponentProps> = ({ history }) => {
  return (
    <CreateNamedEntityForm
      breadcrumbs={breadcrumbs}
      service={OfficesService}
      history={history}
      singular="Office"
      redirect={SettingsLinks.Offices}
    />
  )
}

export const CreateOffice = Page(View, 'Create Office')
