import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import 'normalize.css'
import 'react-aspect-ratio/aspect-ratio.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { createGlobalStyle } from 'styled-components'
import { RequestStates } from '../util/constants'
import { Colors } from './colors.style'
import './zoom-fix.css'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Circular Std', sans-serif;
    padding: 0;
    min-width: 500px;
    box-sizing: border-box !important;
    justify-content: center;
  }

  html {
    height: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  a:active {
    color: initial;
  }

  * {
    outline: none !important;
    box-sizing: border-box !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: bottom;
  }

  #root {
    font-family: 'Circular Std', sans-serif;
    color: #4c504e;
    background: linear-gradient(45deg, #151715, #13171b);
    display: flex;
    height: 100vh;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  h1 {
    size: 30px;
    color: ${Colors.Header};
  }

  h6 {
    font-size: 85%;
    font-family: inherit;
    font-weight: normal;
  }

  hr {
    border: 1px solid ${Colors.LightGrey};
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Colors.LightGrey};
    border: 2px solid ${Colors.LightGrey};
    outline: 0;
    font-size: 90%;
    color: #606276;
    appearance: none;
    padding: 10px 20px;
    position: relative;
  }

  small {
    color: ${Colors.SmallLink};
    font-size: 75%;
  }

  *.${RequestStates.Approved.toLowerCase()} {
    color: ${Colors.Green};
  }

  *.${RequestStates.Rejected.toLowerCase()} {
    color: ${Colors.Red};
  }

  .filepond--wrapper {
    height: 100%;
    width: 100%;
  }

  .filepond--file {
    height: 100%;
  }

  .filepond--wrapper * {
    font-size: inherit;
    font-family: inherit;
    background: transparent;
  }

  .filepond--root,
  .filepond--root .filepond--drop-label {
    height: 100%;
    background: transparent;
  }

  .filepond--drop-label {
    height: 100%;
    width: 100%;
    color: transparent;
  }

  .modal__overlay, .react-confirm-alert-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.75) !important;
  }

  .modal {
    width: 500px;
    background: white;
    border-radius: 5px;
    padding: 40px;
    outline: none;
    border: none;
    z-index: 5;
    max-height: 100vh;
    overflow: auto;
  }
`
