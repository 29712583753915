import {
  FirstLogin,
  ForgotPassword,
  Login,
  ResetPasswordRequest,
} from '../model/request/auth'
import { Employee } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class AuthService {
  public static async getAuth() {
    return ApiClient.get<Employee>('auth/user')
  }

  public static async firstLogin(email: string, password: string) {
    return ApiClient.post<FirstLogin>(
      'auth/first-login',
      { email, password },
      FirstLogin
    )
  }

  public static async login(email: string, password: string) {
    return ApiClient.post<
      Login,
      { access_token: string; expires_at: string; user: Employee }
    >('auth/login', { email, password }, Login)
  }

  public static async sendResetEmail(email: string) {
    return ApiClient.post<ForgotPassword>(
      'password/email',
      { email },
      ForgotPassword
    )
  }

  public static async resetPassword(
    email: string,
    password: string,
    password_confirmation: string,
    token: string
  ) {
    return ApiClient.post<ResetPasswordRequest>(
      'password/reset',
      {
        email,
        password,
        password_confirmation,
        token,
      },
      ResetPasswordRequest
    )
  }

  public static async logout() {
    const response = await ApiClient.get('auth/logout')

    if (response.success) {
      ApiClient.unsetToken()
    }
  }
}
