import { SidebarLinks } from '../links'

export enum RequestsLinks {
  CreateRequest = '/requests/create',
  LeaveRequests = '/requests/leave-requests',
  CreateLeaveRequest = '/requests/leave-requests/create',
}

export const requestsLinks = [
  {
    path: SidebarLinks.Requests,
    name: 'Requests',
    exact: true,
  },
  { path: RequestsLinks.LeaveRequests, name: 'Leave Requests', exact: true },
]
