import { Formik } from 'formik'
import { History } from 'history'
import { isEqual } from 'lodash'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { PrimaryButton, PrimaryLink } from '../../../component/button'
import { Container, CreateEditForm } from '../../../component/container'
import { CheckableBox } from '../../../component/field'
import { PageBody } from '../../../component/misc'
import { AuthContext } from '../../../context/auth.context'
import { LoaderState } from '../../../context/loader.context'
import { SetTeam } from '../../../model/request/jobs'
import { Employee, Job } from '../../../model/response'
import { EmployeesService, JobsService } from '../../../service'
import { validateWithModel } from '../../../util/validation'
import { SidebarLinks } from '../links'

interface SelectTeamProps {
  history: History
  params: Record<string, string>
  loaderState: LoaderState
  job: Job
  setJob: React.Dispatch<React.SetStateAction<Job | undefined>>
}

export const SelectTeam: React.FC<SelectTeamProps> = ({
  history,
  params,
  loaderState: { setLoading, setDone },
  job,
  setJob,
}) => {
  const alert = useAlert()
  const [employees, setEmployees] = useState<Employee[]>()
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(AuthContext)

  const breadcrumbs = useMemo(
    () => [
      { path: SidebarLinks.Index, name: 'Dashboard' },
      {
        path: SidebarLinks.Jobs,
        name: 'Jobs',
      },
      {
        path: `${SidebarLinks.Jobs}/${params.id}`,
        name: job?.client.name,
      },
      {
        path: '',
        name: 'Select Team',
      },
    ],
    [params.id, job]
  )

  const getEmployees = async () => {
    const response = await EmployeesService.list()

    if (response.data) {
      setEmployees(response.data)
    }
  }

  const getDependencies = async () => {
    await getEmployees()
    setIsLoading(false)
  }

  useEffect(() => {
    getDependencies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, user])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      loadingMessage="Fetching employees"
    >
      <Formik
        enableReinitialize
        validate={validateWithModel(SetTeam)}
        initialValues={new SetTeam(job)}
        onSubmit={async (values) => {
          if (!isEqual(new SetTeam(job), values)) {
            setLoading(`Updating team`)
            const response = await JobsService.setTeam(job?.id as any, values)
            setDone()

            if (response.data) {
              alert.success('Team successfully updated')
              setJob({ ...(job as Job), team: response.data })
              history.push(`${SidebarLinks.Jobs}/${params.id}`)
              return
            }

            if (response.errors) {
              Object.values(response.errors).forEach((error: string) => {
                alert.error(error)
              })
            } else {
              alert.error(response.message)
            }

            return
          }

          alert.info('No changes were made')
          history.push(`${SidebarLinks.Jobs}/${params.id}`)
        }}
      >
        {({ handleSubmit, errors, isSubmitting }) => (
          <>
            <header>
              <h2>Set Team</h2>
              <h6>
                {errors.member_ids
                  ? errors.member_ids
                  : 'Click to select team members'}
              </h6>
            </header>
            <form onSubmit={handleSubmit}>
              <Container>
                {(job?.accepted && job.team
                  ? job.team?.members
                  : employees?.filter(
                      ({ slug, active }) =>
                        slug !== job?.engagement_partner.slug &&
                        slug !== job?.engagement_manager.slug &&
                        active
                    )
                )?.map(
                  ({ id, slug, first_name, last_name, office, job_title }) => (
                    <CheckableBox
                      name="member_ids"
                      value={id}
                      key={id}
                      selected={job?.accepted}
                    >
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_STORAGE}/profile/${slug}), url(/svg/portrait.svg)`,
                        }}
                      />
                      <section>
                        <b>
                          {first_name} {last_name}
                        </b>
                        {job?.accepted ? null : (
                          <>
                            <span>{office.name}</span>
                            <h6>{job_title.name}</h6>
                          </>
                        )}
                      </section>
                    </CheckableBox>
                  )
                )}
              </Container>
              <CreateEditForm as="div">
                <footer>
                  <PrimaryButton type="submit">
                    <span>{job?.team ? 'Update' : 'Set'} Team</span>
                    <Check />
                  </PrimaryButton>
                  <PrimaryLink to={`${SidebarLinks.Jobs}/${params.id}`}>
                    <span>Cancel</span>
                    <X />
                  </PrimaryLink>
                </footer>
              </CreateEditForm>
            </form>
          </>
        )}
      </Formik>
    </PageBody>
  )
}
