import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ArrowRight } from 'react-feather'
import { SlickLink } from '../../../component/button'
import { Container, JumboBox, TinyCircle } from '../../../component/container'
import { PageBody } from '../../../component/misc'
import { Job } from '../../../model/response'
import { JobsService } from '../../../service'
import { Permissions } from '../../../util/constants'
import { formatCurrency } from '../../../util/validation'
import { Page, PageComponentProps } from '../../Page'
import { NotCreated } from '../common'
import { SidebarLinks } from '../links'
import { JobsLinks, links } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Jobs, name: 'Jobs' },
]

const View: React.FC<PageComponentProps> = ({
  authState,
  permissionsState,
}) => {
  const [jobs, setJobs] = useState<Job[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const getJobs = async () => {
    const response = await JobsService.list()
    setIsLoading(false)

    if (response.data) {
      setJobs(response.data)
    }
  }

  useEffect(() => {
    getJobs()
  }, [])

  return (
    
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Jobs"
      pageLinks={links}
      tagline="View / manage the jobs on the platform"
      isLoading={isLoading}
      loadingMessage="Fetching jobs"
      actionArea={
        permissionsState.hasPermission(Permissions.JobCreation) ? (
          <SlickLink to={JobsLinks.CreateJob}>
            <span>Create Job</span>
          </SlickLink>
        ) : null
      }
    >
      {jobs.length ? (
        <Container large>
          {jobs.map(
            ({
              id,
              client,
              stage,
              engagement_partner,
              engagement_manager,
              category,
              job_type,
              team,
              freelancer,
              amount,
              accepted,
              start_date,
              end_date,
            }) => (
              <JumboBox
                to={
                  permissionsState.hasPermission(Permissions.AdminViewAccess) ||
                  authState.user?.slug === engagement_manager.slug ||
                  authState.user?.slug === engagement_partner.slug ||
                  (accepted &&
                    team?.members.some(
                      (member) => authState.user?.slug === member.slug
                    ))
                    ? `${SidebarLinks.Jobs}/${id}`
                    : '?'
                }
                key={id}
                ratio="1/1"
              >
                <main>
                  <section>
                    <section>
                      <small>Client</small>
                      <h2>{client.name}</h2>
                    </section>
                    <section>
                      <small>Job Type</small>
                      <h6>
                        {job_type.name.toUpperCase()}
                      </h6>
                    </section>
                    <section>
                      <small>Engagement Partner</small>
                      <h6>
                        {engagement_partner.first_name}{' '}
                        {engagement_partner.last_name}
                      </h6>
                    </section>
                    <section>
                      <small>Engagement Manager</small>
                      <h6>
                        {engagement_manager.first_name}{' '}
                        {engagement_manager.last_name}
                      </h6>
                    </section>
                  </section>
                  {accepted ? (
                    <>
                      <section>
                        <small>Team</small>
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(5, 30px)',
                            gridGap: 10,
                          }}
                        >
                          {team?.members.map((member) => (
                            <TinyCircle
                              key={member.id}
                              title={`${member.first_name} ${member.last_name}`}
                              background={`${process.env.REACT_APP_STORAGE}/profile/${member.slug}`}
                            />
                          ))}
                        </div>
                        <br />
                        <small>Timeline</small>
                        <h6 style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{moment(start_date).format('LL')}</span>
                          <ArrowRight />
                          <span>{moment(end_date).format('LL')}</span>
                        </h6>
                      </section>
                      {freelancer ? (
                        <section>
                          <small>Freelancer</small>
                          <h6>
                            {freelancer.first_name} {freelancer.last_name}
                          </h6>
                        </section>
                      ) : null}
                    </>
                  ) : null}
                </main>
                <aside>
                  <section>
                    <section>
                      <small>Job Stage</small>
                      <h6>{stage}</h6>
                    </section>
                    {amount ? (
                      <section>
                        <small>Amount</small>
                        <h6>{'N'+ formatCurrency(amount)}</h6>
                      </section>
                    ) : null}
                  </section>
                  <section>
                    <small>State</small>
                    {category === 'Completed'?
                      <h6 style={{color:"green"}}> {category}</h6>
                    : <h6 style={{color:"#ffcc00"}}>{category}</h6>}
                   
                  </section>
                </aside>
              </JumboBox>
            )
          )}
        </Container>
      ) : (
        NotCreated('job', 'jobs', JobsLinks.CreateJob)
      )}
    </PageBody>
  )
}

export const Jobs = Page(View, 'Jobs')
