import { groupBy } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { ArrowRight } from 'react-feather'
import { Container, Draggable, DropResult } from 'react-smooth-dnd'
import styled from 'styled-components'
import { generateConfirmAlert } from '../../../component/alert'
import { SlickLink } from '../../../component/button'
import { PageBody } from '../../../component/misc'
import { Job } from '../../../model/response'
import { JobsService } from '../../../service'
import { Colors, Effects, RandomColors } from '../../../style'
import { JobStagesSerial, Permissions } from '../../../util/constants'
import { useLoadingMessage } from '../../../util/hook'
import { Page, PageComponentProps } from '../../Page'
import { SidebarLinks } from '../links'
import { JobsLinks, links } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Jobs, name: 'Jobs' },
  { path: '', name: 'Progress Board' },
]

interface Column {
  id: number
  name: string
  rows: Job[]
}

const Board = styled.div`
  display: grid;
  grid-template-columns: repeat(${JobStagesSerial.length}, 300px);
  grid-gap: 30px;
  min-height: 100%;

  .drop-preview {
    background: #fafbfa;
    border: 2px dashed ${Colors.LightGrey};
    margin-bottom: 20px;

    + * {
      margin-top: 20px;
    }
  }
`

const StyledItem = styled.span`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 10px;
  border: 2px solid ${Colors.LightGrey};
  cursor: not-allowed;
  position: relative;

  * {
    user-select: none;
  }

  &::before {
    position: absolute;
    left: -2px;
    height: 20px;
    width: 2px;
    top: 0;
    background: #${(props: { colorIndex: number }) => RandomColors[props.colorIndex]};
    content: '';
    display: block;
  }

  header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
      font-weight: bold;
    }
  }

  section {
    display: flex;
    flex-direction: column;
  }

  section + section {
    margin-top: 10px;
  }

  section > * + * {
    margin-top: 5px;
  }

  svg {
    height: 15px;
    width: 15px;
    margin: 0 5px;
  }

  &.movable {
    cursor: grab;
  }
`

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  background: white;
  padding: 20px;
  border: 2px solid ${Colors.LightGrey};
  box-shadow: ${Effects.DropShadow};

  > header {
    margin-bottom: 20px;
  }

  > main {
    flex: 1;
  }

  > main > div {
    height: 100%;

    > * + * {
      margin-top: 20px;
    }
  }
`

export const View: React.FC<PageComponentProps> = ({
  loaderState: { setLoading, setDone },
  authState: { user },
  permissionsState: { hasPermission },
}) => {
  const alert = useAlert()
  const [jobs, setJobs] = useState<Job[]>([])
  const [columns, setColumns] = useState<Column[]>([])
  const { isLoading, message, updateLoader } = useLoadingMessage()

  const replaceJob = (job: Job) => {
    const index = jobs.findIndex(({ id }) => job.id === id)
    const newJobs = [...jobs.slice(0, index), job, ...jobs.slice(index + 1)]

    setJobs(newJobs)
  }

  const advanceJob = async (id: number) => {
    setLoading('Advancing job progress')
    const response = await JobsService.advance(id)
    setDone()

    if (response.data) {
      alert.success('Job stage successfully advanced')
      replaceJob(response.data)

      return
    }

    alert.error(response.message)
  }

  const getJob = (id: number, index: number) => {
    return columns.find((column) => column.id === id)?.rows[index]
  }

  const onCardDrop = async (
    index: number,
    { addedIndex, payload }: DropResult
  ) => {
    if (addedIndex !== null) {
      const job = payload as Job

      // tslint:disable-next-line
      ;(async () => {
        updateLoader(true, 'Verifying job stage and information')
        const response = await JobsService.retrieve(job.id)
        updateLoader(false)

        if (response.data) {
          if (response.data.stage !== job.stage) {
            replaceJob(response.data)

            return
          }

          generateConfirmAlert(
            {
              header: `Advance job from ${JobStagesSerial[index - 1]} to ${
                JobStagesSerial[index]
              }`,
              message: `Are you sure you want to advance this job's progress?`,
              confirmText: `Yes, advance`,
              cancelText: 'No, cancel',
            },
            advanceJob.bind(undefined, job.id)
          )
        }
      })()
    }
  }

  const getJobs = async () => {
    updateLoader(true, 'Fetching jobs')
    const response = await JobsService.list()
    updateLoader(false)

    if (response.data) {
      setJobs(response.data)
      return
    }

    alert.error(response.message)
  }

  const getColumns = useCallback(async () => {
    const groupedJobs = groupBy(jobs, 'stage')

    setColumns(
      JobStagesSerial.map((name, index) => ({
        id: index,
        name,
        rows: groupedJobs[name] || [],
      }))
    )
  }, [jobs])

  useEffect(() => {
    getJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getColumns()
  }, [getColumns])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Progress Board"
      tagline="View / manage the jobs on the platform"
      pageLinks={links}
      isLoading={isLoading}
      loadingMessage={message}
      actionArea={
        hasPermission(Permissions.JobCreation) ? (
          <SlickLink to={JobsLinks.CreateJob}>
            <span>Create Job</span>
          </SlickLink>
        ) : null
      }
    >
      <Board>
        {columns.map(({ id, name, rows }) => (
          <StyledColumn key={name}>
            <header>
              <h3>{name}</h3>
            </header>
            <main>
              <Container
                groupName={name}
                orientation="vertical"
                getChildPayload={(index) => getJob(id, index)}
                shouldAcceptDrop={(e, job: Job) => {
                  const diff = id - +JobStagesSerial.indexOf(job.stage)

                  if (diff === 1) {
                    return true
                  }

                  return false
                }}
                onDrop={(e) => onCardDrop(id, e)}
                dropPlaceholder={{
                  animationDuration: 150,
                  showOnTop: true,
                  className: 'drop-preview',
                }}
              >
                {rows.length ? (
                  rows.map(
                    ({
                      id: itemId,
                      client,
                      category,
                      engagement_manager,
                      engagement_partner,
                      start_date,
                      end_date,
                      accepted,
                    }) => {
                      const canManageJob =
                        accepted &&
                        (user?.id === engagement_manager.id ||
                          user?.id === engagement_partner.id)
                      const Element = canManageJob ? Draggable : 'div'

                      const warn = () => {
                        if (!canManageJob) {
                          alert.error('You cannot manage that job')
                        }
                      }

                      return (
                        <Element
                          draggable={!canManageJob}
                          key={itemId}
                          title={
                            canManageJob
                              ? client.name
                              : 'You cannot manage this job'
                          }
                          onDragStart={warn}
                        >
                          <StyledItem
                            colorIndex={Math.round(Math.random() * 9)}
                            className={canManageJob ? 'movable' : ''}
                          >
                            <header>
                              <span>{client.name}</span>
                              <small>{category}</small>
                            </header>
                            <main>
                              <section>
                                {start_date && end_date && accepted ? (
                                  <small
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <span>
                                      {moment(start_date).format('LL')}
                                    </span>
                                    <ArrowRight />
                                    <span>{moment(end_date).format('LL')}</span>
                                  </small>
                                ) : (
                                  <small className="rejected">
                                    No approved timeline
                                  </small>
                                )}
                              </section>
                            </main>
                          </StyledItem>
                        </Element>
                      )
                    }
                  )
                ) : (
                  <small>No jobs at this stage</small>
                )}
              </Container>
            </main>
          </StyledColumn>
        ))}
      </Board>
    </PageBody>
  )
}

export const ProgressBoard = Page(View, 'Progress Board')
