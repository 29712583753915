import React from 'react'
import { LeavesService } from '../../../../service'
import { Page } from '../../../Page'
import { SidebarLinks } from '../../links'
import { ListNamedEntitiesPage } from '../common/ListPage'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: '',
    name: 'Leaves',
  },
]

const View = () => (
  <ListNamedEntitiesPage
    breadcrumbs={breadcrumbs}
    service={LeavesService}
    singular="Leave"
    plural="Leaves"
    listLink={SettingsLinks.Leaves}
    createLink={SettingsLinks.CreateLeave}
    noFilter
  />
)

export const Leaves = Page(View, 'Leaves')
