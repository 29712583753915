import React from 'react'
import { JobTitlesService } from '../../../../service'
import { Page } from '../../../Page'
import { SidebarLinks } from '../../links'
import { ListNamedEntitiesPage } from '../common/ListPage'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: '',
    name: 'Job Titles',
  },
]

const View = () => (
  <ListNamedEntitiesPage
    breadcrumbs={breadcrumbs}
    service={JobTitlesService}
    singular="Job Title"
    plural="Job Titles"
    listLink={SettingsLinks.JobTitles}
    createLink={SettingsLinks.CreateJobTitle}
  />
)

export const JobTitles = Page(View, 'Job Titles')
