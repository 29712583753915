export enum RequestStates {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum RequestTypes {
  Individual = 'Individual',
  Office = 'Office',
}
