import { Field, useField } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { Colors, Effects } from '../../style'
import { generateValidationMessage } from '../../util/validation'

export const SwitchContainer = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  flex-wrap: wrap;

  > label {
    width: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
    font-size: 70%;
    color: ${Colors.Header};
    transition: background-color 0.5s ease;
    margin-bottom: 10px;
  }

  input {
    opacity: 0;
    filter: opacity(0);
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  i {
    position: relative;
    width: 50px;
    height: 30px;
    background: ${Colors.DarkGrey};
    border-radius: 15px;
    margin-right: 10px;
    border: 2px ${Colors.DarkGrey} solid;
    transition: all 0.5s ease;
  }

  i::after {
    border: 1px ${Colors.DarkGrey} solid;
    transition: left 0.15s ease;
    position: absolute;
    content: ' ';
    display: block;
    background: white;
    height: 24px;
    width: 24px;
    border-radius: 15px;
    box-shadow: ${Effects.DropShadow};
    left: 0;
    top: 0;
  }

  input:checked + i,
  input[value='true'] + i {
    background: ${Colors.Primary};
    border-color: ${Colors.Primary};
  }

  input:checked + i::after,
  input[value='true'] + i::after {
    border-color: ${Colors.Primary};
    left: calc(100% - 26px);
  }

  &.disabled {
    input + i {
      background: ${Colors.CancelButton};
      border-color: ${Colors.CancelButton};
    }

    input + i::after {
      border-color: ${Colors.CancelButton};
    }
  }
`

export const Switch: React.FC<any> = ({ label, disabled, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      <SwitchContainer className={disabled ? 'disabled' : ''}>
        {label ? (
          <label>
            <span>{label}</span>
          </label>
        ) : null}
        <Field type="checkbox" disabled={disabled} {...props} />
        <i />
        <small>Click to {field.value ? 'deactivate' : 'activate'}</small>
      </SwitchContainer>
      {generateValidationMessage(meta.error, meta.touched, !field.name)}
    </>
  )
}
