import { Dispatch, SetStateAction } from 'react'
import { CreateLeaveWizard } from '../../../model/request/leaves'
import { Leave, NamedEntity } from '../../../model/response'
import {
  DisciplinaryCaseTypesService,
  EventCategoriesService,
  JobTitlesService,
  JobTypesService,
  LeavesService,
  OfficesService,
} from '../../../service'
import { RoleFieldNames, roleKeys, RoleNames } from '../../../util/constants'

interface Field {
  name: string
  placeholder: string
  label: string
  type?: string
}

interface Form {
  name: string
  data: any[]
  service: new () => {}
  updater: Dispatch<SetStateAction<any[]>>
  example: string
  schema?: new () => {}
  fields?: Field[]
}

export const FormSetup = (
  leaves: Leave[],
  setLeaves: Dispatch<SetStateAction<Leave[]>>,
  jobTitles: NamedEntity[],
  setJobTitles: Dispatch<SetStateAction<NamedEntity[]>>,
  jobTypes: NamedEntity[],
  setJobTypes: Dispatch<SetStateAction<NamedEntity[]>>,
  offices: NamedEntity[],
  setOffices: Dispatch<SetStateAction<NamedEntity[]>>,
  caseTypes: NamedEntity[],
  setCaseTypes: Dispatch<SetStateAction<NamedEntity[]>>,
  eventCategories: NamedEntity[],
  setEventCategories: Dispatch<SetStateAction<NamedEntity[]>>
): Form[] => [
  {
    name: 'Leave',
    data: leaves,
    service: LeavesService,
    updater: setLeaves,
    schema: CreateLeaveWizard,
    example: 'Casual Leave',
    fields: [
      {
        name: 'name',
        placeholder: 'Example: Casual Leave',
        label: 'Leave Name',
      },
      ...roleKeys.map((key) => ({
        name: (RoleFieldNames as any)[key],
        placeholder: `Number of days for ${(RoleNames as any)[key]}`,
        label: (RoleNames as any)[key],
        type: 'number',
      })),
    ],
  },
  {
    name: 'Job Title',
    data: jobTitles,
    service: JobTitlesService,
    updater: setJobTitles,
    example: 'Accountant',
  },
  {
    name: 'Job Type',
    data: jobTypes,
    service: JobTypesService,
    updater: setJobTypes,
    example: 'Auditing',
  },
  {
    name: 'Office',
    data: offices,
    service: OfficesService,
    updater: setOffices,
    example: 'Human Resources',
  },
  {
    name: 'Disciplinary Case Type',
    data: caseTypes,
    service: DisciplinaryCaseTypesService,
    updater: setCaseTypes,
    example: 'Indiscipline',
  },
  {
    name: 'Event Category',
    data: eventCategories,
    service: EventCategoriesService,
    updater: setEventCategories,
    example: 'Public Holiday',
  },
]
