import { Formik } from 'formik'
import { isMatch } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import ReactModal from 'react-modal'
import { Route, Switch } from 'react-router-dom'
import { generateConfirmAlert } from '../../../component/alert'
import {
  CancelSlickButton,
  PrimarySlickButton,
  SlickButton,
  SlickLink,
} from '../../../component/button'
import {
  Container,
  CreateEditForm,
  FormDivider,
} from '../../../component/container'
import {
  CheckableBox,
  DatePicker,
  FormTextInput,
} from '../../../component/field'
import { InternalRoute, Loader, PageBody } from '../../../component/misc'
import { RejectTeamTimeline, SetTimeline, CreateJob as CreateJobModel, CreateJob } from '../../../model/request/jobs'
import { Job, Employee, NamedEntity, Client } from '../../../model/response'
import {
  JobsService, ClientsService,
  EmployeesService, JobTypesService,
} from '../../../service'
import { Permissions } from '../../../util/constants'
import { validateWithModel } from '../../../util/validation'
import { Page, PageComponentProps } from '../../Page'
import { SidebarLinks } from '../links'
import { CreateFinancialRequest } from './financial-requests/CreateFinancialRequest'
import { SelectTeam } from './SelectTeam'
import { useLoadingMessage } from '../../../util/hook'
import { formatCurrency } from '../../../util/validation'



const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Jobs, name: 'Jobs' },
  {
    path: '',
    name: 'Job',
  },
]

const View: React.FC<PageComponentProps> = ({
  history,
  params,
  setTitle,
  authState,
  permissionsState: { hasPermission },
  loaderState,
}) => {
  const alert = useAlert()
  const [job, setJob] = useState<Job>()
  const [editMode, setEditMode] = useState(false)
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const [employees, setEmployees] = useState<Employee[]>()
  const [clients, setClients] = useState<Client[]>()
  const [jobTypes, setJobTypes] = useState<NamedEntity[]>()
  const { updateLoader } = useLoadingMessage()
  const [jobdetails, setJobdetails] = useState<CreateJobModel>()


  const getJobTypes = async () => {
    updateLoader(true, 'Fetching job types')
    const response = await JobTypesService.list()
    updateLoader(false)

    if (response.data) {
      setJobTypes(response.data)
      return
    }

    alert.error(response.message)
  }

  const getClients = async () => {
    updateLoader(true, 'Fetching job types')
    const response = await ClientsService.list()
    updateLoader(false)

    if (response.data) {
      setClients(response.data)
      return
    }

    alert.error(response.message)
  }

  const getEmployees = async () => {
    updateLoader(true, 'Fetching employees')
    const response = await EmployeesService.list()
    updateLoader(false)

    if (response.data) {
      setEmployees(response.data)
      return
    }

    alert.error(response.message)
  }

  const getDependencies = async () => {
    await getEmployees()
    await getClients()
    await getJobTypes()
  }



  const hasTimeline = useMemo(() => !!(job?.start_date && job?.end_date), [job])

  const approvable = useMemo(() => !job?.accepted && job?.team && hasTimeline, [
    job,
    hasTimeline,
  ])

  const isEM = useMemo(
    () => authState.user?.id === job?.engagement_manager.id,
    [job, authState.user]
  )

  const isEP = useMemo(
    () => authState.user?.id === job?.engagement_partner.id,
    [job, authState.user]
  )

  const getJob = async () => {
    const response = await JobsService.retrieve(params.id)

    const canAccessPage =
      hasPermission(Permissions.AdminViewAccess) ||
      authState.user?.id === response.data?.engagement_partner.id ||
      authState.user?.id === response.data?.engagement_manager.id ||
      (response.data?.accepted &&
        response.data?.team &&
        response.data?.team?.members.some(
          ({ id }) => authState.user?.id === id
        ))

    if (!canAccessPage) {
      alert.error('You are not authorized to view this job')
      history.replace(SidebarLinks.Jobs)
      return
    }

    if (response.data) {
      console.log(response.data)
      setJob(response.data)
      setTitle(response.data.client.name as string)

      const jobb = {
        client_id: response.data.client.id || 0,
        job_type_id: response.data.job_type.id || 0,
        engagement_partner_id: response.data.engagement_partner.id || 0,
        engagement_manager_id: response.data.engagement_manager.id || 0,
        amount: response.data.amount || 0
      }

      setJobdetails(jobb)
    }
  }

  const updateJob = async (jobb: CreateJob, resetForm: any) => {
    if (!isMatch(new CreateJobModel(jobdetails), jobb)) {
      loaderState.setLoading('Updating job')
      const response = await JobsService.update(params.id, jobb)
      loaderState.setDone()

      if (response.data) {
        alert.success('job updated successfully')
        setJob(response.data)
        const jobb = {
          client_id: response.data.client.id || 0,
          job_type_id: response.data.job_type.id || 0,
          engagement_partner_id: response.data.engagement_partner.id || 0,
          engagement_manager_id: response.data.engagement_manager.id || 0,
          amount: response.data.amount || 0
        }

        setJobdetails(jobb)
        setEditMode(false)
        resetForm()
        return
      }

      return
    }
    setEditMode(false)
    alert.info('No changes were made')
  }

  const handleApprove = () => {
    const acceptRequest = async () => {
      loaderState.setLoading('Approving job team and timeline')
      const response = await JobsService.approve(params.id)
      loaderState.setDone()

      if (response.data) {
        alert.success(response.message)
        setJob(response.data)
        return
      }

      alert.error(response.message)
    }

    generateConfirmAlert(
      {
        header: `Accept team and timeline`,
        message: `Are you sure you want to approve this team and timeline?`,
        confirmText: `Yes, approve`,
        cancelText: 'No, cancel',
      },
      acceptRequest
    )
  }

  useEffect(() => {
    getJob()
    getDependencies()
    // eslint-disable-next-line
  }, [params.id])

  return job ? (
    <Switch>
      <Route exact path={`${SidebarLinks.Jobs}/${params.id}`}>
        <Formik
          validate={validateWithModel(SetTimeline)}
          enableReinitialize
          initialValues={{ ...new SetTimeline(job), ...new CreateJobModel(jobdetails) }}
          onSubmit={async (values, { resetForm }) => {
            if (!isMatch(new SetTimeline(job), values)) {
              loaderState.setLoading('Setting new timeline for this job')
              const response = await JobsService.setTimeline(params.id, values)
              loaderState.setDone()

              if (response.data) {
                alert.success('Timeline set successfully')
                setJob(response.data)
                setEditMode(false)
                resetForm()
                return
              }

              return
            }

            resetForm()
            setEditMode(false)
            alert.info('No changes were made')
          }}
        >
          {({ handleSubmit, resetForm, values, setFieldValue, isSubmitting }) => (
            <Loader isLoading={!job} message="Fetching job information">
              <PageBody
                breadcrumbs={breadcrumbs}
                title={`${job?.client.name}`}
                tagline={job?.job_type.name}
                actionArea={
                  <>
                    {/*{authState.user?.role === 'Super Admin' && (job?.accepted && job.category === 'Ongoing') ?*/}
                    <>
                      {editMode ? null : (
                        <SlickButton
                          onClick={() => {
                            setEditMode(true)
                          }}
                        >
                          <span>Edit</span>
                        </SlickButton>
                      )}

                      {!editMode ? null : (
                        <>
                          <CancelSlickButton
                            type="button"
                            disabled={isSubmitting}
                            onClick={() => {
                              setEditMode(false)
                            }}
                          >
                            <span>Cancel</span>
                          </CancelSlickButton>
                          <SlickButton
                            onClick={() => {

                              const jobb = {
                                client_id: values.client_id || 0,
                                job_type_id: values.job_type_id || 0,
                                engagement_partner_id: values.engagement_partner_id || 0,
                                engagement_manager_id: values.engagement_manager_id || 0,
                                amount: values.amount || 0
                              }
                              updateJob(jobb, resetForm)
                              setEditMode(true)
                            }}
                          >
                            <span>update</span>
                          </SlickButton>
                        </>
                      )}
                    </>
                    {/*:null}*/}

                    {isEM && (job?.accepted && job.category === 'Ongoing'
                    ) ? (

                      <SlickLink
                        to={`${SidebarLinks.Jobs}/${params.id}/create-financial-request`}
                      >
                        <span>Create Financial Request</span>
                      </SlickLink>
                    ) : null}
                    {isEM && !job?.accepted ? (
                      <>
                        {editMode ? null : (
                          <SlickLink
                            to={`${SidebarLinks.Jobs}/${params.id}/team`}
                          >
                            <span>{job?.team ? 'Update' : 'Set'} Team</span>
                          </SlickLink>
                        )}

                        {editMode ? (
                          <>
                            <CancelSlickButton
                              type="button"
                              onClick={() => {
                                resetForm()
                                setEditMode(false)
                              }}
                            >
                              <span>Cancel</span>
                            </CancelSlickButton>
                            <PrimarySlickButton
                              type="button"
                              onClick={() => handleSubmit()}
                            >
                              <span>Save</span>
                            </PrimarySlickButton>
                          </>
                        ) : (
                          <SlickButton
                            type="button"
                            onClick={setEditMode.bind(undefined, true)}
                          >
                            <span>
                              {hasTimeline ? 'Update' : 'Set'} Timeline
                            </span>
                          </SlickButton>
                        )}
                      </>
                    ) : null}
                    {isEP && approvable ? (
                      <>
                        <SlickButton
                          onClick={setRejectModalOpen.bind(undefined, true)}
                        >
                          <span>Reject</span>
                        </SlickButton>
                        <PrimarySlickButton onClick={handleApprove}>
                          <span>Approve</span>
                        </PrimarySlickButton>
                      </>
                    ) : null}
                  </>
                }
              >
                <CreateEditForm>
                  <main>
                    <FormDivider>
                      <section>
                        <span>Job Details</span>
                        <FormTextInput
                          label="Client"
                          as="select"
                          name="client_id"
                          disabled={!editMode}
                        >
                          {clients?.map(({ id, name }) => (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          ))}
                        </FormTextInput>
                        <FormTextInput
                          label="Engagement Partner"
                          as="select"
                          name="engagement_partner_id"
                          disabled={!editMode}
                        >

                          {employees?.map(({ id, first_name, last_name }, i) => (
                            <option value={id} key={i}>
                              {first_name} {last_name}
                            </option>
                          ))}
                        </FormTextInput>
                        <FormTextInput
                          label="Engagement Manager"
                          as="select"
                          name="engagement_manager_id"
                          disabled={!editMode}
                        >
                          {employees?.map(({ id, first_name, last_name }, i) => (
                            <option value={id} key={i}>
                              {first_name} {last_name}
                            </option>
                          ))}
                        </FormTextInput>
                        <FormTextInput
                          label="Stage"
                          name="stage"
                          placeholder="Stage"
                          value={job?.stage}
                          disabled
                        />
                        git add.<FormTextInput
                          label="Amount (₦)"
                          name="amount"
                          placeholder="Stage"
                          disabled={!editMode}
                          type="number"
                        />
                        <FormTextInput
                          label="Job Type"
                          as="select"
                          name="job_type_id"
                          disabled={!editMode}
                        >
                          <option value={0} disabled>
                            Select job type
                        </option>
                          {jobTypes?.map(({ id, name }, i) => (
                            <option value={id} key={i}>
                              {name}
                            </option>
                          ))}
                        </FormTextInput>
                      </section>
                      {(isEM && editMode) || hasTimeline ? (
                        <section>
                          <span>
                            {job?.accepted ? '' : 'Proposed '}Timeline
                          </span>
                          <DatePicker
                            label="Start of Job"
                            name="start_date"
                            minDate={new Date()}
                            onChange={(date: string) => {
                              if (moment(values.end_date).isBefore(date, 'd')) {
                                setFieldValue('end_date', date)
                              }
                            }}
                            disabled={!editMode || !isEM || job?.accepted}
                          />
                          <DatePicker
                            label="End of Job"
                            name="end_date"
                            minDate={values.start_date}
                            disabled={!editMode || !isEM || job?.accepted}
                          />
                        </section>
                      ) : null}
                    </FormDivider>
                  </main>
                </CreateEditForm>
                {job?.team ? (
                  <>
                    <span>{job.accepted ? '' : 'Proposed '}Team</span>
                    <Container>
                      {job?.team?.members.map(
                        ({ id, slug, first_name, last_name }) => (
                          <CheckableBox
                            name="member_ids"
                            value={id}
                            key={id}
                            selected={true}
                            display
                          >
                            <div
                              className="image"
                              style={{
                                backgroundImage: `url(${process.env.REACT_APP_STORAGE}/profile/${slug}), url(/svg/portrait.svg)`,
                              }}
                            />
                            <span>
                              {first_name} {last_name}
                            </span>
                          </CheckableBox>
                        )
                      )}
                    </Container>
                  </>
                ) : null}
              </PageBody>
            </Loader>
          )}
        </Formik>
        {rejectModalOpen ? (
          <ReactModal
            overlayClassName="modal__overlay"
            className="modal"
            isOpen={true}
            onRequestClose={setRejectModalOpen.bind(undefined, false)}
          >
            <Formik
              initialValues={new RejectTeamTimeline()}
              validate={validateWithModel(RejectTeamTimeline)}
              onSubmit={async (original) => {
                const rejectAccept = async () => {
                  loaderState.setLoading('Rejecting job team/timeline')
                  const response = await JobsService.reject(params.id, original)
                  loaderState.setDone()

                  if (response.data) {
                    alert.success(response.message)
                    setJob(response.data)
                    setRejectModalOpen(false)
                    return
                  }

                  alert.error(response.message)
                }

                generateConfirmAlert(
                  {
                    header: `Reject team/timeline`,
                    message: `Are you sure you want to reject this team/timeline?`,
                    confirmText: `Yes, reject`,
                    cancelText: 'No, cancel',
                  },
                  rejectAccept
                )
              }}
            >
              {({ handleSubmit, isSubmitting }) => (
                <CreateEditForm onSubmit={handleSubmit}>
                  <header>
                    <h2>Reject Team/Timeline</h2>
                    <h6>Enter reason for rejection</h6>
                  </header>
                  <main>
                    <section>
                      <FormTextInput
                        label="Reason For Rejection"
                        name="response"
                        placeholder="State your reason for rejecting this team/timeline"
                        autoCapitalize="on"
                        as="textarea"
                        disabled={isSubmitting}
                      />
                    </section>
                  </main>
                  <footer>
                    <PrimarySlickButton type="submit">
                      <span>Reject</span>
                    </PrimarySlickButton>
                  </footer>
                </CreateEditForm>
              )}
            </Formik>
          </ReactModal>
        ) : null}
      </Route>
      <Route exact path={`${SidebarLinks.Jobs}/:id/team`}>
        <SelectTeam
          history={history}
          params={params}
          loaderState={loaderState}
          job={job as Job}
          setJob={setJob}
        />
      </Route>
      <InternalRoute
        exact
        path={`${SidebarLinks.Jobs}/:id/create-financial-request`}
        authFunction={() => isEM && !!job?.accepted}
      >
        <CreateFinancialRequest
          history={history}
          params={params}
          loaderState={loaderState}
          job={job as Job}
          setJob={setJob}
        />
      </InternalRoute>
    </Switch>
  ) : null
}

export const SingleJob = Page(View, 'Job')
