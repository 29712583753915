import React from 'react'
import { DisciplinaryCaseTypesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { SingleNamedEntityPage } from '../common/SinglePage'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.DisciplinaryCaseTypes,
    name: 'Disciplinary Case Types',
  },
  { path: '', name: 'Disciplinary Case Type' },
]

const View: React.FC<PageComponentProps> = ({ params }) => (
  <SingleNamedEntityPage
    breadcrumbs={breadcrumbs}
    service={DisciplinaryCaseTypesService}
    singular="Disciplinary case type"
    id={params.id}
  />
)

export const SingleDisciplinaryCaseType = Page(View, 'Disciplinary Case Type')
