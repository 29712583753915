import { registerDecorator, ValidationArguments } from 'class-validator'
import { ValidationOptions } from 'class-validator'
import { includes } from 'lodash'
import moment from 'moment'

const Weekends = ['Saturday', 'Sunday']

export const getFormattedDate = (date?: string | Date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const dateInFuture = (date: string | Date) => {
  return moment(date).isAfter(Date.now(), 'd')
}

export function IsNotWeekend(validationOptions?: ValidationOptions) {
  return function(object: Object, propertyName: string) {
    registerDecorator({
      name: 'isNotWeekend',
      target: object.constructor,
      propertyName,
      constraints: [],
      options: validationOptions,
      validator: {
        validate(value: string, args: ValidationArguments) {
          return !includes(Weekends, moment(value).format('dddd'))
        },
      },
    })
  }
}

export function IsNotBeforeOrAfter(
  otherProperty?: string,
  notAfter?: boolean,
  validationOptions?: ValidationOptions
) {
  return function(object: Object, propertyName: string) {
    registerDecorator({
      name: 'isNotBeforeOrAfter',
      target: object.constructor,
      propertyName,
      constraints: [],
      options: validationOptions,
      validator: {
        validate(value: string, args: ValidationArguments) {
          return moment(value)[notAfter ? 'isSameOrBefore' : 'isSameOrAfter'](
            otherProperty ? (args.object as any)[otherProperty] : Date.now(),
            'd'
          )
        },
      },
    })
  }
}
