import { Formik } from 'formik'
import { History } from 'history'
import React, { useContext } from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { PrimaryButton, PrimaryLink } from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { FormTextInput } from '../../../../component/field'
import { PageBody } from '../../../../component/misc'
import { LoaderContext } from '../../../../context/loader.context'
import { CreateNamedEntity } from '../../../../model/request/named-entities'
import { NamedEntity } from '../../../../model/response'
import { ApiResponse } from '../../../../util/ApiClient'
import { validateWithModel } from '../../../../util/validation'
import { createNamedEntityInitialValues } from '../common'

interface CreateNamedEntityFormProps {
  breadcrumbs: any
  service: any
  history: History
  singular: string
  redirect: string
}

export const CreateNamedEntityForm: React.FC<CreateNamedEntityFormProps> = ({
  breadcrumbs,
  service,
  history,
  singular,
  redirect,
}) => {
  const { setLoading, setDone } = useContext(LoaderContext)
  const { state } = useLocation<any>()
  const alert = useAlert()
  const realRedirect = state?.referrer || redirect

  return (
    <PageBody breadcrumbs={breadcrumbs}>
      <Formik
        initialValues={createNamedEntityInitialValues}
        validate={validateWithModel(CreateNamedEntity)}
        onSubmit={async (values, { setErrors }) => {
          setLoading(`Creating ${singular}`)
          const response = (await service.create(values)) as ApiResponse<
            NamedEntity
          >
          setDone()

          if (response.success) {
            alert.success(`${singular} created successfully`)
            history.push(
              realRedirect,
              state
                ? {
                    key: state.key,
                    values: {
                      ...state.values,
                      [state.field]: response.data?.id,
                    },
                  }
                : null
            )
            return
          }

          if (response.errors) {
            alert.error(
              'There was a problem with your data. Check the form again'
            )
            setErrors(response.errors as any)
            return
          }

          alert.error(response.message)
        }}
      >
        {({ handleSubmit, handleChange, isSubmitting }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create {singular}</h2>
              <h6>Fill in the required detail below</h6>
            </header>
            <main>
              <section>
                <FormTextInput
                  label="Name"
                  name="name"
                  placeholder="Name"
                  autoCapitalize="on"
                  spellCheck="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink
                to={
                  isSubmitting
                    ? '#'
                    : {
                        pathname: realRedirect,
                        state: state
                          ? { key: state.key, values: state.values }
                          : null,
                      }
                }
              >
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  )
}
