import {
  CreateNamedEntity,
  EditNamedEntity,
} from '../model/request/named-entities'
import { NamedEntity } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class JobTypesService {
  public static async create(details: CreateNamedEntity) {
    return ApiClient.post<CreateNamedEntity, NamedEntity>(
      'job-type/create',
      details,
      CreateNamedEntity
    )
  }

  public static async list() {
    return ApiClient.get<NamedEntity[]>('job-type/view-list')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<NamedEntity>('job-type/show', id)
  }

  public static async update(id: number | string, details: EditNamedEntity) {
    return ApiClient.patch<EditNamedEntity, NamedEntity>(
      'job-type/update',
      id,
      details,
      EditNamedEntity
    )
  }
}
