import {
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsPositive,
  MinLength,
} from 'class-validator'
import { RequestTypes } from '../../../util/constants'
import { Employee } from '../../response'

export class CreateRequest {
  @IsEnum(RequestTypes, { message: 'Request type is invalid' })
  public type!: string

  @IsNumber({}, { message: 'Price must be a number' })
  public price!: number

  @IsPositive({ message: 'Creator is invalid' })
  public creator_id!: number

  @IsNotEmpty({ message: 'Enter a description' })
  @MinLength(3, { message: 'Description is too short' })
  public description!: string

  constructor(object: CreateRequest | undefined, creator: Employee) {
    if (object) {
      this.type = object.type
      this.price = object.price
      this.creator_id = creator?.id || 0
      this.description = object.description
      return
    }

    this.type = RequestTypes.Individual
    this.price = 0
    this.creator_id = creator?.id || 0
    this.description = ''
  }

  public static transform(object: CreateRequest) {
    object.creator_id = Number.parseInt(object.creator_id as any, 10)
    object.price = Number.parseInt(object.price as any, 10)
  }
}
