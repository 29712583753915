import { Formik } from 'formik'
import { isMatch } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  CancelSlickButton,
  PrimarySlickButton,
  SlickButton,
} from '../../../../component/button'
import { CreateEditForm, FormDivider } from '../../../../component/container'
import { FormTextInput, Switch } from '../../../../component/field'
import { Loader, PageBody } from '../../../../component/misc'
import { EditClient } from '../../../../model/request/jobs'
import { Client } from '../../../../model/response'
import { ClientsService } from '../../../../service'
import { validateWithModel } from '../../../../util/validation'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { JobsLinks } from '../links'

const View: React.FC<PageComponentProps> = ({ params, loaderState }) => {
  const alert = useAlert()
  const [client, setClient] = useState<Client>()
  const [editMode, setEditMode] = useState(false)

  const getClient = async () => {
    const response = (await ClientsService.retrieve(params.id)).data
    setClient(response)
  }

  const breadcrumbs = useMemo(
    () => [
      { path: SidebarLinks.Index, name: 'Dashboard' },
      { path: SidebarLinks.Jobs, name: 'Jobs' },
      { path: JobsLinks.Clients, name: 'Clients' },
      {
        path: '',
        name: client?.name || 'Client',
      },
    ],
    [client]
  )

  useEffect(() => {
    getClient()
    // eslint-disable-next-line
  }, [params.slug])

  return (
    <Formik
      enableReinitialize
      validate={validateWithModel(EditClient)}
      initialValues={new EditClient(client)}
      onSubmit={async (values) => {
        if (!isMatch(client as Client, values)) {
          loaderState.setLoading('Updating client')
          const response = await ClientsService.update(params.id, values)
          loaderState.setDone()

          if (response.success) {
            alert.success(`Client updated successfully`)
            setClient({
              ...(response.data as Client),
              ...values,
            })
            setEditMode(false)
            return
          }

          alert.error(response.message)
          return
        }

        alert.info('No changes were made')
        setEditMode(false)
      }}
    >
      {({ handleSubmit, handleChange, resetForm, isSubmitting }) => (
        <Loader isLoading={!client} message="Fetching client information">
          <PageBody
            breadcrumbs={breadcrumbs}
            title={client?.name}
            tagline={client?.active ? 'Active' : 'Inactive'}
            actionArea={
              editMode ? (
                <>
                  <CancelSlickButton
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => {
                      resetForm()
                      setEditMode(false)
                    }}
                  >
                    <span>Cancel</span>
                  </CancelSlickButton>
                  <PrimarySlickButton
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    <span>Save</span>
                  </PrimarySlickButton>
                </>
              ) : (
                <SlickButton
                  onClick={() => {
                    setEditMode(true)
                  }}
                >
                  <span>Edit</span>
                </SlickButton>
              )
            }
          >
            <CreateEditForm onSubmit={handleSubmit}>
              <main>
                {editMode ? (
                  <section>
                    <Switch
                      name="active"
                      label="Status"
                      disabled={isSubmitting}
                    />
                  </section>
                ) : null}
                <FormDivider>
                  <section>
                    <span>Client Information</span>
                    <FormTextInput
                      label="Name"
                      name="name"
                      placeholder="Name"
                      trim
                      handleChange={handleChange}
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Phone Number"
                      name="phone_number"
                      placeholder="Phone number"
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Address"
                      placeholder="Address"
                      name="address"
                      as="textarea"
                      handleChange={handleChange}
                      disabled={!editMode || isSubmitting}
                    />
                  </section>
                </FormDivider>
              </main>
            </CreateEditForm>
          </PageBody>
        </Loader>
      )}
    </Formik>
  )
}

export const SingleClient = Page(View, 'Client')
