import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import { AspectRatio } from 'react-aspect-ratio'
import { FilePond, registerPlugin } from 'react-filepond'
import { PermissionsContext } from '../../../context/permissions.context'
import { ApiClient } from '../../../util/ApiClient'
import { Permissions } from '../../../util/constants'

const acceptedMimeTypes = ['image/jpeg', 'image/png']

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
export const OfficeStructurePond: React.FC = () => {
  const alert = useAlert()
  const [background, setBackground] = useState(``)
  const { hasPermission } = useContext(PermissionsContext)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isAdmin = useMemo(() => hasPermission(Permissions.AdminViewAccess), [])

  useEffect(() => {
    setBackground(`${process.env.REACT_APP_STORAGE}/office/office-structure`)
  }, [])

  return (
    <AspectRatio
      ratio="3/2"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        // maxWidth: 800,
      }}
    >
      {isAdmin ? (
        <FilePond
          allowMultiple={false}
          name="office_structure"
          instantUpload={false}
          allowRevert={false}
          acceptedFileTypes={acceptedMimeTypes}
          labelIdle=""
          onerror={(error, file) => {
            alert.error(error.main)
            file?.abortLoad()
          }}
          onaddfile={(error, file) => {
            if (
              !acceptedMimeTypes.find(mimeType => mimeType === file.fileType)
            ) {
              alert.error('You can only upload JPEG and PNG formats')
              file.abortLoad()
              return
            }

            if (file.fileSize > 3 * 1000 * 1024) {
              alert.error('File is too large')
              file.abortLoad()
            }
          }}
          onprocessfile={(error, file) => {
            file.abortLoad()
          }}
          server={
            {
              process: async (
                _: string,
                file: File,
                metadata: any,
                load: any,
                error: any,
                progress: any,
                abort: any
              ) => {
                const data = new FormData()
                data.append('office_structure', file)
                const request = new XMLHttpRequest()

                request.onload = e => {
                  if (request.status >= 200 && request.status < 300) {
                    load(request.responseText)
                    alert.success('Image updated successfully')
                    setBackground(
                      `${
                        process.env.REACT_APP_STORAGE
                      }/office/office-structure?${Date.now()}`
                    )
                  } else {
                    console.error(request)
                  }
                }

                request.upload.onprogress = e => {
                  progress(e.lengthComputable, e.loaded, e.total)
                }

                request.open(
                  'POST',
                  `${process.env.REACT_APP_BASE_URL}/office-structure/upload`
                )
                request.setRequestHeader(
                  'authorization',
                  ApiClient.getBearerToken()
                )

                request.send(data)

                return {
                  abort: () => {
                    if (!request.DONE) {
                      request.abort()
                    }

                    abort()
                  },
                }
              },
            } as any
          }
        />
      ) : null}
    </AspectRatio>
  )
}
