import { Field, Formik } from 'formik'
import Fuse, { IFuseOptions } from 'fuse.js'
import { filter, matches } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ListViewButton, SlickLink } from '../../../../component/button'
import { Box, Container } from '../../../../component/container'
import { Input } from '../../../../component/field'
import { FilterBar, Loader, PageBody, Table } from '../../../../component/misc'
import { Client } from '../../../../model/response'
import { ClientsService } from '../../../../service'
import { Permissions } from '../../../../util/constants'
import { Page, PageComponentProps } from '../../../Page'
import { NotCreated } from '../../common'
import { SidebarLinks } from '../../links'
import { JobsLinks, links } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Jobs, name: 'Jobs' },
  {
    path: '',
    name: 'Clients',
  },
]

export enum Filters {
  All,
  Active,
  Inactive,
}

export const filters = {
  [Filters.All]: {},
  [Filters.Active]: { active: true },
  [Filters.Inactive]: { active: false },
}

const View: React.FC<PageComponentProps> = ({ permissionsState, query }) => {
  const isListView = useMemo(() => query.view === 'list', [query.view])
  const [isLoading, setIsLoading] = useState(true)
  const [clients, setClients] = useState<{
    [filter: string]: Client[]
  }>({})

  const getClients = async () => {
    const retrieved = (await ClientsService.list()).data
    const filtered: { [filter: string]: Client[] } = {}

    Object.entries(filters).forEach(([filterName, constraint]) => {
      filtered[filterName] = filter(retrieved, matches(constraint))
    })

    setClients(filtered)
    setIsLoading(false)
  }

  const getList = useCallback(
    (search: string, currentFilter: Filters) => {
      const options: IFuseOptions<Client> = {
        minMatchCharLength: 0,
        threshold: 0.2,
        keys: ['name', 'email', 'address'],
      }

      const list = search
        ? new Fuse(clients[currentFilter], options)
            .search(search)
            .map(({ item }) => item)
        : clients[currentFilter]

      return list || []
    },
    [clients]
  )

  useEffect(() => {
    getClients()
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Clients"
      pageLinks={links}
      tagline="View / manage the clients on the platform"
      actionArea={
        permissionsState.hasPermission(Permissions.JobCreation) ? (
          <SlickLink to={JobsLinks.CreateClient}>
            <span>Create Client</span>
          </SlickLink>
        ) : null
      }
    >
      <Loader isLoading={isLoading} message="Fetching clients">
        {clients[Filters.All]?.length ? (
          <Formik
            initialValues={{ filter: Filters.All, name: '' }}
            onSubmit={() => {}}
          >
            {({ values: { name: search, filter: currentFilter } }) => {
              const list = getList(search, currentFilter)

              return (
                <>
                  <FilterBar>
                    <section className="dropdown">
                      <Field as="select" name="filter">
                        <option value={Filters.All}>All</option>
                        <option value={Filters.Active}>Active</option>
                        <option value={Filters.Inactive}>Inactive</option>
                      </Field>
                    </section>
                    <section>
                      <ListViewButton />
                      <Field
                        as={Input}
                        name="name"
                        placeholder="Filter by name..."
                      />
                    </section>
                  </FilterBar>
                  {isListView ? (
                    <Table
                      columns={[
                        { Header: 'No.', accessor: 'id' },
                        { Header: 'Name', accessor: 'name' },
                        { Header: 'Address', accessor: 'address' },
                        { Header: 'Email', accessor: 'email' },
                        { Header: 'Phone Number', accessor: 'phone_number' },
                        {
                          Header: 'Status',
                          accessor: ({ active }: Client) =>
                            active ? 'Active' : 'Inactive',
                        },
                      ]}
                      data={list}
                    />
                  ) : (
                    <Container>
                      {list.map(({ id, name, email, active }) => (
                        <Box
                          to={`${JobsLinks.Clients}/${id}`}
                          inactive={!active}
                          key={id}
                        >
                          <span>{name}</span>
                          <h6>{email}</h6>
                          <small>{active ? 'Active' : 'Inactive'}</small>
                        </Box>
                      ))}
                    </Container>
                  )}{' '}
                </>
              )
            }}
          </Formik>
        ) : (
          NotCreated('client', 'clients', JobsLinks.CreateClient)
        )}
      </Loader>
    </PageBody>
  )
}

export const Clients = Page(View, 'Clients')
