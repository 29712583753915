/* eslint-disable react-hooks/exhaustive-deps */
import { useField } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Check, X } from 'react-feather'
import styled from 'styled-components'
import { Colors, Effects } from '../../style'
import { generateValidationMessage } from '../../util/validation'
import { FieldContainer, Input } from './Text'

interface DatePickerProps {
  name: string
  label: string
  placeholder?: string
  onBlur?: any
  minDate?: string | Date
  maxDate?: string | Date
  onChange?: (date: string) => void
  disabled?: boolean
}

const DatePickerContainer = styled(FieldContainer)`
  .react-datepicker {
    color: #565050;
    background: #ffffffee;
    backdrop-filter: blur(10px);
    border: 2px solid ${Colors.LightGrey};
    border-radius: 0;
    box-shadow: ${Effects.DropShadow};
  }

  .react-datepicker__header {
    background: ${Colors.LightGrey};
    border-radius: 0;
    padding-top: 15px;
    border: none;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-bottom: 10px;
    color: ${Colors.Primary};
    font-weight: bold;
    font-size: 1em;
  }

  .react-datepicker__day-names {
    white-space: nowrap;
    padding: 5px 20px;
  }

  .react-datepicker__week {
    white-space: nowrap;
    padding: 0 20px;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 0;
    font-size: 80%;
  }

  .react-datepicker__day {
    font-weight: bold;
  }

  .react-datepicker__day--outside-month {
    color: ${Colors.DarkGrey};
    background: #f7f7f7;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    border-radius: 0;
    background: ${Colors.LightGrey};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    border-radius: 0;
    background-color: ${Colors.Primary} !important;
    color: white;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month {
    margin: 0;
    padding: 20px 0;
  }

  .react-datepicker__day--disabled {
    background: ${Colors.LightGrey} !important;
    color: ${Colors.DarkGrey} !important;
    cursor: not-allowed;
    font-weight: normal;
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: white;
    position: absolute;
    border-radius: 0;
    border: 2px solid ${Colors.LightGrey};
    box-shadow: ${Effects.DropShadow};
  }

  .react-datepicker__year-select,
  .react-datepicker__month-select {
    height: 30px;
    padding: 5px;
    border: none;
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 5px;
  }
`

const CustomInput = React.forwardRef((props: any, ref: any) => {
  const date = moment(props.value)
  return (
    <Input
      ref={ref}
      {...props}
      value={date.isValid() ? date.format('LL') : ''}
    />
  )
})

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  minDate,
  maxDate,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(props)
  const [currentDate, setCurrentDate] = useState<
    Date | string | null | undefined
  >(field.value)

  useEffect(() => {
    const formattedDate = currentDate
      ? moment(currentDate).format('YYYY-MM-DD')
      : ''
    field.onChange(field.name)(formattedDate as any)

    if (onChange && formattedDate) {
      onChange(formattedDate)
    }
  }, [currentDate])

  return (
    <DatePickerContainer className={props.disabled ? 'disabled' : ''}>
      <label className={meta.touched ? (meta.error ? 'error' : 'valid ') : ''}>
        <section>
          {!props.disabled && meta.touched ? (
            meta.error ? (
              <X />
            ) : (
              <Check />
            )
          ) : null}
          {label}
          {generateValidationMessage(
            meta.initialError || meta.error,
            meta.touched
          )}
        </section>
      </label>
      <ReactDatePicker
        name={field.name}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        customInput={<CustomInput />}
        dateFormat="LLLL"
        openToDate={field.value ? new Date(field.value) : new Date()}
        {...field}
        onSelect={(date) => {
          helpers.setTouched(true)
        }}
        onChange={(date, e) => {
          setCurrentDate(date as Date)
        }}
        {...props}
        placeholderText={props.placeholder || 'Enter date'}
        minDate={minDate ? moment(minDate).toDate() : null}
        maxDate={maxDate ? moment(maxDate).toDate() : null}
      />
    </DatePickerContainer>
  )
}
