import { Formik, FormikErrors } from 'formik'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { PrimaryButton, PrimaryLink } from '../../../component/button'
import { CreateEditForm } from '../../../component/container'
import { FormTextInput } from '../../../component/field'
import { PageBody } from '../../../component/misc'
import { CreateJob as CreateJobModel } from '../../../model/request/jobs'
import { Client, Employee, NamedEntity } from '../../../model/response'
import {
  ClientsService,
  EmployeesService,
  JobsService,
  JobTypesService,
} from '../../../service'
import { Permissions } from '../../../util/constants'
import { useLoadingMessage } from '../../../util/hook'
import { validateWithModel } from '../../../util/validation'
import { Page, PageComponentProps } from '../../Page'
import { SidebarLinks } from '../links'
import { SettingsLinks } from '../settings/links'
import { JobsLinks } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Jobs, name: 'Jobs' },
  {
    path: '',
    name: 'Jobs',
  },
]

const View: React.FC<PageComponentProps> = ({
  history,
  loaderState,
  permissionsState,
}) => {
  const alert = useAlert()
  const { state } = useLocation<any>()
  const [employees, setEmployees] = useState<Employee[]>()
  const [clients, setClients] = useState<Client[]>()
  const [jobTypes, setJobTypes] = useState<NamedEntity[]>()
  const { isLoading, message, updateLoader } = useLoadingMessage()

  const getJobTypes = async () => {
    updateLoader(true, 'Fetching job types')
    const response = await JobTypesService.list()
    updateLoader(false)

    if (response.data) {
      setJobTypes(response.data)
      return
    }

    alert.error(response.message)
  }

  const getClients = async () => {
    updateLoader(true, 'Fetching job types')
    const response = await ClientsService.list()
    updateLoader(false)

    if (response.data) {
      setClients(response.data)
      return
    }

    alert.error(response.message)
  }

  const getEmployees = async () => {
    updateLoader(true, 'Fetching employees')
    const response = await EmployeesService.list()
    updateLoader(false)

    if (response.data) {
      setEmployees(response.data)
      return
    }

    alert.error(response.message)
  }

  const getDependencies = async () => {
    await getEmployees()
    await getClients()
    await getJobTypes()
  }

  useEffect(() => {
    getDependencies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      loadingMessage={message}
    >
      <Formik
        initialValues={
          new CreateJobModel(
            (state as any)?.key === 'create-job'
              ? ((state as any)?.values as CreateJobModel)
              : undefined
          )
        }
        validate={validateWithModel(CreateJobModel)}
        onSubmit={async (values, { setErrors }) => {
          loaderState.setLoading('Creating job')
          const response = await JobsService.create(values)
          loaderState.setDone()

          if (response.success) {
            alert.success('Job created successfully')
            history.push(SidebarLinks.Jobs)
            return
          }

          alert.error(
            'There was a problem with your data. Check the form again'
          )
          setErrors(response.errors as FormikErrors<CreateJobModel>)
        }}
      >
        {({ handleChange, handleSubmit, isSubmitting, values }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Job</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <FormTextInput
                  label="Client"
                  addLink={{
                    to: JobsLinks.CreateClient,
                    state: {
                      key: 'create-job',
                      referrer: JobsLinks.CreateJob,
                      field: 'client_id',
                      values,
                    },
                  }}
                  as="select"
                  name="client_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select client
                  </option>
                  {clients?.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Job Type"
                  addLink={
                    permissionsState.hasPermission(
                      Permissions.SystemConfiguration
                    )
                      ? {
                          to: SettingsLinks.CreateJobType,
                          state: {
                            key: 'create-job',
                            referrer: JobsLinks.CreateJob,
                            field: 'job_type_id',
                            values,
                          },
                        }
                      : undefined
                  }
                  as="select"
                  name="job_type_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select job type
                  </option>
                  {jobTypes?.map(({ id, name }, i) => (
                    <option value={id} key={i}>
                      {name}
                    </option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Engagement Partner"
                  as="select"
                  name="engagement_partner_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select engagement partner
                  </option>
                  {employees?.map(({ id, first_name, last_name }, i) => (
                    <option value={id} key={i}>
                      {first_name} {last_name}
                    </option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Engagement Manager"
                  as="select"
                  name="engagement_manager_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select engagement manager
                  </option>
                  {employees?.map(({ id, first_name, last_name }, i) => (
                    <option value={id} key={i}>
                      {first_name} {last_name}
                    </option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Amount (N)"
                  name="amount"
                  placeholder="Amount"
                  type="number"
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink to={isSubmitting ? '#' : SidebarLinks.Jobs}>
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  )
}

export const CreateJob = Page(View, 'Create Job')
