import React from 'react'
import { EventCategoriesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { CreateNamedEntityForm } from '../common/CreateForm'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.EventCategories,
    name: 'Event Categories',
  },
  {
    path: '',
    name: 'Create Event Category',
  },
]

const View: React.FC<PageComponentProps> = ({ history }) => {
  return (
    <CreateNamedEntityForm
      breadcrumbs={breadcrumbs}
      service={EventCategoriesService}
      history={history}
      singular="Event Category"
      redirect={SettingsLinks.EventCategories}
    />
  )
}

export const CreateEventCategory = Page(View, 'Create Event Category')
