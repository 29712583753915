import {
  IsBoolean,
  IsEmail,
  IsNumberString,
  IsPhoneNumber,
  IsString,
  Length,
  Matches,
  MinLength,
} from 'class-validator'
import {
  IsOptionalOrEmpty,
  NameValidationPattern,
  PhoneRegion,
} from '../../../util/validation'

export class EditFreelancer {
  @Matches(NameValidationPattern, { message: 'First name is invalid' })
  public first_name!: string

  @IsOptionalOrEmpty()
  @Matches(NameValidationPattern, { message: 'Middle name is invalid' })
  public middle_name?: string

  @Matches(NameValidationPattern, { message: 'Last name is invalid' })
  public last_name!: string

  @IsString({ message: 'Company name is invalid' })
  @MinLength(3, { message: 'Company name is too short' })
  public company_name!: string

  @IsEmail(undefined, { message: 'Email is invalid' })
  public email!: string

  @IsPhoneNumber(PhoneRegion, { message: 'Phone number is invalid' })
  public phone_number!: string

  @Matches(NameValidationPattern, { message: 'Job title is invalid' })
  public job_title!: string

  @IsString({ message: 'Address is invalid' })
  @MinLength(3, { message: 'Address is too short' })
  public address!: string

  @Matches(NameValidationPattern, { message: 'Bank name is invalid' })
  public bank_name!: string

  @Matches(NameValidationPattern, { message: 'Account name is invalid' })
  public account_name!: string

  @IsNumberString({ message: 'Account number is invalid' })
  @Length(10, 10, { message: 'Account number is invalid' })
  public account_number!: string

  @IsBoolean({ message: 'State is either active or inactive' })
  public active!: boolean

  constructor(object?: EditFreelancer) {
    if (object) {
      this.first_name = object.first_name
      this.middle_name = object.middle_name
      this.last_name = object.last_name
      this.company_name = object.company_name
      this.email = object.email
      this.phone_number = object.phone_number
      this.job_title = object.job_title
      this.address = object.address
      this.bank_name = object.bank_name
      this.account_name = object.account_name
      this.account_number = object.account_number
      this.active = object.active
      return
    }

    this.first_name = ''
    this.middle_name = ''
    this.last_name = ''
    this.company_name = ''
    this.email = ''
    this.phone_number = ''
    this.job_title = ''
    this.address = ''
    this.bank_name = ''
    this.account_name = ''
    this.account_number = ''
    this.active = true
  }
}
