import * as React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import styled from 'styled-components'
import { Colors } from '../../style'
import { PrimarySlickButton, SlickButton } from '../button'

const Div = styled.div`
  background: #ffffffee;
  border-radius: 2.5px;
  padding: 40px;
  width: 100vw;
  max-width: 500px;
  backdrop-filter: blur(20px);
  display: block;
  flex-direction: column;

  > header {
    color: ${Colors.Header};
    margin-bottom: 30px;

    * + * {
      margin-top: 10px;
    }
  }

  > footer {
    display: flex;
    align-items: flex-end;
    flex: 1;

    > * + * {
      margin-left: 20px;
    }
  }
`

interface ConfirmDialogProps extends React.ComponentProps<any> {
  header: string
  message: string
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  header,
  message,
  children,
  ...props
}: ConfirmDialogProps) => (
  <Div {...props}>
    <header>
      <h2>{header}</h2>
      <h6>{message}</h6>
    </header>
    <footer>{children}</footer>
  </Div>
)

export interface ConfirmAlertConfig {
  header?: string
  message: string | React.ReactElement
  confirmText?: string
  cancelText?: string
  danger?: boolean
}

export const generateConfirmAlert = (
  config: ConfirmAlertConfig,
  confirmAction: Function
) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <ConfirmDialog
          header={config.header || 'Confirm'}
          message={
            (config.message as string) ||
            'Are you sure you want to carry out this action?'
          }
        >
          <PrimarySlickButton
            type="submit"
            className={config.danger ? 'danger' : ''}
            onClick={() => {
              confirmAction()
              onClose()
            }}
          >
            <span>{config.confirmText || 'Okay'}</span>
          </PrimarySlickButton>
          <SlickButton onClick={onClose} type="reset" className="cancel">
            <span>{config.cancelText || 'Cancel'}</span>
          </SlickButton>
        </ConfirmDialog>
      )
    },
  })
}
