import React from 'react'
import { DisciplinaryCaseTypesService } from '../../../../service'
import { Page } from '../../../Page'
import { SidebarLinks } from '../../links'
import { ListNamedEntitiesPage } from '../common/ListPage'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: '',
    name: 'Disciplinary Case Types',
  },
]

const View = () => (
  <ListNamedEntitiesPage
    breadcrumbs={breadcrumbs}
    service={DisciplinaryCaseTypesService}
    singular="Disciplinary Case Type"
    plural="Disciplinary Case Types"
    listLink={SettingsLinks.DisciplinaryCaseTypes}
    createLink={SettingsLinks.CreateDisciplinaryCaseType}
  />
)

export const DisciplinaryCaseTypes = Page(View, 'Disciplinary Case Types')
