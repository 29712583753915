import React from 'react'
import { OfficesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { SingleNamedEntityPage } from '../common/SinglePage'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.Offices,
    name: 'Offices',
  },
  { path: '', name: 'Office' },
]

const View: React.FC<PageComponentProps> = ({ params }) => (
  <SingleNamedEntityPage
    breadcrumbs={breadcrumbs}
    service={OfficesService}
    singular="Office"
    id={params.id}
  />
)

export const SingleOffice = Page(View, 'Office')
