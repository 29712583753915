import React from 'react'
import { JobTitlesService } from '../../../../service'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { SingleNamedEntityPage } from '../common/SinglePage'
import { SettingsLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Settings, name: 'Settings' },
  {
    path: SettingsLinks.JobTitles,
    name: 'Job Titles',
  },
  { path: '', name: 'Job Title' },
]

const View: React.FC<PageComponentProps> = ({ params }) => (
  <SingleNamedEntityPage
    breadcrumbs={breadcrumbs}
    service={JobTitlesService}
    singular="Job title"
    id={params.id}
  />
)

export const SingleJobTitle = Page(View, 'Job Title')
