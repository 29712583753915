import { CreateDisciplinaryCase } from '../model/request/disciplinary-cases'
import { DisciplinaryCase } from '../model/response'
import { ApiClient } from '../util/ApiClient'

export class DisciplinaryCasesService {
  public static async create(details: CreateDisciplinaryCase) {
    return ApiClient.post<CreateDisciplinaryCase, DisciplinaryCase>(
      'case/create',
      details,
      CreateDisciplinaryCase
    )
  }

  public static async list() {
    return ApiClient.get<DisciplinaryCase[]>('case/view')
  }

  public static async retrieve(id: number | string) {
    return ApiClient.getOne<DisciplinaryCase>('case/show', id)
  }

  public static async open(id: number | string) {
    return ApiClient.postOne<unknown, unknown>('case/open', id)
  }

  public static async close(id: number | string) {
    return ApiClient.postOne<unknown, unknown>('case/close', id)
  }
}
