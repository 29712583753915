import React, { SyntheticEvent, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Colors, Effects } from '../../style'

const DropdownContainer = styled.div`
  position: relative;
`

interface DropdownBodyProps {
  visible: boolean
  right?: boolean
}

const DropdownBody = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  border: 2px solid ${Colors.LightGrey};
  ${props => (props.right ? 'right' : 'left')}: 0;
  background: white;
  display: ${(props: DropdownBodyProps) => (props.visible ? 'block' : 'none')};
  width: 150px;
  z-index: 3;
  border-radius: 2.5px;
  box-shadow: ${Effects.DropShadow};
`

const Item = styled.button`
  background: white;
  padding: 10px 20px;
  display: flex;
  outline: none;
  appearance: none;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border: none;

  :hover,
  :focus {
    background: ${Colors.LightGrey};
  }
`

const LinkItem = styled(Link)`
  background: white;
  padding: 10px 20px;
  display: flex;
  outline: none;
  appearance: none;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border: none;

  :hover,
  :focus {
    background: ${Colors.LightGrey};
  }
`

const Shadow = styled.div`
  position: fixed;
  background: transparent;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
`

interface DropdownProps {
  component: React.ComponentType<any>
  hover?: boolean
  right?: boolean
}

const Menu: React.FC<DropdownProps> = ({
  component: Component,
  right,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)

  const handleLocalClick = (event: SyntheticEvent) => {
    event.stopPropagation()

    if (!visible) {
      setVisible(true)
      return
    }

    setVisible(false)
  }

  const handleGlobalClick = (event: SyntheticEvent) => {
    setVisible(false)
  }

  return (
    <>
      <DropdownContainer ref={containerRef} onClick={handleLocalClick}>
        <Component />
        <DropdownBody visible={visible} right={right}>
          {children}
        </DropdownBody>
      </DropdownContainer>
      {visible ? <Shadow onClick={handleGlobalClick} /> : null}
    </>
  )
}

export const Dropdown = {
  Menu,
  Item,
  LinkItem,
}
