import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { PrimaryButton, PrimaryLink } from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { DatePicker, FormTextInput } from '../../../../component/field'
import { PageBody } from '../../../../component/misc'
import { CreateAsset as CreateAssetModel } from '../../../../model/request/assets'
import { Employee, NamedEntity } from '../../../../model/response'
import {
  AssetCategoriesService,
  AssetsService,
  EmployeesService,
} from '../../../../service'
import { useLoadingMessage } from '../../../../util/hook'
import { validateWithModel } from '../../../../util/validation'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { OrganizationLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  {
    path: OrganizationLinks.Assets,
    name: 'Assets',
  },
  {
    path: '',
    name: 'Create Asset',
  },
]

const View: React.FC<PageComponentProps> = ({ history, loaderState }) => {
  const alert = useAlert()
  const { state } = useLocation<any>()
  const [categories, setCategories] = useState<NamedEntity[]>()
  const [employees, setEmployees] = useState<Employee[]>()
  const { isLoading, message, updateLoader } = useLoadingMessage()

  const createAsset = async (values: CreateAssetModel) => {
    loaderState.setLoading()
    const response = await AssetsService.create(values)
    loaderState.setDone()

    if (response.success) {
      alert.success(response.message)
      history.push(OrganizationLinks.Assets, null)
      return
    }

    alert.error(response.message)
    return
  }

  const getCategories = async () => {
    updateLoader(true, 'Fetching asset categories')
    const response = await AssetCategoriesService.list()
    updateLoader(false)

    if (response.data) {
      setCategories(response.data)
      return
    }

    alert.error(response.message)
  }

  const getEmployees = async () => {
    updateLoader(true, 'Fetching employees')
    const response = await EmployeesService.list()
    updateLoader(false)

    if (response.data) {
      setEmployees(response.data)
      return
    }

    alert.error(response.message)
  }

  useEffect(() => {
    // tslint:disable-next-line: whitespace
    ;(async () => {
      await getCategories()
      await getEmployees()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      loadingMessage={message}
    >
      <Formik
        enableReinitialize
        initialValues={
          new CreateAssetModel(
            state?.key === 'create-asset'
              ? (state?.values as CreateAssetModel)
              : undefined
          )
        }
        validate={validateWithModel(CreateAssetModel)}
        onSubmit={createAsset}
      >
        {({ handleSubmit, handleChange, isSubmitting, values }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Asset</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <FormTextInput
                  label="Name"
                  name="name"
                  placeholder="Asset Name"
                  autoCapitalize="on"
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Asset Category"
                  addLink={{
                    to: OrganizationLinks.CreateAssetCategory,
                    state: {
                      key: 'create-asset',
                      referrer: OrganizationLinks.CreateAsset,
                      field: 'asset_category_id',
                      values,
                    },
                  }}
                  as="select"
                  name="asset_category_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select asset category
                  </option>
                  {categories?.map(({ id, name }, i) => (
                    <option value={id} key={i}>
                      {name}
                    </option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Price"
                  name="price"
                  placeholder="Asset Price"
                  type="number"
                  isCurrency
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Description"
                  name="description"
                  placeholder="Description"
                  autoCapitalize="on"
                  as="textarea"
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Beneficiary"
                  as="select"
                  name="beneficiary_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select beneficiary
                  </option>
                  {employees?.map(({ id, first_name, last_name }, i) => (
                    <option value={id} key={i}>
                      {first_name} {last_name}
                    </option>
                  ))}
                </FormTextInput>
                <DatePicker
                  label="Date Acquired"
                  name="date_acquired"
                  maxDate={new Date()}
                  disabled={isSubmitting}
                />
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink
                to={
                  isSubmitting
                    ? '#'
                    : { pathname: OrganizationLinks.Assets, state: null }
                }
              >
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  )
}

export const CreateAsset = Page(View, 'Create Asset')
