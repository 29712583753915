import {
  ArrayMinSize,
  ArrayUnique,
  IsArray,
  IsNumberString,
} from 'class-validator'
import { Job } from '../../response'

export class SetTeam {
  @ArrayMinSize(2, { message: 'Select at least two team members' })
  @ArrayUnique({ message: 'Each member must appear only once' })
  @IsArray()
  @IsNumberString({ each: true, message: ':value is undefined' })
  public member_ids!: string[]

  constructor(job?: Job) {
    this.member_ids = job?.team?.members.map(({ id }) => `${id}`) || []
  }
}
