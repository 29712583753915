import { Formik, FormikErrors } from 'formik'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { useLocation } from 'react-router-dom'
import { PrimaryButton, PrimaryLink } from '../../../component/button'
import { CreateEditForm } from '../../../component/container'
import { DatePicker, FormTextInput } from '../../../component/field'
import { PageBody } from '../../../component/misc'
import { CreateEmployee as CreateEmployeeModel } from '../../../model/request/employees'
import { NamedEntity } from '../../../model/response'
import {
  EmployeesService,
  JobTitlesService,
  OfficesService,
} from '../../../service'
import { Permissions, RoleNames } from '../../../util/constants'
import { validateWithModel } from '../../../util/validation'
import { Page, PageComponentProps } from '../../Page'
import { SidebarLinks } from '../links'
import { SettingsLinks } from '../settings/links'
import { EmployeesLinks } from './links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  {
    path: SidebarLinks.Employees,
    name: 'Employees',
  },
  {
    path: '',
    name: 'Create Employee',
  },
]

const View: React.FC<PageComponentProps> = ({
  history,
  loaderState,
  permissionsState,
}) => {
  const alert = useAlert()
  const { state } = useLocation()
  const [offices, setOffices] = useState<NamedEntity[]>()
  const [jobTitles, setJobTitles] = useState<NamedEntity[]>()

  const getDependencies = async () => {
    loaderState.setLoading()
    setOffices((await OfficesService.list()).data)
    setJobTitles((await JobTitlesService.list()).data)
    loaderState.setDone()
  }

  useEffect(() => {
    getDependencies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody breadcrumbs={breadcrumbs}>
      <Formik
        initialValues={
          new CreateEmployeeModel(
            (state as any)?.key === 'create-employee'
              ? ((state as any)?.values as CreateEmployeeModel)
              : undefined
          )
        }
        validate={validateWithModel(CreateEmployeeModel)}
        onSubmit={async (values, { setErrors }) => {
          loaderState.setLoading()
          const response = await EmployeesService.create(values)
          loaderState.setDone()

          if (response.success) {
            alert.success('Employee created successfully')
            history.push(SidebarLinks.Employees, null)
            return
          }

          alert.error(
            'There was a problem with your data. Check the form again'
          )
          setErrors(response.errors as FormikErrors<CreateEmployeeModel>)
        }}
      >
        {({ handleChange, handleSubmit, isSubmitting, values }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Employee</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <span>Basic Information</span>
                <FormTextInput
                  label="First Name"
                  name="first_name"
                  placeholder="First name"
                  autoCapitalize="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Middle Name"
                  name="middle_name"
                  placeholder="Middle name"
                  autoCapitalize="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Last Name"
                  name="last_name"
                  placeholder="Last name"
                  autoCapitalize="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  disabled={isSubmitting}
                />
                <DatePicker
                  label="Date of Employment"
                  name="date_of_employment"
                  placeholder="Date of employment"
                  maxDate={new Date()}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Office Designation"
                  addLink={
                    permissionsState.hasPermission(
                      Permissions.SystemConfiguration
                    )
                      ? {
                          to: SettingsLinks.CreateOffice,
                          state: {
                            key: 'create-employee',
                            referrer: EmployeesLinks.CreateEmployee,
                            field: 'office_id',
                            values,
                          },
                        }
                      : undefined
                  }
                  as="select"
                  name="office_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select office
                  </option>
                  {offices?.map(({ id, name }, i) => (
                    <option value={id} key={i}>
                      {name}
                    </option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Role"
                  as="select"
                  name="role"
                  disabled={isSubmitting}
                >
                  <option value="" disabled>
                    Select role
                  </option>
                  {Object.values(RoleNames).map((role, i) => (
                    <option value={role} key={i}>
                      {role}
                    </option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Job Title"
                  addLink={
                    permissionsState.hasPermission(
                      Permissions.SystemConfiguration
                    )
                      ? {
                          to: SettingsLinks.CreateJobTitle,
                          state: {
                            key: 'create-employee',
                            referrer: EmployeesLinks.CreateEmployee,
                            field: 'job_title_id',
                            values,
                          },
                        }
                      : undefined
                  }
                  as="select"
                  name="job_title_id"
                  disabled={isSubmitting}
                >
                  <option value={0} disabled>
                    Select job title
                  </option>
                  {jobTitles?.map(({ id, name }, i) => (
                    <option value={id} key={i}>
                      {name}
                    </option>
                  ))}
                </FormTextInput>
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink
                to={
                  isSubmitting
                    ? '#'
                    : { pathname: SidebarLinks.Employees, state: null }
                }
              >
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  )
}

export const CreateEmployee = Page(View, 'Create Employee')
