import {
  CreateNamedEntity,
  EditNamedEntity,
} from '../../../model/request/named-entities'

export const createNamedEntityInitialValues: CreateNamedEntity = {
  name: '',
}

export const editNamedEntityInitialValues: EditNamedEntity = {
  name: '',
  active: true,
}

export enum Filters {
  All,
  Active,
  Inactive,
}

export const filters = {
  [Filters.All]: {},
  [Filters.Active]: { active: true },
  [Filters.Inactive]: { active: false },
}
