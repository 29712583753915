import React, { ReactNodeArray } from 'react'
import styled from 'styled-components'
import { Colors, Effects } from '../../style'

export const CreateEditForm = styled.form`
  width: 100%;
  max-width: 450px;
  backdrop-filter: blur(20px);
  display: block;
  flex-direction: column;
  border: none;
  padding: 0;
  margin: 0;

  > * {
    width: 100%;
  }

  > header {
    margin-bottom: 30px;

    * + * {
      margin-top: 10px;
    }
  }

  > main > * + * {
    margin-top: 40px;
  }

  > main > section {
    display: flex;
    flex-direction: column;
    width: 100%;

    > * + * {
      margin-top: 20px;
    }
  }

  > footer {
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    flex: 1;

    > * + * {
      margin-left: 20px;
    }
  }
`

const Divider = styled.div`
  width: 100vw;
  max-width: 920px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  > main > section {
    width: 100%;
    max-width: 450px;
    display: block;
    flex-direction: column;
    padding: 30px 30px 50px;
    border: 2px solid ${Colors.LightGrey};
    box-shadow: ${Effects.DropShadow};
    background: white;

    > * + * {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 1400px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`

export const FormDivider: React.FC = ({ children }) => (
  <Divider>
    {Array.isArray(children) ? (
      (children as ReactNodeArray).map((component, i) => (
        <main key={i}>{component}</main>
      ))
    ) : (
      <main>{children}</main>
    )}
  </Divider>
)
