import { Formik } from 'formik'
import { isMatch } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { MoreHorizontal } from 'react-feather'
import ReactModal from 'react-modal'
import { Link } from 'react-router-dom'
import { Row } from 'react-table'
import { generateConfirmAlert } from '../../../../component/alert'
import { PrimarySlickButton, SlickLink } from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { DatePicker, FormTextInput } from '../../../../component/field'
import { Dropdown, PageBody, Table } from '../../../../component/misc'
import { EditAsset } from '../../../../model/request/assets'
import { Asset, Employee, NamedEntity } from '../../../../model/response'
import {
  AssetCategoriesService,
  AssetsService,
  EmployeesService,
} from '../../../../service'
import { Permissions } from '../../../../util/constants'
import { useLoadingMessage } from '../../../../util/hook'
import { formatCurrency, validateWithModel } from '../../../../util/validation'
import { Page, PageComponentProps } from '../../../Page'
import { NotCreated } from '../../common'
import { SidebarLinks } from '../../links'
import { links, OrganizationLinks } from '../links'

ReactModal.setAppElement('#root')

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Organization, name: 'Organization' },
  { path: OrganizationLinks.Assets, name: 'Assets' },
]

const View: React.FC<PageComponentProps> = ({
  loaderState,
  permissionsState: { hasPermission },
}) => {
  const alert = useAlert()
  const [assets, setAssets] = useState<Asset[]>()
  const [categories, setCategories] = useState<NamedEntity[]>()
  const [employees, setEmployees] = useState<Employee[]>()
  const [activeAsset, setActiveAsset] = useState<Asset>()
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { isLoading, message, updateLoader } = useLoadingMessage()

  const handleEditAsset = (asset: Asset) => {
    setActiveAsset(asset)
    setEditModalOpen(true)
  }

  const editAsset = (values: EditAsset) => {
    if (isMatch(new EditAsset(activeAsset), values)) {
      alert.info('No changes were made')
      setEditModalOpen(false)
      return
    }

    const update = async () => {
      loaderState.setLoading()
      const response = await AssetsService.update(
        activeAsset?.id as number,
        values
      )
      loaderState.setDone()

      if (response.success) {
        alert.success(response.message)
        setEditModalOpen(false)
        await getAssets()
        return
      }

      alert.error(response.message)
      return
    }

    generateConfirmAlert(
      {
        header: `Edit asset`,
        message: `Are you sure you want to save changes to this asset?`,
        confirmText: `Yes, save`,
        cancelText: 'No, cancel',
      },
      update
    )
  }

  const handleDeleteAsset = (id: number) => {
    const deleteRequest = async () => {
      loaderState.setLoading()
      const response = await AssetsService.delete(id)
      loaderState.setDone()

      if (response.success) {
        alert.success(response.message)
        await getAssets()
        return
      }

      alert.error(response.message)
    }

    generateConfirmAlert(
      {
        header: `Delete asset`,
        message: `Are you sure you want to delete this asset?`,
        confirmText: `Yes, delete!`,
        cancelText: 'No, cancel',
      },
      deleteRequest
    )
  }

  const getAssets = async () => {
    updateLoader(true, 'Fetching assets')
    const response = await AssetsService.list()
    updateLoader(false)

    if (response.data) {
      setAssets(response.data)
      return
    }

    alert.error(response.message)
  }

  const getCategories = async () => {
    updateLoader(true, 'Fetching asset categories')
    const response = await AssetCategoriesService.list()
    updateLoader(false)

    if (response.data) {
      setCategories(response.data)
      return
    }

    alert.error(response.message)
  }

  const getEmployees = async () => {
    updateLoader(true, 'Fetching employees')
    const response = await EmployeesService.list()
    updateLoader(false)

    if (response.data) {
      setEmployees(response.data)
      return
    }

    alert.error(response.message)
  }

  useEffect(() => {
    // tslint:disable-next-line: whitespace
    ;(async () => {
      await getCategories()
      await getEmployees()
      await getAssets()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Assets"
      pageLinks={links}
      isLoading={isLoading}
      loadingMessage={message}
      tagline="View / manage the office assets on the platform"
      actionArea={
        hasPermission(Permissions.AdminViewAccess) ? (
          <SlickLink to={OrganizationLinks.CreateAsset}>
            <span>Create Asset</span>
          </SlickLink>
        ) : null
      }
    >
      {assets?.length ? (
        <>
          <Table
            columns={[
              { Header: 'No.', accessor: 'id' },
              { Header: 'Name', accessor: 'name' },
              {
                Header: 'Price',
                accessor: 'price',
                Cell: ({
                  row: {
                    original: { price },
                  },
                }: {
                  row: Row<Asset>
                }) => {
                  return formatCurrency(price)
                },
              },
              { Header: 'Description', accessor: 'description' },
              {
                Header: 'Category',
                accessor: ({ category }: Asset) => (
                  <Link
                    to={`${OrganizationLinks.AssetCategories}/${category.id}`}
                  >
                    {category.name}
                  </Link>
                ),
              },
              {
                Header: 'Beneficiary',
                accessor: ({ beneficiary }: Asset) => (
                  <Link to={`${SidebarLinks.Employees}/${beneficiary.slug}`}>
                    {beneficiary.first_name} {beneficiary.last_name}
                  </Link>
                ),
                sortType: (a: any, b: any) => {
                  return `${a.first_name} ${a.last_name}`.toLowerCase() >
                    `${b.first_name} ${b.last_name}`.toLowerCase()
                    ? 1
                    : -1
                },
              },
              {
                Header: 'Date Acquired',
                accessor: ({ date_acquired }: Asset) =>
                  moment(date_acquired).format('LL'),
                sortType: (a: any, b: any) => {
                  return moment(a).diff(b, 'd')
                },
              },
              ...(hasPermission(Permissions.AdminViewAccess)
                ? [
                    {
                      Header: ' ',
                      disableSortBy: true,
                      accessor: (asset: Asset) => (
                        <Dropdown.Menu right component={MoreHorizontal}>
                          <Dropdown.Item
                            onClick={() => {
                              handleEditAsset(asset)
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              handleDeleteAsset(asset.id)
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      ),
                    },
                  ]
                : []),
            ]}
            data={assets}
          />
          {editModalOpen ? (
            <ReactModal
              overlayClassName="modal__overlay"
              className="modal"
              isOpen={true}
              onRequestClose={setEditModalOpen.bind(undefined, false)}
            >
              <Formik
                enableReinitialize
                initialValues={new EditAsset(activeAsset)}
                validate={validateWithModel(EditAsset)}
                onSubmit={editAsset}
              >
                {({ handleSubmit, handleChange, isSubmitting }) => (
                  <CreateEditForm onSubmit={handleSubmit}>
                    <header>
                      <h2>Edit Asset</h2>
                      <h6>Fill in the required details below</h6>
                    </header>
                    <main>
                      <section>
                        <FormTextInput
                          label="Name"
                          name="name"
                          placeholder="Asset Name"
                          autoCapitalize="on"
                          disabled={isSubmitting}
                        />
                        <FormTextInput
                          label="Asset Category"
                          as="select"
                          name="asset_category_id"
                          disabled={isSubmitting}
                        >
                          <option value={0} disabled>
                            Select asset category
                          </option>
                          {categories?.map(({ id, name }, i) => (
                            <option value={id} key={i}>
                              {name}
                            </option>
                          ))}
                        </FormTextInput>
                        <FormTextInput
                          label="Price"
                          name="price"
                          placeholder="Asset Price"
                          type="number"
                          disabled={isSubmitting}
                        />
                        <FormTextInput
                          label="Description"
                          name="description"
                          placeholder="Description"
                          autoCapitalize="on"
                          as="textarea"
                          handleChange={handleChange}
                          disabled={isSubmitting}
                        />
                        <FormTextInput
                          label="Beneficiary"
                          as="select"
                          name="beneficiary_id"
                          disabled={isSubmitting}
                        >
                          <option value={0} disabled>
                            Select beneficiary
                          </option>
                          {employees?.map(
                            ({ id, first_name, last_name }, i) => (
                              <option value={id} key={i}>
                                {first_name} {last_name}
                              </option>
                            )
                          )}
                        </FormTextInput>
                        <DatePicker
                          label="Date Acquired"
                          name="date_acquired"
                          disabled={isSubmitting}
                        />
                      </section>
                    </main>
                    <footer>
                      <PrimarySlickButton type="submit">
                        <span>Save</span>
                      </PrimarySlickButton>
                    </footer>
                  </CreateEditForm>
                )}
              </Formik>
            </ReactModal>
          ) : null}
        </>
      ) : (
        NotCreated('asset', 'assets', OrganizationLinks.CreateAsset)
      )}
    </PageBody>
  )
}

export const Assets = Page(View, 'Leave Requests')
