import { IsPositive, Matches } from 'class-validator'
import {
  DateValidationPattern,
  getFormattedDate,
  IsNotBeforeOrAfter,
  IsNotWeekend,
} from '../../../util/validation'
import { LeaveRequest } from '../../response'
import moment from 'moment'

export class EditLeaveRequest {
  @IsNotBeforeOrAfter(undefined, false, {
    message: 'End date cannot be in the past',
  })
  @IsNotWeekend({
    message: 'Start date cannot be on a weekend',
  })
  @Matches(DateValidationPattern, {
    message: 'Start date is invalid',
  })
  public start_date!: string

  @IsNotBeforeOrAfter('start_date', false, {
    message: 'End date cannot be before the start date',
  })
  @IsNotWeekend({
    message: 'End date cannot be on a weekend',
  })
  @Matches(DateValidationPattern, {
    message: 'End date is invalid',
  })
  public end_date!: string

  @IsPositive({ message: 'Creator is invalid' })
  public creator_id!: number

  @IsPositive({ message: 'Leave type is invalid' })
  public leave_type!: number

  constructor(object?: LeaveRequest) {
    this.start_date = object?.start_date || getFormattedDate()
    this.end_date = object?.end_date || getFormattedDate()
    this.creator_id = object?.leave_request_creator.id || 0
    this.leave_type = object?.leave_type.id || 0
  }

  public static transform(object: EditLeaveRequest) {
    object.creator_id = Number.parseInt(object.creator_id as any, 10)
    object.leave_type = Number.parseInt(object.leave_type as any, 10)

    if (moment(object.end_date).isBefore(object.start_date)) {
      object.end_date = object.start_date
    }
  }
}
