import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { SpecialRoute } from '../../component/misc'
import { SidebarLinks } from '../../page/main/links'
import { ApprovalLimits } from '../../page/main/settings/ApprovalLimits'
import { CreateDisciplinaryCaseType } from '../../page/main/settings/disciplinary-case-types/CreateDisciplinaryCaseType'
import { DisciplinaryCaseTypes } from '../../page/main/settings/disciplinary-case-types/DisciplinaryCaseTypes'
import { SingleDisciplinaryCaseType } from '../../page/main/settings/disciplinary-case-types/SingleDisciplinaryCaseType'
import { CreateEventCategory } from '../../page/main/settings/event-categories/CreateEventCategory'
import { EventCategories } from '../../page/main/settings/event-categories/EventCategories'
import { SingleEventCategory } from '../../page/main/settings/event-categories/SingleEventCategory'
import { CreateJobTitle } from '../../page/main/settings/job-titles/CreateJobTitle'
import { JobTitles } from '../../page/main/settings/job-titles/JobTitles'
import { SingleJobTitle } from '../../page/main/settings/job-titles/SingleJobTitle'
import { CreateJobType } from '../../page/main/settings/job-types/CreateJobType'
import { JobTypes } from '../../page/main/settings/job-types/JobTypes'
import { SingleJobType } from '../../page/main/settings/job-types/SingleJobType'
import { JobStages } from '../../page/main/settings/JobStages'
import { CreateLeave } from '../../page/main/settings/leaves/CreateLeave'
import { Leaves } from '../../page/main/settings/leaves/Leaves'
import { SingleLeave } from '../../page/main/settings/leaves/SingleLeave'
import { SettingsLinks } from '../../page/main/settings/links'
import { CreateOffice } from '../../page/main/settings/offices/CreateOffice'
import { Offices } from '../../page/main/settings/offices/Offices'
import { SingleOffice } from '../../page/main/settings/offices/SingleOffice'
import { Roles } from '../../page/main/settings/Roles'
import { Permissions } from '../../util/constants'

export const SettingsRoutes = () => (
  <SpecialRoute
    path={SidebarLinks.Settings}
    isPrivate
    permission={Permissions.SystemConfiguration}
  >
    <Switch>
      <Route exact path={SidebarLinks.Settings} component={Roles} />
      <Route exact path={SettingsLinks.JobStages} component={JobStages} />
      <Route exact path={SettingsLinks.Leaves} component={Leaves} />
      <Route exact path={SettingsLinks.CreateLeave} component={CreateLeave} />
      <Route
        exact
        path={`${SettingsLinks.Leaves}/:id`}
        component={SingleLeave}
      />
      {/* Job Titles */}
      <Route exact path={SettingsLinks.JobTitles} component={JobTitles} />
      <Route
        exact
        path={`${SettingsLinks.JobTitles}/create`}
        component={CreateJobTitle}
      />
      <Route
        exact
        path={`${SettingsLinks.JobTitles}/:id`}
        component={SingleJobTitle}
      />
      {/* Job Types */}
      <Route exact path={SettingsLinks.JobTypes} component={JobTypes} />
      <Route
        exact
        path={`${SettingsLinks.JobTypes}/create`}
        component={CreateJobType}
      />
      <Route
        exact
        path={`${SettingsLinks.JobTypes}/:id`}
        component={SingleJobType}
      />
      {/* Offices */}
      <Route exact path={SettingsLinks.Offices} component={Offices} />
      <Route
        exact
        path={`${SettingsLinks.Offices}/create`}
        component={CreateOffice}
      />
      <Route
        exact
        path={`${SettingsLinks.Offices}/:id`}
        component={SingleOffice}
      />
      {/* Event Categories */}
      <Route
        exact
        path={SettingsLinks.EventCategories}
        component={EventCategories}
      />
      <Route
        exact
        path={`${SettingsLinks.EventCategories}/create`}
        component={CreateEventCategory}
      />
      <Route
        exact
        path={`${SettingsLinks.EventCategories}/:id`}
        component={SingleEventCategory}
      />
      {/* Disciplinary Case Types */}
      <Route
        exact
        path={SettingsLinks.DisciplinaryCaseTypes}
        component={DisciplinaryCaseTypes}
      />
      <Route
        exact
        path={`${SettingsLinks.DisciplinaryCaseTypes}/create`}
        component={CreateDisciplinaryCaseType}
      />
      <Route
        exact
        path={`${SettingsLinks.DisciplinaryCaseTypes}/:id`}
        component={SingleDisciplinaryCaseType}
      />
      <Route path={SettingsLinks.ApprovalLimits} component={ApprovalLimits} />
    </Switch>
  </SpecialRoute>
)
