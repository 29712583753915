import { Formik } from 'formik'
import { isMatch } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import {
  CancelSlickButton,
  PrimarySlickButton,
  SlickButton,
} from '../../../component/button'
import { CreateEditForm, FormDivider } from '../../../component/container'
import { DatePicker, FormTextInput, Switch } from '../../../component/field'
import { Loader, PageBody } from '../../../component/misc'
import { SetAccountDetails } from '../../../model/request/account-details.model'
import {
  AdminEditEmployee,
  SetNextOfKin,
} from '../../../model/request/employees'
import {
  AccountDetails,
  Employee,
  NamedEntity,
  NextOfKin,
} from '../../../model/response'
import {
  EmployeesService,
  JobTitlesService,
  OfficesService,
} from '../../../service'
import {
  Banks,
  HiddenRoleNames,
  Permissions,
  RoleNames,
} from '../../../util/constants'
import { useLoadingMessage } from '../../../util/hook'
import { Genders, validateWithModel } from '../../../util/validation'
import { Page, PageComponentProps } from '../../Page'
import { SidebarLinks } from '../links'
import { ProfilePicturePond } from './ProfilePicturePond'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Employees, name: 'Employees' },
  {
    path: '',
    name: 'Employee',
  },
]

const View: React.FC<PageComponentProps> = ({
  history,
  params,
  authState,
  loaderState,
  permissionsState,
}) => {
  const alert = useAlert()
  const [employee, setEmployee] = useState<Employee>()
  const [employeeDetails, setEmployeeDetails] = useState(
    new AdminEditEmployee()
  )
  const [offices, setOffices] = useState<NamedEntity[]>()
  const [jobTitles, setJobTitles] = useState<NamedEntity[]>()
  const [editMode, setEditMode] = useState(false)
  const { isLoading, message, updateLoader } = useLoadingMessage()

  const getDependencies = async () => {
    updateLoader(true, 'Fetching employee information')
    await getEmployee()
    updateLoader(false)
    setOffices((await OfficesService.list()).data)
    setJobTitles((await JobTitlesService.list()).data)
  }

  const getEmployee = async () => {
    const response = (await EmployeesService.retrieve(params.slug)).data
    setEmployee(response)
    setEmployeeDetails(new AdminEditEmployee(response as Employee))
  }

  useEffect(() => {
    if (!permissionsState.userAllowed(params.slug)) {
      history.replace(SidebarLinks.Employees)
      return
    }

    getDependencies()
    // eslint-disable-next-line
  }, [params.slug])

  return (
    <Formik
      enableReinitialize
      validate={validateWithModel(AdminEditEmployee)}
      initialValues={employeeDetails}
      onSubmit={async (values, { resetForm }) => {
        const {
          next_of_kin: _1,
          account_details: _2,
          ...original
        } = employeeDetails
        const { next_of_kin, account_details, ...details } = values

        let updated = false
        let hasChange = false
        let newEmployee = employee

        if (!isMatch(original, details)) {
          hasChange = true

          loaderState.setLoading('Updating employee')
          const response = await EmployeesService.update(
            params.slug,
            details as AdminEditEmployee
          )

          if (response.success) {
            newEmployee = {
              ...employee,
              ...(response.data as Employee),
              ...details,
            }
            updated = true
            setEditMode(false)
          } else {
            alert.error(response.message)
          }
        }

        if (
          !isMatch(
            employeeDetails?.next_of_kin as SetNextOfKin,
            next_of_kin as SetNextOfKin
          )
        ) {
          hasChange = true

          loaderState.setLoading('Updating next of kin details')
          const response = await EmployeesService.setNextOfKin(
            params.slug,
            next_of_kin as SetNextOfKin
          )

          if (response.success) {
            newEmployee = {
              ...(employee as Employee),
              next_of_kin: response.data as NextOfKin,
            }
            updated = true
            setEditMode(false)
          }
        }

        if (
          !isMatch(
            employeeDetails?.account_details as SetAccountDetails,
            account_details as SetAccountDetails
          )
        ) {
          hasChange = true

          loaderState.setLoading('Updating account details')
          const response = await EmployeesService.setAccountDetails(
            params.slug,
            account_details as SetAccountDetails
          )

          if (response.success) {
            newEmployee = {
              ...(employee as Employee),
              account_details: response.data as AccountDetails,
            }
            updated = true
            setEditMode(false)
          }
        }

        loaderState.setDone()

        if (!hasChange) {
          alert.info('No changes were made')
          setEditMode(false)
          resetForm()
          return
        }

        if (updated) {
          alert.success('Employee details updated')
          setEmployee(newEmployee)
          const newFormDetails = new AdminEditEmployee(newEmployee)

          newFormDetails.job_title_id = details.job_title_id
          newFormDetails.office_id = details.office_id

          setEmployeeDetails(newFormDetails)
          resetForm({ values: employeeDetails, touched: {} })

          if (newEmployee?.slug === authState.user?.slug) {
            alert.info(
              'Your details have changed. The application has refreshed'
            )
            authState.setUser(newEmployee as Employee)
          }
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        resetForm,
        isSubmitting,
        initialValues,
      }) => (
        <Loader isLoading={isLoading} message={message}>
          <PageBody
            breadcrumbs={breadcrumbs}
            title={`${employee?.first_name} ${employee?.last_name}${
              authState.user?.slug === employee?.slug ? ' (You)' : ''
            }`}
            tagline={employee?.active ? 'Active' : 'Inactive'}
            actionArea={
              editMode ? (
                <>
                  <CancelSlickButton
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => {
                      resetForm()
                      setEditMode(false)
                    }}
                  >
                    <span>Cancel</span>
                  </CancelSlickButton>
                  <PrimarySlickButton
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    <span>Save</span>
                  </PrimarySlickButton>
                </>
              ) : (
                <SlickButton
                  onClick={() => {
                    setEditMode(true)
                  }}
                >
                  <span>Edit</span>
                </SlickButton>
              )
            }
          >
            <CreateEditForm onSubmit={handleSubmit}>
              <main>
                {editMode &&
                permissionsState.hasPermission(Permissions.AdminViewAccess) ? (
                  <section>
                    <Switch
                      name="active"
                      label="Status"
                      disabled={
                        authState.user?.slug === employee?.slug || isSubmitting
                      }
                    />
                  </section>
                ) : null}
                <section>
                  <small>Employee Number: {employee?.employee_number}</small>
                  <ProfilePicturePond slug={employee?.slug as string} />
                </section>
                <FormDivider>
                  <section>
                    <span>Employee Information</span>
                    <FormTextInput
                      label="Email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      disabled={
                        !editMode ||
                        isSubmitting ||
                        !permissionsState.hasPermission(
                          Permissions.AdminViewAccess
                        )
                      }
                    />
                    <FormTextInput
                      label="Office Designation"
                      as="select"
                      name="office_id"
                      disabled={
                        !editMode ||
                        isSubmitting ||
                        !permissionsState.hasPermission(
                          Permissions.AdminViewAccess
                        )
                      }
                    >
                      <option value={0} disabled>
                        Select office
                      </option>
                      {offices?.map(({ id, name }, i) => (
                        <option value={id} key={i}>
                          {name}
                        </option>
                      ))}
                    </FormTextInput>
                    <FormTextInput
                      label="Job Title"
                      as="select"
                      name="job_title_id"
                      disabled={
                        !editMode ||
                        isSubmitting ||
                        !permissionsState.hasPermission(
                          Permissions.AdminViewAccess
                        )
                      }
                    >
                      <option value={0} disabled>
                        Select job title
                      </option>
                      {jobTitles?.map(({ id, name }, i) => (
                        <option value={id} key={i}>
                          {name}
                        </option>
                      ))}
                    </FormTextInput>
                    <DatePicker
                      label="Date of Employment"
                      name="date_of_employment"
                      placeholder="Date of employment"
                      disabled={
                        !editMode ||
                        isSubmitting ||
                        !permissionsState.hasPermission(
                          Permissions.AdminViewAccess
                        )
                      }
                    />
                    <FormTextInput
                      label="Role"
                      as="select"
                      name="role"
                      disabled={
                        !editMode ||
                        isSubmitting ||
                        !permissionsState.hasPermission(
                          Permissions.AdminViewAccess
                        )
                      }
                    >
                      <option value="" disabled>
                        Select role
                      </option>
                      {Object.values(RoleNames).map((role, i) => (
                        <option value={role} key={i}>
                          {role}
                        </option>
                      ))}
                      {initialValues.role === HiddenRoleNames.SuperAdmin ? (
                        <option value={HiddenRoleNames.SuperAdmin}>
                          {HiddenRoleNames.SuperAdmin}
                        </option>
                      ) : null}
                    </FormTextInput>
                  </section>
                  <section>
                    <span>Personal Information</span>
                    <FormTextInput
                      label="First Name"
                      name="first_name"
                      placeholder="First name"
                      autoCapitalize="on"
                      disabled={!editMode || isSubmitting}
                      trim
                      handleChange={handleChange}
                    />
                    <FormTextInput
                      label="Middle Name"
                      name="middle_name"
                      placeholder="Middle name"
                      autoCapitalize="on"
                      disabled={!editMode || isSubmitting}
                      trim
                      handleChange={handleChange}
                    />
                    <FormTextInput
                      label="Last Name"
                      name="last_name"
                      placeholder="Last name"
                      autoCapitalize="on"
                      disabled={!editMode || isSubmitting}
                      trim
                      handleChange={handleChange}
                    />
                    <DatePicker
                      label="Date of Birth"
                      name="date_of_birth"
                      placeholder="Date of birth"
                      maxDate={new Date()}
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Phone Number"
                      name="phone_number"
                      placeholder="Phone number"
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Gender"
                      as="select"
                      name="gender"
                      disabled={!editMode || isSubmitting}
                    >
                      <option value="" disabled>
                        Select gender
                      </option>
                      {Object.values(Genders).map((gender, i) => (
                        <option value={gender} key={i}>
                          {gender}
                        </option>
                      ))}
                    </FormTextInput>
                  </section>
                </FormDivider>
                <FormDivider>
                  <section>
                    <span>Next of Kin</span>
                    <FormTextInput
                      label="Name"
                      placeholder="Name"
                      name="next_of_kin.name"
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Relationship"
                      placeholder="Relationship"
                      name="next_of_kin.relationship"
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Address"
                      placeholder="Address"
                      name="next_of_kin.address"
                      as="textarea"
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Phone Number"
                      placeholder="Phone number"
                      name="next_of_kin.phone_number"
                      disabled={!editMode || isSubmitting}
                    />
                  </section>
                  <section>
                    <span>Account Details</span>
                    <FormTextInput
                      label="Bank Name"
                      placeholder="Bank name"
                      name="account_details.bank_name"
                      as="select"
                      disabled={!editMode || isSubmitting}
                    >
                      <option value="" disabled>
                        Select bank
                      </option>
                      {Banks.map((bank, i) => (
                        <option key={i}>{bank}</option>
                      ))}
                    </FormTextInput>
                    <FormTextInput
                      label="Account Name"
                      placeholder="Account name"
                      name="account_details.account_name"
                      disabled={!editMode || isSubmitting}
                    />
                    <FormTextInput
                      label="Account Number"
                      placeholder="Account number"
                      name="account_details.account_number"
                      disabled={!editMode || isSubmitting}
                    />
                  </section>
                </FormDivider>
                {employee?.role === HiddenRoleNames.SuperAdmin ? null : (
                  <FormDivider>
                    <section>
                      <span>Leave Summary</span>
                      <br />
                      <small>Number of days remaining</small>
                      {employee?.leave_summary.map(
                        ({ leave, days_approved, days_remaining }) => (
                          <FormTextInput
                            key={leave.id}
                            label={leave.name}
                            name="leave.name"
                            value={`${days_remaining} out of ${days_approved}`}
                            disabled
                          />
                        )
                      )}
                    </section>
                  </FormDivider>
                )}
              </main>
            </CreateEditForm>
          </PageBody>
        </Loader>
      )}
    </Formik>
  )
}

export const SingleEmployee = Page(View, 'Employee')
