import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Colors } from '../../style'

export const SlickButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  background: #181a1f;
  border: 2px solid #181a1f;
  box-sizing: border-box;
  border-radius: 20px;
  outline: none;
  transition: all 0.5s ease;
  font-size: 85%;
  font-weight: bold;

  &:hover {
    background: ${Colors.DarkGrey};
    border-color: ${Colors.DarkGrey};
  }

  * {
    color: white;
  }
`

export const SlickLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  background: #181a1f;
  border: 2px solid #181a1f;
  box-sizing: border-box;
  border-radius: 20px;
  outline: none;
  transition: all 0.5s ease;
  font-size: 85%;
  font-weight: bold;

  &:hover {
    background: ${Colors.DarkGrey};
    border-color: ${Colors.DarkGrey};
  }

  * {
    color: white;
  }
`

export const PrimarySlickButton = styled(SlickButton)`
  background: ${Colors.Primary};
  border-color: ${Colors.Primary};

  &:hover {
    background: ${Colors.PrimaryDark};
    border-color: ${Colors.PrimaryDark};
  }
`

export const RedSlickButton = styled(SlickButton)`
  background: ${Colors.Red};
  border-color: ${Colors.Red};

  &:hover {
    background: ${Colors.Red};
    border-color: ${Colors.Red};
  }
`

export const CancelSlickButton = styled(SlickButton)`
  background: ${Colors.CancelButton};
  border-color: ${Colors.CancelButtonStroke};

  * {
    color: ${Colors.CancelButtonText};
  }

  &:hover {
    background: ${Colors.CancelButtonStroke};
    border-color: ${Colors.CancelButtonStroke};
  }
`
