import { Formik, FormikErrors } from 'formik'
import React from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { PrimaryButton, PrimaryLink } from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { FormTextInput } from '../../../../component/field'
import { PageBody } from '../../../../component/misc'
import { CreateFreelancer as CreateFreelancerModel } from '../../../../model/request/freelancers'
import { FreelancersService } from '../../../../service'
import { Banks } from '../../../../util/constants'
import { validateWithModel } from '../../../../util/validation'
import { Page, PageComponentProps } from '../../../Page'
import { SidebarLinks } from '../../links'
import { EmployeesLinks } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Employees, name: 'Employees' },
  {
    path: EmployeesLinks.Freelancers,
    name: 'Freelancers',
  },
  {
    path: '',
    name: 'Create Freelancer',
  },
]

const View: React.FC<PageComponentProps> = ({ history, loaderState }) => {
  const alert = useAlert()

  return (
    <PageBody breadcrumbs={breadcrumbs}>
      <Formik
        initialValues={new CreateFreelancerModel()}
        validate={validateWithModel(CreateFreelancerModel)}
        onSubmit={async (values, { setErrors }) => {
          loaderState.setLoading('Creating freelancer')
          const response = await FreelancersService.create(values)
          loaderState.setDone()

          if (response.success) {
            alert.success('Freelancer created successfully')
            history.push(EmployeesLinks.Freelancers)
            return
          }

          alert.error(
            'There was a problem with your data. Check the form again'
          )
          setErrors(response.errors as FormikErrors<CreateFreelancerModel>)
        }}
      >
        {({ handleChange, handleSubmit, isSubmitting }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Freelancer</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <span>Basic Information</span>
                <FormTextInput
                  label="First Name"
                  name="first_name"
                  placeholder="First name"
                  autoCapitalize="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Middle Name"
                  name="middle_name"
                  placeholder="Middle name"
                  autoCapitalize="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Last Name"
                  name="last_name"
                  placeholder="Last name"
                  autoCapitalize="on"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
              </section>
              <section>
                <span>Contact Information</span>
                <FormTextInput
                  label="Company Name"
                  name="company_name"
                  placeholder="Company name"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Phone Number"
                  name="phone_number"
                  placeholder="Phone number"
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Job Title"
                  placeholder="Job title"
                  name="job_title"
                  trim
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Address"
                  placeholder="Address"
                  as="textarea"
                  name="address"
                  handleChange={handleChange}
                  disabled={isSubmitting}
                />
              </section>
              <section>
                <span>Account Details</span>
                <FormTextInput
                  label="Bank Name"
                  placeholder="Bank name"
                  as="select"
                  name="bank_name"
                  disabled={isSubmitting}
                >
                  <option value="" disabled>
                    Select bank
                  </option>
                  {Banks.map((bank, i) => (
                    <option key={i}>{bank}</option>
                  ))}
                </FormTextInput>
                <FormTextInput
                  label="Account Name"
                  placeholder="Account name"
                  name="account_name"
                  disabled={isSubmitting}
                />
                <FormTextInput
                  label="Account Number"
                  placeholder="Account number"
                  name="account_number"
                  disabled={isSubmitting}
                />
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink to={isSubmitting ? '#' : EmployeesLinks.Freelancers}>
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  )
}

export const CreateFreelancer = Page(View, 'Create Freelancer')
