import { IsPositive, Min } from 'class-validator'

export class CreateJob {
  @IsPositive({ message: 'Client is invalid' })
  public client_id!: number

  @IsPositive({ message: 'Job type is invalid' })
  public job_type_id!: number

  @IsPositive({ message: 'Engagement partner is invalid' })
  public engagement_partner_id!: number

  @IsPositive({ message: 'Engagement manager is invalid' })
  public engagement_manager_id!: number

  @Min(0, { message: 'Amount is invalid' })
  public amount!: number

  constructor(object?: CreateJob) {
    this.client_id = object?.client_id || 0
    this.job_type_id = object?.job_type_id || 0
    this.engagement_partner_id = object?.engagement_partner_id || 0
    this.engagement_manager_id = object?.engagement_manager_id || 0
    this.amount = object?.amount || 0
  }

  public static transform(object: CreateJob) {
    object.client_id = Number.parseInt(object.client_id as any, 10)
    object.job_type_id = Number.parseInt(object.job_type_id as any, 10)
    object.engagement_partner_id = Number.parseInt(
      object.engagement_partner_id as any,
      10
    )
    object.engagement_manager_id = Number.parseInt(
      object.engagement_manager_id as any,
      10
    )
    object.amount = Number.parseInt(object.amount as any, 10)
  }
}
