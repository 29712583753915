import { Formik } from 'formik'
import React from 'react'
import { useAlert } from 'react-alert'
import { Check } from 'react-feather'
import { Redirect } from 'react-router-dom'
import { PrimaryButton } from '../../component/button'
import { EntryForm } from '../../component/container'
import { FormTextInput } from '../../component/field'
import { ResetPassword } from '../../model/request/auth'
import { AuthService } from '../../service'
import { validateWithModel } from '../../util/validation'
import { Page, PageComponentProps } from '../Page'
import { CentralizerContainer } from './common'

const View: React.FC<PageComponentProps> = ({
  location,
  authState,
  loaderState,
}) => {
  const alert = useAlert()
  const { state } = location as any

  if (state && state.email) {
    return (
      <CentralizerContainer>
        <Formik
          initialValues={new ResetPassword()}
          validate={validateWithModel(ResetPassword)}
          onSubmit={async ({ password }) => {
            loaderState.setLoading('Setting your new password')
            const response = await AuthService.firstLogin(state.email, password)
            loaderState.setDone()

            if (response.data) {
              alert.success('Password set successfully')
              authState.login(state.email, password)
            } else {
              alert.error(response.message)
            }
          }}
        >
          {({ handleSubmit }) => (
            <EntryForm onSubmit={handleSubmit}>
              <header>
                <img src="/img/logo.png" alt="ABDC Logo" />
                <h6>Please set a new password</h6>
              </header>
              <main>
                <FormTextInput
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Password"
                />
                <FormTextInput
                  label="Confirm Password"
                  name="password_confirmation"
                  type="password"
                  placeholder="Confirm Password"
                />
              </main>
              <footer>
                <PrimaryButton type="submit">
                  <span>Change Password</span>
                  <Check />
                </PrimaryButton>
              </footer>
            </EntryForm>
          )}
        </Formik>
      </CentralizerContainer>
    )
  }

  return <Redirect to="/login" />
}

export const FirstLogin = Page(View, 'Set a new password for your ABDC account')
