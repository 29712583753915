import { Field, Formik } from 'formik'
import Fuse, { IFuseOptions } from 'fuse.js'
import { filter, matches } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAlert } from 'react-alert'
import { Link } from 'react-router-dom'
import { Row } from 'react-table'
import { ListViewButton, SlickLink } from '../../../../component/button'
import { Box, Container } from '../../../../component/container'
import { Input } from '../../../../component/field'
import { FilterBar, PageBody, Table } from '../../../../component/misc'
import { Freelancer } from '../../../../model/response'
import { FreelancersService } from '../../../../service'
import { Permissions } from '../../../../util/constants'
import { useLoadingMessage } from '../../../../util/hook'
import { Page, PageComponentProps } from '../../../Page'
import { NotCreated } from '../../common'
import { SidebarLinks } from '../../links'
import { EmployeesLinks, employeesLinks as links } from '../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Employees, name: 'Employees' },
  {
    path: '',
    name: 'Freelancers',
  },
]

export enum Filters {
  All,
  Active,
  Inactive,
  OnLeave,
}

export const filters = {
  [Filters.All]: {},
  [Filters.Active]: { active: true },
  [Filters.Inactive]: { active: false },
  [Filters.OnLeave]: { onLeave: true },
}

const View: React.FC<PageComponentProps> = ({ permissionsState, query }) => {
  const alert = useAlert()
  const isListView = useMemo(() => query.view === 'list', [query.view])
  const { isLoading, message, updateLoader } = useLoadingMessage()
  const [freelancers, setFreelancers] = useState<{
    [filter: string]: Freelancer[]
  }>({})

  const getFreelancers = async () => {
    updateLoader(true, 'Fetching freelancers')
    const retrieved = (await FreelancersService.list()).data
    updateLoader(false)

    if (retrieved) {
      const filtered: { [filter: string]: Freelancer[] } = {}

      Object.entries(filters).forEach(([filterName, constraint]) => {
        filtered[filterName] = filter(retrieved, matches(constraint))
      })

      setFreelancers(filtered)
      return
    }

    alert.error('Error fetching freelancers')
  }

  const getList = useCallback(
    (search: string, currentFilter: Filters) => {
      const options: IFuseOptions<Freelancer> = {
        minMatchCharLength: 0,
        threshold: 0.2,
        keys: ['first_name', 'last_name', 'email', 'job_title', 'company_name'],
      }

      const list = search
        ? new Fuse(freelancers[currentFilter], options)
            .search(search)
            .map(({ item }) => item)
        : freelancers[currentFilter]

      return list || []
    },
    [freelancers]
  )

  useEffect(() => {
    getFreelancers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageBody
      breadcrumbs={breadcrumbs}
      title="Freelancers"
      pageLinks={links}
      isLoading={isLoading}
      loadingMessage={message}
      tagline="View / manage the freelancers on the platform"
      actionArea={
        permissionsState.hasPermission(Permissions.AdminViewAccess) ? (
          <SlickLink to={EmployeesLinks.CreateFreelancer}>
            <span>Create Freelancer</span>
          </SlickLink>
        ) : null
      }
    >
      {freelancers[Filters.All]?.length ? (
        <Formik
          initialValues={{ filter: Filters.All, name: '' }}
          onSubmit={() => {}}
        >
          {({ values: { name, filter: currentFilter } }) => {
            const list = getList(name, currentFilter)

            return (
              <>
                <FilterBar>
                  <section className="dropdown">
                    <Field as="select" name="filter">
                      <option value={Filters.All}>All</option>
                      <option value={Filters.Active}>Active</option>
                      <option value={Filters.Inactive}>Inactive</option>
                    </Field>
                  </section>
                  <section>
                    <ListViewButton />
                    <Field
                      as={Input}
                      name="name"
                      placeholder="Filter by name..."
                    />
                  </section>
                </FilterBar>
                {isListView ? (
                  <Table
                    columns={[
                      { Header: 'No.', accessor: 'id' },
                      {
                        Header: 'Name',
                        accessor: ({
                          first_name,
                          last_name,
                          slug,
                        }: Freelancer) => (
                          <Link
                            to={
                              permissionsState.userAllowed(slug)
                                ? `${EmployeesLinks.Freelancers}/${slug}`
                                : '#'
                            }
                          >
                            {first_name} {last_name}
                          </Link>
                        ),
                        sortType: (
                          { original: a }: Row<Freelancer>,
                          { original: b }: Row<Freelancer>
                        ) =>
                          `${a.first_name} ${a.last_name}`.toLowerCase() >
                          `${b.first_name} ${b.last_name}`.toLowerCase()
                            ? 1
                            : -1,
                      },
                      { Header: 'Job Title', accessor: 'job_title' },
                      { Header: 'Company', accessor: 'company_name' },
                      {
                        Header: 'Status',
                        accessor: ({ active }: Freelancer) =>
                          active ? 'Active' : 'Inactive',
                      },
                    ]}
                    data={list}
                  />
                ) : (
                  <Container>
                    {list.map(
                      ({
                        id,
                        first_name,
                        last_name,
                        active,
                        company_name,
                        job_title,
                        slug,
                      }) => (
                        <Box
                          to={`${EmployeesLinks.Freelancers}/${slug}`}
                          noLink={!permissionsState.userAllowed(slug)}
                          inactive={!active}
                          key={id}
                        >
                          <span>
                            {first_name} {last_name}
                          </span>
                          <h6>
                            {job_title} at {company_name}
                          </h6>
                          <small>{active ? 'Active' : 'Inactive'}</small>
                        </Box>
                      )
                    )}
                  </Container>
                )}
              </>
            )
          }}
        </Formik>
      ) : (
        NotCreated('freelancer', 'freelancers', EmployeesLinks.CreateFreelancer)
      )}
    </PageBody>
  )
}

export const Freelancers = Page(View, 'Freelancers')
