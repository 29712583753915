import { ErrorMessage, Formik, FormikErrors } from 'formik'
import { History } from 'history'
import React from 'react'
import { useAlert } from 'react-alert'
import { Check, X } from 'react-feather'
import { PrimaryButton, PrimaryLink } from '../../../../component/button'
import { CreateEditForm } from '../../../../component/container'
import { FormTextInput } from '../../../../component/field'
import { PageBody } from '../../../../component/misc'
import { LoaderState } from '../../../../context/loader.context'
import { CreateFinancialRequest as CreateFinancialRequestModel } from '../../../../model/request/jobs'
import { Job } from '../../../../model/response'
import { FinancialRequestsService } from '../../../../service'
import { Colors } from '../../../../style'
import { validateWithModel } from '../../../../util/validation'
import { SidebarLinks } from '../../links'

const breadcrumbs = [
  { path: SidebarLinks.Index, name: 'Dashboard' },
  { path: SidebarLinks.Jobs, name: 'Jobs' },
  {
    path: '',
    name: 'Financial Requests',
  },
]

interface CreateFinancialRequestProps {
  history: History
  params: Record<string, string>
  loaderState: LoaderState
  job: Job
  setJob: React.Dispatch<React.SetStateAction<Job | undefined>>
}

export const CreateFinancialRequest: React.FC<CreateFinancialRequestProps> = ({
  history,
  params,
  loaderState,
  job,
}) => {
  const alert = useAlert()

  return (
    <PageBody breadcrumbs={breadcrumbs}>
      <Formik
        initialValues={new CreateFinancialRequestModel(job)}
        validate={validateWithModel(CreateFinancialRequestModel)}
        onSubmit={async (values, { setErrors }) => {
          loaderState.setLoading('Creating financial request')
          const response = await FinancialRequestsService.create(values)
          loaderState.setDone()

          if (response.success) {
            alert.success('Financial request created successfully')
            history.push(`${SidebarLinks.Jobs}/${params.id}`)

            return
          }

          if (response.errors) {
            alert.error(
              'There was a problem with your data. Check the form again'
            )
            setErrors(
              response.errors as FormikErrors<CreateFinancialRequestModel>
            )
            return
          }

          alert.error(response.message)
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <CreateEditForm onSubmit={handleSubmit}>
            <header>
              <h2>Create Financial Request</h2>
              <h6>Fill in the required details below</h6>
            </header>
            <main>
              <section>
                <FormTextInput
                  label="Accommodation (N)"
                  name="accommodation"
                  placeholder="Accommodation expenses"
                  type="number"
                  disabled={isSubmitting}
                  min={0}
                />
                <FormTextInput
                  label="Transportation (N)"
                  name="transportation"
                  placeholder="Transportation expenses"
                  type="number"
                  disabled={isSubmitting}
                  min={0}
                />
                <FormTextInput
                  label="Feeding (N)"
                  name="feeding"
                  placeholder="Feeding expenses"
                  type="number"
                  disabled={isSubmitting}
                  min={0}
                />
                <FormTextInput
                  label="Others (N)"
                  name="others"
                  placeholder="Other expenses"
                  type="number"
                  disabled={isSubmitting}
                  min={0}
                />
                <ErrorMessage
                  name="total"
                  component={() => (
                    <section>
                      <div style={{ color: Colors.Red }}>
                        <span>Please enter at least one amount</span>
                      </div>
                    </section>
                  )}
                />
              </section>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Create</span>
                <Check />
              </PrimaryButton>
              <PrimaryLink
                to={isSubmitting ? '#' : `${SidebarLinks.Jobs}/${params.id}`}
              >
                <span>Cancel</span>
                <X />
              </PrimaryLink>
            </footer>
          </CreateEditForm>
        )}
      </Formik>
    </PageBody>
  )
}
