import { Formik } from 'formik'
import React from 'react'
import { useAlert } from 'react-alert'
import { Check } from 'react-feather'
import { Link } from 'react-router-dom'
import { PrimaryButton } from '../../component/button'
import { EntryForm } from '../../component/container'
import { FormTextInput } from '../../component/field'
import { ForgotPassword as ForgotPasswordModel } from '../../model/request/auth'
import { AuthService } from '../../service'
import { validateWithModel } from '../../util/validation'
import { Page, PageComponentProps } from '../Page'
import { CentralizerContainer } from './common'

const View: React.FC<PageComponentProps> = ({ loaderState, history }) => {
  const alert = useAlert()

  return (
    <CentralizerContainer>
      <Formik
        initialValues={new ForgotPasswordModel()}
        validate={validateWithModel(ForgotPasswordModel)}
        onSubmit={async ({ email }) => {
          loaderState.setLoading('Putting your password reset email together')
          const response = await AuthService.sendResetEmail(email)
          loaderState.setDone()

          if (response.success) {
            history.push('/login', {})
            alert.success('Email sent successfully')
            return
          }

          alert.error(response.message)
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <EntryForm onSubmit={handleSubmit}>
            <header>
              <img src="/img/logo.png" alt="ABDC Logo" />
              <h6>Don’t worry. It is easy to reset</h6>
            </header>
            <main>
              <FormTextInput
                label="Email"
                name="email"
                type="email"
                placeholder="Email address"
                disabled={isSubmitting}
              />
              <div>
                <Link to={isSubmitting ? '#' : '/login'}>
                  <small>Go to login</small>
                </Link>
              </div>
            </main>
            <footer>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                <span>Send Email</span>
                <Check />
              </PrimaryButton>
            </footer>
          </EntryForm>
        )}
      </Formik>
    </CentralizerContainer>
  )
}

export const ForgotPassword = Page(View, 'Forgot Password?')
