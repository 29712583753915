import { extract, save } from './storage'
import { runValidation } from './validation'

export interface QueryParams {
  [field: string]: any
}

export interface ApiResponse<T> {
  success: boolean
  errors?: { [key: string]: string }
  data?: T
  message: string
}

interface ApiRequest<T = any> {
  body?: T
  query?: QueryParams
  model?: T
}

export enum HttpMethods {
  Get = 'GET',
  Post = 'POST',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

const tokenKey = 'API_TOKEN'

function LogRequests() {
  return function (
    target: Function,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const originalFunction: Function = descriptor.value
    descriptor.value = async function (...args: any[]) {
      try {
        const [method, endpoint, request] = args
        const response = await originalFunction.call(target, ...args)
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Made [${method}] API call to [${endpoint}]:`, {
            request,
            response,
          })
        }
        return response
      } catch (error) {
        console.error(error)
      }
    }

    return descriptor
  }
}
export class ApiClient {
  private static token: null | string = null
  public static expires_at: null | string = null
  private static abortController = new AbortController()

  private static extractToken() {
    ;[this.token, this.expires_at] = (extract(tokenKey) as string)?.split(
      '//'
    ) || [null, null]
  }

  @LogRequests()
  private static async RequestType<ResponseType = any, RequestType = any>(
    method: HttpMethods,
    endpoint: string,
    request?: ApiRequest<RequestType>
  ): Promise<ApiResponse<ResponseType>> {
    const base = process.env.REACT_APP_BASE_URL
    const headers: any = {}
    this.abortController = new AbortController()

    headers.Accept = 'application/json'
    headers['Content-Type'] = 'application/json'

    this.extractToken()

    if (this.token) {
      headers.Authorization = this.getBearerToken()
    }

    const requestContent: any = {}

    if (request && request.query) {
      const query = Object.entries(request.query)
        .filter(([_, value]) => value)
        .map(([field, value]) => field + '=' + value)
        .join('&')
      endpoint += query ? '?' + query : ''
    }

    if (request && request.body && request.model) {
      const errors = runValidation(request.model)(request.body)

      if (errors.length) {
        return { message: 'Invalid request', success: false }
      }

      requestContent.body = JSON.stringify(request.body)
    }

    try {
      const options = {
        headers,
        mode: 'cors',
        method,
        ...requestContent,
        signal: this.abortController.signal,
      }

      const response = await fetch(base + '/' + endpoint, options)
      const responseContent = (await response.json()) as ApiResponse<
        ResponseType
      >
      return responseContent
    } catch (error) {
      const { response } = error

      if (!response || response.status === 500) {
        return response?.message
          ? { message: response.message, success: false }
          : {
              message: 'Error connecting to server. Try again',
              success: false,
            }
      }

      return response.data
    }
  }

  public static abort() {
    this.abortController.abort()
  }

  public static getBearerToken() {
    return `Bearer ${this.token}`
  }

  public static setToken(token: string, expires_at: string) {
    this.token = token
    this.expires_at = expires_at
    save<string>(tokenKey, `${token}//${expires_at}`)
  }

  public static unsetToken() {
    this.token = null
    localStorage.removeItem(tokenKey)
  }

  public static get<T>(endpoint: string, query?: QueryParams) {
    return this.RequestType<T>(HttpMethods.Get, endpoint, { query })
  }

  public static getOne<T>(endpoint: string, id: number | string) {
    return this.get<T>(`${endpoint}/${id}`)
  }

  public static post<RequestType, ResponseType = any>(
    endpoint: string,
    body?: RequestType,
    model?: any
  ) {
    return this.RequestType<ResponseType>(HttpMethods.Post, endpoint, {
      body,
      model,
    })
  }

  public static postOne<RequestType, ResponseType = any>(
    endpoint: string,
    id: number | string,
    body?: RequestType,
    model?: any
  ) {
    return this.post<RequestType, ResponseType>(
      `${endpoint}/${id}`,
      body,
      model
    )
  }

  public static patch<RequestType, ResponseType = RequestType>(
    endpoint: string,
    id?: number | string,
    body?: RequestType,
    model?: any
  ) {
    return this.RequestType<ResponseType, RequestType>(
      HttpMethods.Patch,
      `${endpoint}/${id || ''}`,
      { body, model }
    )
  }

  public static delete<T = any>(endpoint: string) {
    return this.RequestType<T>(HttpMethods.Delete, endpoint)
  }

  public static deleteOne<RequestType, ResponseType = RequestType>(
    endpoint: string,
    id?: number | string,
    body?: RequestType,
    model?: any
  ) {
    return this.RequestType<ResponseType, RequestType>(
      HttpMethods.Delete,
      `${endpoint}/${id || ''}`,
      { body, model }
    )
  }
}
